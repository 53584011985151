<ng-container *ngIf="!isPreSubmission; else preSubmissionQuestions">
  <div class="main-content">
    <h2 class="mb-5">{{ callName }}</h2>
    <div class="whitebox_container">
      <div class="new-solution">
        <img class="add-solutionimg img-static" src="assets/images/new-solution.svg" alt="new-solution" />
        <p>
          {{ 'vendor.add_solution.sub_text' | translate }}
        </p>
        <button type="button" class="btn cst_btn add_newbtn" (click)="showPreSubmission()">
          <img src="assets/images/plus.svg" alt="plus" class="img-static" />
          {{ 'vendor.add_solution.button' | translate }}
        </button>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #preSubmissionQuestions>
  <app-pre-submission *ngIf="isPreSubmission" [callId]="callId"></app-pre-submission>
</ng-template>

<app-timeout></app-timeout>