import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { lastValueFrom, tap } from 'rxjs';
import { SharedService } from 'src/app/shared/services/shared.service';
import { SecuritySubscriberService } from '../security-subscriber.service';
import { SecurityService } from '../security.service';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit {
  userDetails: any;
  userDetailsForm: any;
  countries: any;
  currentUser: any;
  isError: any;
  country: string = '';
  constructor(
    private readonly securitySubscriberService: SecuritySubscriberService,
    private readonly router: Router,
    private readonly formBuilder: FormBuilder,
    private readonly location: Location,
    readonly securityService: SecurityService,
    private readonly sharedService: SharedService
  ) {
    this.userDetailsForm = this.formBuilder.group({
      firstName: [''],
      lastName: [''],
      email: ['', [Validators.required]],
      role: ['', [Validators.required]],
      country: [''],
      contactNumber: ['', Validators.pattern('^\\+?[0-9]{10,14}$')],
      companyName: ['', [Validators.required]],
      jobTitle: ['']
    });
  }

  async ngOnInit() {
    this.countries = (await lastValueFrom(this.securityService.getCountries())).countries;
    this.userDetailsForm.get('email').disable();
    this.userDetailsForm.get('role').disable();
    this.userDetailsForm.get('companyName').disable();
    this.securitySubscriberService.getCurrentUser
      .pipe(
        tap((user: any) => {
          this.currentUser = user;
          if (user) {
            if (Object.keys(user).length != 0) {
              this.userDetailsForm.patchValue(user);
            }
          }
        })
      )
      .subscribe();
  }

  setCountry(country: string) {
    this.userDetailsForm.controls['country'].setValue(country);
  }

  navigatToDashboard() {
    this.securityService.navigateUser(this.currentUser.role);
  }

  async onUpdate() {
    const profileDetails = this.userDetailsForm.value;
    try {
      await lastValueFrom(this.securityService.updateUser(profileDetails));
      this.sharedService.showDynamicAlert('admin.profile_update_success', 'success');
      this.securityService.navigateUser(this.currentUser.role);
    } catch (error: any) {
      this.isError = 'admin.unable_to_process';
      this.sharedService.showDynamicAlert('admin.unable_to_process');
    }
  }
}
