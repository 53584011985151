import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormCategory } from '../category/category.model';
import { ViewportScroller } from '@angular/common';

@Component({
  selector: 'app-stage',
  templateUrl: './stage.component.html'
})
export class StageComponent implements OnInit {
  @Input() categories!: FormCategory[];
  @Input() formGroup!: FormGroup;
  @Input() answerData: any;
  @Input() isSubmitted!: boolean;
  @Input() isFormSubmitted!: boolean;
  @Input() noteGroup!: FormGroup;
  @Input() attachments: any[] = [];
  @Input() stepId!: string;
  @Output() getAttachments = new EventEmitter();
  constructor(private viewPortScroller: ViewportScroller) {}

  ngOnInit(): void {
    this.categories.forEach((cat: FormCategory, index: number) => {
      const catName = cat.name;
      const form = new FormGroup({});
      this.formGroup.addControl(catName, form);
      this.categories[index]['form'] = form;
    });
  }

  getAttachmentsHere() {
    this.getAttachments.emit();
  }

  navigateToId(id: string) {
    const newId = this.getCategoryId(id);

    setTimeout(() => {
      this.viewPortScroller.setOffset([0, 250]);
      this.viewPortScroller.scrollToAnchor(newId);
    }, 100);
  }

  getCategoryId(id: string) {
    return id.replaceAll('.', '');
  }

  hasError(i: number) {
    return this.isSubmitted && this.formGroup.controls[this.categories[i].name].status === 'INVALID' && this.formGroup.controls[this.categories[i].name].touched;
  }
}
