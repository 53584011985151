import { Component, OnInit } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { SecurityService } from 'src/app/security/security.service';
import { SharedService } from 'src/app/shared/services/shared.service';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss']
})
export class WelcomeComponent implements OnInit {
  pdfs: any;
  searchModalOpen: boolean = false;
  solutions: any = [];
  constructor(private readonly securityService: SecurityService, private sharedService: SharedService) {}

  async ngOnInit() {
    await this.getPdfsToDownload();
    await this.getSolutions();
  }

  downloadData(dataToDownload: string) {
    if (this.pdfs && this.pdfs.has(dataToDownload)) {
      const pdfBlob = this.pdfs.get(dataToDownload);
      const fileURL = URL.createObjectURL(pdfBlob);
      const anchorElement = document.createElement('a');
      anchorElement.href = fileURL;
      anchorElement.target = '_blank';
      anchorElement.setAttribute('download', dataToDownload);
      anchorElement.dispatchEvent(new MouseEvent('click'));
    }
  }

  getbgColor(val: string) {
    switch (val) {
      case 'Point of service':
        return 'green';
      case 'Registries & Directories':
        return 'orange';
      case 'Data Management services':
        return 'blue';
      case 'Health system/Provider administration':
        return 'red';
      case 'Surveillance and Response':
        return 'purple';
      default:
        return '';
    }
  }

  getPdfSize(key: string): string {
    const pdf = this.pdfs.get(key);
    return pdf ? (pdf.size / 1000).toFixed(1) : '';
  }

  async getPdfsToDownload() {
    this.pdfs = await lastValueFrom(this.securityService.getSolutionDevelopersPDFs());
  }

  async getSolutions() {
    const searchDetails = {
      page: 1,
      pageSize: 3
    };
    const solutions = await lastValueFrom(this.sharedService.searchSolution(searchDetails));

    this.solutions = solutions.data.rows;
  }

  getImages(i: number) {
    if (i % 3 === 0) {
      return '../../../assets/images/image 38.png';
    }
    if (i % 3 === 1) {
      return '../../../assets/images/image 31.png';
    }
    if (i % 3 === 2) {
      return '../../../assets/images/image 39.png';
    }
    return '';
  }
}
