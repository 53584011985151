import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AdminService {
  constructor(private readonly http: HttpClient) {}

  getAllUsers(paginationOptions: Object): Observable<any> {
    return this.http.post(`${environment.baseUrl}/user/get-users`, paginationOptions);
  }

  getPublishSolutionRequests(paginationOptions: any): Observable<any> {
    return this.http.get(`${environment.baseUrl}/solution/publish-solution`, {
      params: {
        searchTerm: paginationOptions.searchTerm,
        page: paginationOptions.page,
        pageSize: paginationOptions.pageSize,
        orderColumn: paginationOptions.orderColumn,
        orderByAsc: paginationOptions.orderByAsc
      }
    });
  }

  addNewUser(userDetails: any) {
    return this.http.post(`${environment.baseUrl}/user/add-new-user`, userDetails);
  }

  deleteUser(id: any) {
    const options = {
      headers: new HttpHeaders({
        'Content-': 'application/json'
      }),
      body: {
        id
      }
    };
    return this.http.delete(`${environment.baseUrl}/user/delete-user`, options);
  }

  changeVerificationStatus(userDetails: any) {
    return this.http.post(`${environment.baseUrl}/user/change-verification-status`, userDetails);
  }

  getAllReviewers(searchTerm: any, solutionId: string): Observable<any> {
    return this.http.get(`${environment.baseUrl}/review/all-reviewers`, {
      params: {
        searchTerm,
        solutionId
      }
    });
  }

  getAssignmentRequest(paginationOptions: any): Observable<any> {
    return this.http.get(`${environment.baseUrl}/review/reviewer-assignment`, {
      params: {
        searchTerm: paginationOptions.searchTerm,
        page: paginationOptions.page,
        pageSize: paginationOptions.pageSize,
        orderColumn: paginationOptions.orderColumn,
        orderByAsc: paginationOptions.orderByAsc
      }
    });
  }

  saveAssignmentRequest(assignmentDetails: any): Observable<any> {
    return this.http.post(`${environment.baseUrl}/review/reviewer-assignment`, assignmentDetails);
  }

  getAssignmentRequestByStatus(paginationOptions: any, status: string): Observable<any> {
    return this.http.get(`${environment.baseUrl}/review/reviewer-assignment-by-status`, {
      params: {
        searchTerm: paginationOptions.searchTerm,
        page: paginationOptions.page,
        pageSize: paginationOptions.pageSize,
        orderColumn: paginationOptions.orderColumn,
        orderByAsc: paginationOptions.orderByAsc,
        status: status ?? 'pending'
      }
    });
  }
}
