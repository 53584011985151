import { Component } from '@angular/core';
import { SimpleModalComponent } from 'ngx-simple-modal';
export interface SubmissionStatusModel {
  title: string;
  lang_msg: string;
  type: string;
}

@Component({
  selector: 'app-submission-status',
  templateUrl: './submission-status.component.html',
  styleUrls: ['./submission-status.component.scss']
})
export class SubmissionStatusComponent extends SimpleModalComponent<SubmissionStatusModel, boolean> implements SubmissionStatusModel {
  title = '';
  lang_msg = '';
  type = 'BACK';
  constructor() {
    super();
  }

  /**
   * Handles the back action on modal
   */
  back() {
    this.result = false;
    this.close();
  }

  /**
   * Handles the continue action on modal
   */
  continue() {
    this.result = true;
    this.close();
  }
}
