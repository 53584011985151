import { Component, OnDestroy, OnInit } from '@angular/core';
import { Debounce } from 'lodash-decorators';
import { SimpleModalService } from 'ngx-simple-modal';
import { lastValueFrom } from 'rxjs';
import { SharedService } from 'src/app/shared/services/shared.service';
import { AdminService } from '../admin.service';

@Component({
  selector: 'app-assignment-request',
  templateUrl: './assignment-request.component.html',
  styleUrls: ['./assignment-request.component.scss']
})
export class AssignmentRequestComponent implements OnInit, OnDestroy {
  constructor(private readonly adminService: AdminService, private readonly sharedService: SharedService, private readonly simpleModalService: SimpleModalService) {}
  sortByColumn: any = {};
  paginationOptions = {
    searchTerm: '',
    page: 1,
    pageSize: 10,
    verificationStatus: 'Approved',
    orderColumn: '',
    orderByAsc: false
  };

  totalPages: any;
  totalUsers: any;
  reviewers: any;
  sortDirection: any;
  requestedDetails: { status: string; data: any[] } = { status: 'ongoing', data: [] };
  totalRequests: number = 0;
  noDataFound: boolean = true;
  selectedReviewers: string[] = [];
  reviewerSearched = '';
  activeSolution: any = {};
  async ngOnInit() {
    await this.getAllAssignmentRequests();
  }

  async getAllReviewers(request: any) {
    this.activeSolution = request;
    this.selectedReviewers = [];
    try {
      const reviewersList = await lastValueFrom(this.adminService.getAllReviewers('', this.requestedDetails.status === 'rejected' ? this.activeSolution.solutionId : ''));

      if (this.requestedDetails.status === 'rejected') {
        this.reviewers = reviewersList.reviewers;
      } else this.reviewers = reviewersList.reviewers;
    } catch (err) {
      this.sharedService.showDynamicAlert();
    }
  }

  isInvited() {
    if (this.requestedDetails.status === 'rejected') {
      return this.selectedReviewers.length < 1;
    }
    return (
      this.selectedReviewers.length === 0 ||
      (this.activeSolution.minimumReviewerRequired && this.selectedReviewers.length < this.activeSolution.minimumReviewerRequired) ||
      (!this.activeSolution.minimumReviewerRequired && this.selectedReviewers.length > 1)
    );
  }

  @Debounce(500)
  async searchReviewer(searchedText: string) {
    try {
      this.reviewers = (
        await lastValueFrom(this.adminService.getAllReviewers(searchedText, this.requestedDetails.status === 'rejected' ? this.activeSolution.solutionId : ''))
      ).reviewers;
    } catch (err) {
      this.sharedService.showDynamicAlert();
    }
  }

  removeReviewersFromList(id: string) {
    const reviewers = this.selectedReviewers.filter((reviewer) => reviewer !== id);
    this.selectedReviewers = reviewers;
  }

  selectReviewers(id: string) {
    if (!this.activeSolution.minimumReviewerRequired && this.selectedReviewers.length) {
      this.selectedReviewers = [];
    }
    this.selectedReviewers.includes(id) ? this.removeReviewersFromList(id) : this.selectedReviewers.push(id);
  }

  changeTab(status: string) {
    this.resetConfig();
    if (status === 'ongoing') {
      this.getAllAssignmentRequests();
    } else {
      this.getAllAssignmentRequestsByStatus(status);
    }
  }

  async getAllAssignmentRequestsByStatus(status: string) {
    this.selectedReviewers = [];
    this.requestedDetails.status = status;
    const response = await lastValueFrom(this.adminService.getAssignmentRequestByStatus(this.paginationOptions, this.requestedDetails.status));
    this.totalPages = Math.ceil(response.count / this.paginationOptions.pageSize) || 0;
    this.totalRequests = response?.count || 0;

    this.noDataFound = false;
    if (response.rows.length === 0) {
      this.noDataFound = true;
    }
    this.requestedDetails.data = this.groupByCallName(response.rows);
  }

  groupByCallName(rows: any) {
    let currentCallName = '';
    return rows.map((row: any) => {
      if (row.callName !== currentCallName) {
        currentCallName = row.callName;
      } else {
        row.callName = '';
      }
      return row;
    });
  }

  pageChange(page: any) {
    this.paginationOptions.page = page;
    if (['rejected', 'accepted', 'pending'].includes(this.requestedDetails.status)) {
      this.getAllAssignmentRequestsByStatus(this.requestedDetails.status);
    } else {
      this.getAllAssignmentRequests();
    }
  }

  resetConfig() {
    this.sortByColumn = {};
    this.sortDirection = '';
    this.paginationOptions.orderColumn = '';
    this.paginationOptions.orderByAsc = false;
  }

  async getAllAssignmentRequests() {
    this.selectedReviewers = [];
    this.requestedDetails.status = 'ongoing';
    this.noDataFound = false;
    try {
      const requestList = await lastValueFrom(this.adminService.getAssignmentRequest(this.paginationOptions));
      this.requestedDetails.data = this.groupByCallName(requestList.rows);
      if (this.requestedDetails.data.length === 0) {
        this.noDataFound = true;
      }
      this.totalPages = Math.ceil(requestList.count / this.paginationOptions.pageSize) || 0;
      this.totalRequests = requestList?.count || 0;
    } catch (err) {
      this.sharedService.showDynamicAlert();
    }
  }

  async onSortChanged(event: { columnName: string; sortDirection: any }) {
    const { columnName, sortDirection } = event;
    this.sortDirection = sortDirection;
    this.paginationOptions.orderByAsc = sortDirection;
    this.paginationOptions.orderColumn = columnName;
    this.sortByColumn = {};
    if (sortDirection !== 'default') {
      this.sortByColumn[columnName] = sortDirection;
    }
    if (['rejected', 'accepted', 'pending'].includes(this.requestedDetails.status)) {
      await this.getAllAssignmentRequestsByStatus(this.requestedDetails.status);
    } else {
      await this.getAllAssignmentRequests();
    }
  }

  @Debounce(500)
  async onSearchChange(searchFilter: any) {
    this.paginationOptions.searchTerm = searchFilter;
    this.paginationOptions.page = 1;
    if (['rejected', 'accepted', 'pending'].includes(this.requestedDetails.status)) {
      await this.getAllAssignmentRequestsByStatus(this.requestedDetails.status);
    } else {
      await this.getAllAssignmentRequests();
    }
  }

  async saveAssignmentRequest() {
    try {
      const reviewerIds = this.selectedReviewers;
      const AssignmentRequestData = {
        solutionId: this.activeSolution.solutionId,
        reviewerIds: Array.isArray(reviewerIds) ? reviewerIds : [reviewerIds],
        id: this.activeSolution.id
      };
      await lastValueFrom(this.adminService.saveAssignmentRequest(AssignmentRequestData));
      this.sharedService.showDynamicAlert('Successfully assigned', 'success');
      if (this.requestedDetails.status === 'rejected') {
        await this.getAllAssignmentRequestsByStatus('rejected');
      } else {
        await this.getAllAssignmentRequests();
      }
    } catch (error: any) {
      this.sharedService.showDynamicAlert('Something went wrong');
    }
  }
  ngOnDestroy() {
    this.simpleModalService.removeAll();
  }
}
