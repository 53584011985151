import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-summary-sidebar',
  templateUrl: './summary-sidebar.component.html',
  styleUrls: ['./summary-sidebar.component.scss']
})
export class SummarySidebarComponent {
  @Input() questions: any;
  @Input() previousQuestions: any = [];
  @Input() selectedId: any;
  @Output() navigateTo = new EventEmitter();

  navigateToId(id: string) {
    this.selectedId = this.getId(id);
    this.navigateTo.emit(id);
  }

  getId(id: string) {
    return id.replaceAll('.', '');
  }
}
