import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserRegistrationRequestComponent } from './user-registration-request/user-registration-request.component';
import { AddNewUserComponent } from './add-new-user/add-new-user.component';
import { AssignmentRequestComponent } from './assignment-request/assignment-request.component';
import { UserListComponent } from './user-list/user-list.component';
import { AdminRoutingModule } from './admin-routing.module';
import { SharedModule } from '../shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxPaginationModule } from 'ngx-pagination';
import { AdminComponent } from './admin.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { PublishSolutionRequestComponent } from './publish-solution-request/publish-solution-request.component';
@NgModule({
  declarations: [UserRegistrationRequestComponent, AddNewUserComponent, UserListComponent, AdminComponent, AssignmentRequestComponent, PublishSolutionRequestComponent],
  imports: [CommonModule, AdminRoutingModule, SharedModule, ReactiveFormsModule, FormsModule, NgxPaginationModule, NgSelectModule, MatTooltipModule],
  bootstrap: []
})
export class AdminModule {}
