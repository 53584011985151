<app-base-header *ngIf="!isEditable" [hasBoxShadow]="false"></app-base-header>

<div class="solution-detail-container" [class.main-content]="isEditable">
  <div class="back-container" [class.inside-back-container]="isEditable">
    <div *ngIf="!isEditable" class="back-breadcrumb">
      <a [routerLink]="'/welcome'" class="me-1">{{ 'breadcrumb.home' | translate }}</a> <i class="bi bi-chevron-right"></i>
      <a [routerLink]="'/search'" class="ms-1 me-1">{{ 'welcome.curated_solutions' | translate }}</a>
      <i class="bi bi-chevron-right"></i>
      <span class="solution-name ms-1">{{ solutionName }}</span>
    </div>
  </div>
  <div class="container-fluid">
    <div class="search-detail-header" [class.header-content]="isEditable">
      <div class="d-flex justify-content-between">
        <div class="d-flex align-items-center mb-4">
          <i class="bi bi-arrow-left-circle back-arrow me-2 cursor-pointer" (click)="goBack()"></i>
          <h4>{{ solutionName }}</h4>
        </div>
      </div>

      <div class="solution-details">
        <div class="container-fluid">
          <div class="row">
            <div class="col-lg-3 col-md-6 col-12">
              <p>
                Version:<span>{{ version }}</span>
              </p>
            </div>
            <div class="col-lg-3 col-md-6 col-12">
              <p>
                Website Link:<span>{{ websiteLink }}</span>
              </p>
            </div>
            <div class="col-lg-3 col-md-6 col-12">
              <p>
                Supported Browsers:<span>
                  <img *ngIf="browsers.includes('Safari')" src="assets/images/Safari_browser_logo (1) 2.png" alt="safari_logo" class="me-2" />
                  <img *ngIf="browsers.includes('Chrome')" src="assets/images/Google_Chrome_icon_(February_2022) 2.png" alt="chrome_logo" class="me-2" />
                  <img *ngIf="browsers.includes('Internet Explorer')" src="assets/images/Internet_Explorer_10+11_logo 2.png" alt="internet_explorer_logo" />
                </span>
              </p>
            </div>
            <div class="col-lg-3 col-md-6 col-12">
              <p>
                Supported Platform:<span>
                  <img *ngIf="operatingSystems.includes('IOS')" src="assets/images/Apple_logo_black (1) 1.png" alt="apple_logo" class="me-2" />
                  <img *ngIf="operatingSystems.includes('Android')" src="assets/images/android-logo (1) 2.png" alt="android_logo" />
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="search-detail-card-wrapper">
      <div *ngIf="solutionScores.flags.isAssessmentFrameworkValue && solutionScores.level?.alignmentLevel" class="search-detail-card">
        <img src="assets/images/assessment-icon.svg" alt="assessment-icon" />
        <div class="card-details">
          <h5>{{ 'search.alignment_level' | translate }}</h5>
          <h6>{{ solutionScores.level?.alignmentLevel.toUpperCase() }}</h6>
          <p>
            {{ solutionScores.level?.alignmentLevelDesc }}
          </p>
        </div>
      </div>
      <div *ngIf="solutionScores.flags.isLmicValue && solutionScores.level?.lmicLevel" class="search-detail-card">
        <img src="assets/images/assessment-icon-2.svg" alt="assessment-icon" />
        <div class="card-details">
          <h5>{{ 'search.lmic_level' | translate }}</h5>
          <h6>{{ solutionScores.level?.lmicLevel.toUpperCase() }}</h6>
          <p>{{ solutionScores.level?.lmicLevelDesc }}</p>
        </div>
      </div>
    </div>
    <div *ngIf="solutionScores.flags.isCategoryValue" class="result-card" [class.remove-chart]="!solutionScores.score">
      <div class="result-card-header">
        <h4>{{ 'search.x_axis_label' | translate }}</h4>
      </div>
      <div class="result-card-body score-chart" style="min-height: 240px"></div>
    </div>

    <div *ngIf="!isEditable" class="custom-solution-tabs">
      <ul class="nav nav-tabs" id="myTab" role="tablist">
        <li class="nav-item" role="presentation">
          <button
            class="nav-link right active"
            id="home-tab"
            data-bs-toggle="tab"
            data-bs-target="#home-tab-pane"
            type="button"
            role="tab"
            aria-controls="home-tab-pane"
            aria-selected="true"
          >
            Solution Overview<span class="sb-text">Detailed information about the digital health solution.</span>
          </button>
        </li>
        <li class="nav-item" role="presentation">
          <button
            class="nav-link pl-50 right"
            id="profile-tab"
            data-bs-toggle="tab"
            data-bs-target="#profile-tab-pane"
            type="button"
            role="tab"
            aria-controls="profile-tab-pane"
            aria-selected="false"
          >
            Assessment Results<span class="sb-text">View the summary results of the WHO assessment according to the assessment domains.</span>
          </button>
        </li>
      </ul>

      <div class="tab-content" id="myTabContent">
        <div class="tab-pane fade show active" id="home-tab-pane" role="tabpanel" aria-labelledby="home-tab" tabindex="0">
          <div *ngIf="solutionOverview?.length" class="row">
            <div class="col-md-3 d-md-flex d-none">
              <ul class="result-card-list">
                <li *ngFor="let accordion of solutionOverview; let i = index" [class.active]="accordion.show === true" (click)="changeOverviewResponse(accordion.id)">
                  {{ accordion.title }}
                </li>
              </ul>
            </div>

            <!-- SUMMARY CARD  -->
            <div class="result-card col-md-9 col-12" *ngIf="!isEditable || !isSubmittable">
              <div class="result-card-header" *ngIf="whopanelName">
                <h4 class="d-flex align-items-center">Solution Overview</h4>
              </div>
              <div class="result-card-body">
                <div class="review-detail">
                  <div class="accordion cst-accordion" id="accordionExample">
                    <div class="accordion-item" *ngFor="let accordion of solutionOverview; let i = index" [class.display-accordion]="accordion.show === true">
                      <h2 class="accordion-header" [id]="'heading' + accordion.id">
                        <button
                          class="accordion-button"
                          type="button"
                          data-bs-toggle="collapse"
                          [attr.data-controls]="'collapse' + accordion.id"
                          [attr.data-bs-target]="'#collapse' + accordion.id"
                          aria-expanded="false"
                        >
                          <span> {{ accordion.title }}</span>
                        </button>
                      </h2>
                      <div
                        [id]="'collapse' + accordion.id"
                        class="accordion-collapse collapse show"
                        [class.show]="i === 0 || accordion.show === true"
                        [attr.aria-labelledby]="'heading' + accordion.id"
                        data-bs-parent="#accordionExample"
                      >
                        <div class="accordion-body" [innerHTML]="accordion.content"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="tab-pane fade show" id="profile-tab-pane" role="tabpanel" aria-labelledby="profile-tab" tabindex="1">
          <div class="row">
            <div class="col-md-3 d-md-flex d-none">
              <ul class="result-card-list">
                <li *ngFor="let accordion of assessmentResult; let i = index" [class.active]="accordion.show === true" (click)="changeAssessmentResponse(accordion.id)">
                  {{ accordion.title }}
                </li>
              </ul>
            </div>

            <!-- SUMMARY CARD  -->
            <div class="result-card col-md-9 col-12" *ngIf="!isEditable || !isSubmittable">
              <div class="result-card-header" *ngIf="whopanelName">
                <h4 class="d-flex align-items-center">
                  Assessment Results
                  <p class="badge-approved" *ngIf="status" [ngClass]="status.replaceAll(' ', '')">{{ status }}</p>
                </h4>
                <a *ngIf="attachmentUrl" (click)="downloadResponse()"><i class="me-2 bi bi-download"></i>Download full assessment report</a>
              </div>
              <div class="result-card-body">
                <div class="review-detail">
                  <div class="accordion cst-accordion" id="accordionExample">
                    <div class="accordion-item" *ngFor="let accordion of assessmentResult; let i = index" [class.display-accordion]="accordion.show === true">
                      <h2 class="accordion-header" [id]="'heading' + accordion.id">
                        <button
                          class="accordion-button"
                          [ngClass]="'btn-' + accordion.decision"
                          type="button"
                          data-bs-toggle="collapse"
                          [attr.data-controls]="'collapse' + accordion.id"
                          [attr.data-bs-target]="'#collapse' + accordion.id"
                          aria-expanded="false"
                        >
                          <span> {{ accordion.title }}</span>
                          <p class="badge-yes" [ngClass]="accordion.decision">{{ decisions[accordion.decision].label }}</p>
                        </button>
                      </h2>
                      <div
                        [id]="'collapse' + accordion.id"
                        class="accordion-collapse collapse show"
                        [class.show]="i === 0 || accordion.show === true"
                        [attr.aria-labelledby]="'heading' + accordion.id"
                        data-bs-parent="#accordionExample"
                      >
                        <div class="accordion-body" [innerHTML]="accordion.content"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="isEditable && isSubmittable" class="mb-4 mt-2">
      <a *ngIf="attachmentUrl" (click)="downloadResponse()" class="download-link"><i class="me-2 bi bi-download"></i>Download full assessment report</a>

      <div class="mb-4 mt-4">
        <label for="comments" class="">Solution Overview</label>
        <app-text-editor
          [accordions]="solutionOverview"
          (contentChange)="updateEditorContent($event, 'overview')"
          [contentEditable]="true"
          [hasDecisions]="false"
          [accordionName]="'solution-overview-'"
          [defaultAccordion]="'Default Overview'"
          [newBtnText]="'Add New Overview'"
        ></app-text-editor>
      </div>

      <div>
        <label for="comments" class="form-label">Assessment Framework Domain</label>
        <app-text-editor
          [accordions]="assessmentResult"
          (contentChange)="updateEditorContent($event)"
          [contentEditable]="true"
          [hasDecisions]="true"
          [accordionName]="'report-comment-'"
          [defaultAccordion]="'Default Domain'"
          [newBtnText]="'Add New Domain'"
        ></app-text-editor>
      </div>
      <div *ngIf="isEditable && isSubmittable" class="mt-4 text-end">
        <button (click)="addToCuratedSolution()" type="button" class="btn cst_btn">{{ 'shared.submit' | translate }}</button>
      </div>
    </div>
  </div>
</div>
