<div class="main-content">
  <app-breadcrumb
    [title]="'breadcrumb.all_users' | translate | titlecase"
    [items]="[
      { title: 'users', link: '/admin/users/user-list' },
      { title: 'all_users', link: '' }
    ]"
  >
  </app-breadcrumb>
  <div class="table_container">
    <div class="d-md-flex mb-md-0 mb-2 justify-content-between align-items-center">
      <div>
        <form class="table_search mt-2">
          <input
            name="search-registered-users"
            class="form-control search_input me-2"
            [placeholder]="'shared.search' | translate | titlecase"
            id="search-registered-users"
            type="search"
            [(ngModel)]="paginationOptions.searchTerm"
            (ngModelChange)="onSearchChange($event)"
          />
        </form>
      </div>
      <button type="button" class="btn cst_btn add_newbtn" (click)="navigateToAddNewUser()">
        <img src="assets/images/plus.svg" alt="plus" class="img-static" />
        {{ 'user_list.add_new_user' | translate | uppercase }}
        <span></span>
      </button>
    </div>
    <div class="table-responsive">
      <table class="table cst_table table-striped" aria-describedby="user list">
        <thead>
          <tr>
            <th scope="col">
              <p>
                {{ 'shared.first_name' | translate }}
                <span
                  ><app-sorting [sortByColumn]="sortByColumn" [sortDirection]="sortDirection" (sortChanged)="onSortChanged($event)" columnName="first_name"></app-sorting>
                </span>
              </p>
            </th>
            <th scope="col">
              <p>
                {{ 'shared.last_name' | translate }}
                <span><app-sorting [sortByColumn]="sortByColumn" [sortDirection]="sortDirection" (sortChanged)="onSortChanged($event)" columnName="last_name"></app-sorting> </span>
              </p>
            </th>
            <th scope="col">
              <p>
                {{ 'shared.email' | translate }}
                <span><app-sorting [sortByColumn]="sortByColumn" [sortDirection]="sortDirection" (sortChanged)="onSortChanged($event)" columnName="email"></app-sorting> </span>
              </p>
            </th>
            <th scope="col">
              <p>
                {{ 'shared.company_name' | translate }}
                <span
                  ><app-sorting [sortByColumn]="sortByColumn" [sortDirection]="sortDirection" (sortChanged)="onSortChanged($event)" columnName="companyName"></app-sorting>
                </span>
              </p>
            </th>
            <th scope="col">
              <p>
                {{ 'shared.job_title' | translate }}
                <span><app-sorting [sortByColumn]="sortByColumn" [sortDirection]="sortDirection" (sortChanged)="onSortChanged($event)" columnName="job_title"></app-sorting> </span>
              </p>
            </th>
            <th scope="col">
              <p>
                {{ 'shared.contact_no' | translate }}
                <span
                  ><app-sorting [sortByColumn]="sortByColumn" [sortDirection]="sortDirection" (sortChanged)="onSortChanged($event)" columnName="contact_number"></app-sorting>
                </span>
              </p>
            </th>
            <th scope="col">
              <p>
                {{ 'shared.role' | translate }}
                <span><app-sorting [sortByColumn]="sortByColumn" [sortDirection]="sortDirection" (sortChanged)="onSortChanged($event)" columnName="role"></app-sorting> </span>
              </p>
            </th>
            <th scope="col">
              <p>
                {{ 'shared.country' | translate }}
                <span><app-sorting [sortByColumn]="sortByColumn" [sortDirection]="sortDirection" (sortChanged)="onSortChanged($event)" columnName="country"></app-sorting> </span>
              </p>
            </th>
            <th scope="col">{{ 'shared.action' | translate }}</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let user of users | paginate : { itemsPerPage: paginationOptions.pageSize, currentPage: paginationOptions.page, totalItems: totalUsers }; let i = index">
            <td>{{ user.first_name }}</td>
            <td>{{ user.last_name }}</td>
            <td>
              {{ user.email }}
            </td>
            <td>{{ user?.companyName }}</td>
            <td>{{ user?.job_title }}</td>
            <td>{{ user.contact_number }}</td>
            <td>{{ user.role }}</td>
            <td>{{ user.country }}</td>
            <td>
              <img
                [class.disable-delete-icon]="user.email === userDetails['email'] && user.role === userDetails['role']"
                (click)="!(user.email === userDetails['email'] && user.role === userDetails['role']) ? onUserDelete(user) : ''"
                src="assets/images/delete.svg"
                alt="delete"
                class="img-static"
              />
            </td>
          </tr>
          <tr class="no-row-available" *ngIf="noDataFound">
            <td colspan="6" rowspan="2">{{ 'alert.no_records_found' | translate }}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <app-pagination
      *ngIf="users?.length"
      [currentPage]="paginationOptions.page"
      [totalPages]="totalPages"
      (pageChange)="pageChange($event)"
      [limitSelected]="paginationOptions.pageSize"
    >
    </app-pagination>
  </div>
</div>
