<div class="form_wrapper">
  <div *ngIf="!questionsLoading && !answersLoading; else loading">
    <app-step
      *ngIf="existingResponse && answerData"
      [intakeForm]="intakeForm"
      [questionData]="questionData"
      [answerData]="answerData"
      [prevStageAnswerData]="prevStageAnswerData"
      [isFormSubmitted]="isFormSubmitted"
      [noteGroup]="noteGroup"
      [attachments]="attachments"
      (getAttachments)="getAttachments()"
    ></app-step>
  </div>

  <ng-template #loading>
    <div style="position: fixed; color: black; top: 15%; left: 50%">Loading....</div>
  </ng-template>
  <app-timeout *ngIf="!isFormSubmitted; else timeoutWithoutUser" (closeEditorInterval)="closeEditorInterval()" currentUser="vendor"></app-timeout>
  <ng-template #timeoutWithoutUser>
    <app-timeout></app-timeout>
  </ng-template>
</div>
