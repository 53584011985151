<h5 *ngIf="contentLoaded && checkKeysExist(formGroup, subcat.questions)">{{ subcat.name }}</h5>
<div *ngFor="let question of questions" class="question-box">
  <div [ngSwitch]="question.type" class="">
    <app-input
      *ngSwitchCase="'text'"
      [question]="question"
      [formGroup]="formGroup"
      [answerData]="question.isSolutionName ? getSolutionName() : answerData[question.id]"
      [isFormSubmitted]="isFormSubmitted"
    ></app-input>
    <app-textarea
      *ngSwitchCase="'textarea'"
      [question]="question"
      [formGroup]="formGroup"
      [answerData]="answerData[question.id]"
      [isFormSubmitted]="isFormSubmitted"
    ></app-textarea>
    <div *ngSwitchCase="'radio'">
      <app-radio
        *ngIf="!checkInputRequired(question); else radioWithOthers"
        [question]="question"
        [formGroup]="formGroup"
        [answerData]="answerData[question.id]"
        [isFormSubmitted]="isFormSubmitted"
      ></app-radio>
      <ng-template #radioWithOthers>
        <app-radio-with-other-input
          [question]="question"
          [formGroup]="formGroup"
          [answerData]="answerData[question.id]"
          [isFormSubmitted]="isFormSubmitted"
        ></app-radio-with-other-input>
      </ng-template>
    </div>
    <app-select
      *ngSwitchCase="'single-select'"
      [question]="question"
      [formGroup]="formGroup"
      [answerData]="answerData[question.id]"
      [isFormSubmitted]="isFormSubmitted"
    ></app-select>
    <app-multi-select
      *ngSwitchCase="'multi-select'"
      [question]="question"
      [formGroup]="formGroup"
      [answerData]="answerData[question.id]"
      [isFormSubmitted]="isFormSubmitted"
    ></app-multi-select>
  </div>
  <span class="add-note-box" *ngIf="availableNotes(question.id) && !skipQuestion(formGroup, question.id, question.type, question.options)"
    ><button (click)="noteComponent?.addNoteControl(question.id); $event.preventDefault()" [disabled]="isFormSubmitted" class="btn btn-sm p-0 border-0" id="noteBox">
      <img class="add-note img-static" src="assets/images/edit.png" alt="edit" matTooltip="Add Notes" /></button
  ></span>
  <app-file-upload
    *ngIf="question.isFileAttachment && !skipQuestion(formGroup, question.id, question.type, question.options)"
    [question]="question"
    [attachments]="attachments"
    [stepId]="stepId"
    [isFormSubmitted]="isFormSubmitted"
    (getAttachments)="getAttachmentsHere()"
  ></app-file-upload>
  <app-note
    #noteComponent
    *ngIf="!skipQuestion(formGroup, question.id, question.type, question.options)"
    [question]="question"
    [formGroup]="formGroup"
    [isFormSubmitted]="isFormSubmitted"
    [noteGroup]="noteGroup"
    [stepId]="stepId"
  ></app-note>
</div>
