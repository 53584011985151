export const environment = {
  production: false,
  baseUrl: 'https://dch.argusservices.in/api',
  environment: 'development',
  idle_timeout: 9 * 60 * 1000,
  countdown_timeout: 60 * 1000,
  refresh_editor_interval: 9 * 60 * 1000 + 50 * 1000,
  resend_twoFactor_code_timeout: 30000,
  recaptcha_site_key: import.meta.env.NG_APP_RECAPTCHA_SITE_KEY
};
