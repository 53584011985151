<div class="d-flex justify-content-end">
  <nav aria-label="Page navigation  example" class="cst_pagination">
    <ul class="pagination">
      <li *ngIf="currentPage !== 1" class="pagination-arrows" (click)="setToGivenPage(1)"><<</li>

      <li class="pagination-arrows" (click)="onPrev()"><</li>
      <pagination-controls [directionLinks]="false" previousLabel="" nextLabel="" [responsive]="true" (pageChange)="pageChanged($event)"></pagination-controls>
      <li class="pagination-arrows" (click)="onNext()">></li>

      <li *ngIf="currentPage !== totalPages" class="pagination-arrows" (click)="setToGivenPage(totalPages)">>></li>
    </ul>
  </nav>
</div>
