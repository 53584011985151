import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Question } from 'src/app/shared/models/question.model';
import { SharedService } from 'src/app/shared/services/shared.service';
import { FormService } from 'src/app/vendor/form/form.service';

@Component({
  selector: 'app-radio-with-dropdown',
  templateUrl: './radio-with-dropdown.component.html',
  styleUrls: ['./radio-with-dropdown.component.scss']
})
export class RadioWithDropdownComponent implements OnInit {
  @Input() question!: Question;
  @Input() formGroup!: FormGroup;
  @Input() isPreSubmission = false;
  @Input() options!: any;
  displayApplications = false;
  radioDropdownControl!: FormControl;
  radioControl!: FormControl;

  constructor(private readonly formService: FormService, private readonly sharedService: SharedService) {}

  ngOnInit(): void {
    const validators = this.question.isRequired ? [Validators.required] : [];
    this.radioDropdownControl = new FormControl('', validators);
    this.formGroup.addControl(this.question.id, this.radioDropdownControl);

    this.radioControl = new FormControl('', validators);
    this.formGroup.addControl('radio-' + this.question.id, this.radioControl);

    // If skip logic exists
    if (this.question.skipLogic) {
      this.formService.skipLogic(this.question.skipLogic, this.formGroup, this.question.id, this.question.isRequired, this.isPreSubmission);
    }
    this.radioControl.valueChanges.subscribe((value) => {
      value = !!value;
      if (value) {
        this.formGroup.controls[this.question.id]?.addValidators(Validators.required);
      } else {
        this.formGroup.controls[this.question.id]?.clearValidators();
      }
      this.formGroup.controls[this.question.id].updateValueAndValidity({ emitEvent: false });
      this.displayApplications = value;
    });
  }

  getOptions() {
    return this.options.map((option: any) => ({ id: option.id, label: option.name }));
  }

  selectValueChange(value: string) {
    this.radioDropdownControl.setValue(value);
  }

  hasError() {
    return this.sharedService.hasError(this.formGroup, this.question.id);
  }

  skipQuestion(): boolean {
    return this.formService.question(this.formGroup, this.question.id)['skipQuestion'];
  }
}
