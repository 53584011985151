import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ReviewerService {
  private readonly isFormActiveSubject = new BehaviorSubject<any>(null);
  isFormActive$ = this.isFormActiveSubject.asObservable();

  constructor(private readonly http: HttpClient) {}
  setToggleValue(value: any) {
    this.isFormActiveSubject.next(value);
  }

  getReviewsList(callDetails: any) {
    return this.http.get(`${environment.baseUrl}/solution/all`, { params: callDetails });
  }

  getAssignmentStatus(callDetails: any) {
    return this.http.get(`${environment.baseUrl}/review/get-reviewer-assignment-status`, { params: callDetails });
  }

  saveAssignmentStatus(solutionId: string, status: string, rejectReason: string) {
    return this.http.post(`${environment.baseUrl}/review/reviewer-assignment-status`, { solutionId, status, rejectReason });
  }

  saveAssessmentResponse(reviewDetails: any): Observable<any> {
    return this.http.post(`${environment.baseUrl}/review/assessment`, reviewDetails);
  }
  getAssessmentResponse(reviewDetails: any): Observable<any> {
    return this.http.get(`${environment.baseUrl}/review/assessment`, { params: reviewDetails });
  }
  saveSolutionComments(reviewDetails: any): Observable<any> {
    return this.http.post(`${environment.baseUrl}/review/comment`, reviewDetails);
  }
  getSolutionComments(reviewDetails: any): Observable<any> {
    return this.http.get(`${environment.baseUrl}/review/comment`, { params: reviewDetails });
  }
  getSolutionNotes(reviewDetails: any): Observable<any> {
    return this.http.get(`${environment.baseUrl}/solution/note`, { params: reviewDetails });
  }

  deleteSolutionComments(reviewDetails: any): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-': 'application/json'
      }),
      body: {
        commentId: reviewDetails.commentId
      }
    };

    return this.http.delete(`${environment.baseUrl}/review/comment`, options);
  }
}
