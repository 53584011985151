<div>
  <div *ngFor="let attachment of questionAttachment">
    <div class="attachment-details">
      <a class="cst-badge">
        <span
          class="attachment-text cursor-pointer"
          [matTooltip]="attachment.attachmentUrl || attachment.fileName"
          [matTooltipShowDelay]="500"
          (click)="sharedService.openAttachment(attachment.attachmentUrl, attachment.id)"
          >{{ attachment.attachmentUrl || attachment.fileName }}
        </span>
        <em *ngIf="!isFormSubmitted" class="close bi bi-x-circle-fill cursor-pointer" (click)="onDelete(attachment.id)"></em>
      </a>
      <p>
        <span>{{ 'shared.attachment.reference' | translate }}</span
        >{{ attachment.referenceText }}
      </p>
    </div>
  </div>

  <!-- Modal for URL -->
  <div
    class="modal attachment-modal fade"
    [id]="question.id.replaceAll('.', '') + 'staticBackdrop'"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="staticBackdropLabel"><em class="bi bi-link me-2"></em>{{ 'shared.attachment.url_modal_name' | translate }}</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="resetConfig()"></button>
        </div>
        <div class="modal-body">
          <div class="mb-3 cst_form">
            <label for="exampleInputPassword1" class="form-label">{{ 'shared.attachment.add_url_field_name' | translate }}</label>
            <input type="text" id="exampleInputPassword1" class="form-control w-100" [(ngModel)]="inputUrl" maxlength="255" />
          </div>
          <div class="mb-3 cst_form">
            <label for="exampleInputPassword1" class="form-label">{{ 'shared.attachment.reference_note' | translate }}</label>
            <input type="text" id="exampleInputPassword1" class="form-control w-100" [(ngModel)]="referenceText" maxlength="255" />
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn link_btn" data-bs-dismiss="modal" (click)="resetConfig()">{{ 'shared.attachment.cancel' | translate }}</button>
          <button type="button" class="btn cst_btn" (click)="onSubmit('url')" data-bs-dismiss="modal" [class.disabled]="!inputUrl || !referenceText">
            {{ 'shared.attachment.submit' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal for New File -->
  <div
    class="modal attachment-modal fade"
    [id]="question.id.replaceAll('.', '') + 'staticBackdrop1'"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="staticBackdropLabel"><em class="bi bi-file-earmark-diff me-2"></em>{{ 'shared.attachment.file_modal_name' | translate }}</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="resetConfig()"></button>
        </div>
        <div class="modal-body">
          <div class="mb-3 cst_form">
            <label for="exampleInputPassword1" class="form-label">{{ 'shared.attachment.add_file_field_name' | translate }}</label>
            <input #newFileRef class="form-control w-100" type="file" id="formFile" (change)="onFileChange($event)" />
            <div class="invalid_feedback" *ngIf="hasError">{{ 'alert.file_selection_invalid' | translate }}</div>
            <div class="invalid_feedback" *ngIf="hasTypeError">{{ 'alert.file_type_selection_invalid' | translate }}</div>
          </div>
          <div class="mb-3 cst_form">
            <label for="exampleInputPassword1" class="form-label">{{ 'shared.attachment.reference_note' | translate }}</label>
            <input type="text" id="exampleInputPassword1" class="form-control w-100" [(ngModel)]="referenceText" maxlength="255" />
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn link_btn" data-bs-dismiss="modal" (click)="resetConfig()">{{ 'shared.attachment.cancel' | translate }}</button>
          <button type="button" class="btn cst_btn" (click)="onSubmit('file')" data-bs-dismiss="modal" [class.disabled]="!inputFile || !referenceText">
            {{ 'shared.attachment.submit' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- right side bar -->

  <div
    class="offcanvas mycomment-sidebar offcanvas-end"
    tabindex="-1"
    [id]="question.id.replaceAll('.', '') + 'offcanvasRight'"
    aria-labelledby="offcanvasRightLabel"
    aria-hidden="true"
  >
    <div class="offcanvas-header">
      <h5 id="offcanvasRightLabel" class="mb-0">{{ 'shared.attachment.attach_file_modal_name' | translate }}</h5>
      <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close" (click)="resetConfig()"></button>
    </div>
    <div class="offcanvas-body select-file">
      <div class="form-check cst-radio" *ngFor="let file of files; let index = index">
        <input
          class="form-check-input"
          type="radio"
          name="flexRadioDefault"
          [id]="question.id.replaceAll('.', '') + 'flexRadioDefault' + index"
          (change)="onFileChange($event)"
          [value]="file.fileId"
        />
        <label class="form-check-label" [for]="question.id.replaceAll('.', '') + 'flexRadioDefault' + index"> {{ file.fileName }} ({{ file.questionId }}) </label>
      </div>
      <div class="invalid_feedback" *ngIf="hasAttachError">{{ 'alert.attach_file_selection_invalid' | translate }}</div>
    </div>
    <div class="offcanvas-footer">
      <div class="mb-4 cst_form">
        <label for="exampleInputPassword1" class="form-label">{{ 'shared.attachment.reference_note' | translate }}</label>
        <input type="text" class="form-control w-100" id="exampleInputPassword1" [(ngModel)]="referenceText" maxlength="255" />
      </div>
      <div class="cst-border-top">
        <button type="button" class="btn link_btn me-3" data-bs-dismiss="offcanvas" (click)="resetConfig()">{{ 'shared.attachment.close' | translate }}</button>
        <button type="button" class="btn cst_btn" (click)="onSubmit('selectedFile')" data-bs-dismiss="offcanvas" [class.disabled]="!inputFile || !referenceText || hasAttachError">
          {{ 'shared.attachment.attach' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
