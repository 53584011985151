<div class="main-content">
  <app-breadcrumb
    [title]="'breadcrumb.new_user' | translate | titlecase"
    [items]="[
      { title: 'home', link: '/admin/users/user-list' },
      { title: 'all_users', link: '/admin/users/user-list' },
      { title: 'new_user', link: '' }
    ]"
  >
  </app-breadcrumb>

  <div class="whitebox_container">
    <div *ngIf="isError" class="alert alert-danger cst-alert d-flex" role="alert"><em class="pe-2 bi bi-exclamation-triangle"></em> {{ isError | translate }}</div>
    <form [formGroup]="addNewUserForm" (ngSubmit)="onSubmit()">
      <div class="row">
        <div class="col-lg-6 col-12">
          <div class="mb-4 cst_form me-lg-5">
            <label for="firstName" class="form-label">{{ 'shared.first_name' | translate }}</label>
            <input type="text" id="firstName" class="form-control" formControlName="firstName" />
          </div>
        </div>
        <div class="col-lg-6 col-12">
          <div class="mb-4 cst_form ms-lg-5">
            <label for="lastName" class="form-label">{{ 'shared.last_name' | translate }}</label>
            <input type="text" id="lastName" class="form-control" formControlName="lastName" />
          </div>
        </div>
        <div class="col-lg-6 col-12">
          <div class="mb-4 cst_form me-lg-5">
            <label for="email" class="form-label">{{ 'shared.email' | translate }}<span>*</span></label>
            <input
              [class.invalid]="(addNewUserForm.get('email')?.invalid && (addNewUserForm.get('email')?.dirty || addNewUserForm.get('email')?.touched)) || isError"
              type="email"
              id="email"
              class="form-control"
              formControlName="email"
            />
            <div *ngIf="addNewUserForm.get('email')?.touched && addNewUserForm.get('email')?.invalid">
              <div *ngIf="addNewUserForm.get('email')?.hasError('required')">
                <span class="invalid_feedback">{{ 'alert.email_required' | translate }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-12">
          <div class="mb-4 cst_form ms-lg-5">
            <label for="role" class="form-label">{{ 'shared.role' | translate }}<span>*</span></label>
            <app-ng-select-custom
              [isMultiple]="false"
              [isClearable]="false"
              [isSearchable]="false"
              [items]="roleItems"
              [selectedItems]="addNewUserForm.controls['role'].value"
              (selectedItemChange)="roleChange($event)"
            ></app-ng-select-custom>
          </div>
        </div>
        <div class="col-lg-6 col-12">
          <div class="mb-4 cst_form me-lg-5">
            <label for="country" class="form-label">{{ 'shared.country' | translate }}</label>
            <app-ng-select-custom
              [isMultiple]="false"
              [isClearable]="false"
              [isSearchable]="true"
              [isPlaceholder]="true"
              [items]="countries"
              [selectedItems]="addNewUserForm.controls['country'].value || null"
              (selectedItemChange)="countryChange($event)"
            ></app-ng-select-custom>
          </div>
        </div>
        <div class="col-lg-6 col-12">
          <div class="mb-4 cst_form ms-lg-5">
            <label for="contactNo" class="form-label">{{ 'shared.contact_no' | translate }}</label>
            <input
              [class.invalid]="addNewUserForm.get('contactNo')?.invalid && (addNewUserForm.get('contactNo')?.dirty || addNewUserForm.get('contactNo')?.touched)"
              type="tel"
              id="contactNo"
              class="form-control"
              formControlName="contactNo"
            />
            <div *ngIf="addNewUserForm.get('contactNo')?.touched && addNewUserForm.get('contactNo')?.invalid">
              <div *ngIf="addNewUserForm.get('contactNo')?.errors?.pattern">
                <span class="invalid_feedback">{{ 'alert.contact_no_invalid' | translate }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-12">
          <div class="cst_form me-lg-5">
            <label for="companyName" class="form-label">{{ 'shared.company_name' | translate }}<span>*</span></label>
            <input
              [class.invalid]="addNewUserForm.get('companyName')?.invalid && (addNewUserForm.get('companyName')?.dirty || addNewUserForm.get('companyName')?.touched)"
              type="text"
              id="companyName"
              class="form-control"
              formControlName="companyName"
            />
          </div>
          <div *ngIf="addNewUserForm.get('companyName')?.touched && addNewUserForm.get('companyName')?.invalid">
            <div *ngIf="addNewUserForm.get('companyName')?.hasError('required')">
              <span class="invalid_feedback">{{ 'alert.company_required' | translate }}</span>
            </div>
          </div>
        </div>

        <div class="col-lg-6 col-12">
          <div class="mb-4 cst_form ms-lg-5">
            <label for="jobTitle" class="form-label">{{ 'shared.job_title' | translate | titlecase }}</label>
            <input type="text" id="jobTitle" class="form-control" formControlName="jobTitle" />
          </div>
        </div>
        <div class="col-12 text-end">
          <button type="button" class="btn link_btn me-4 cancel_hover" routerLink="/admin/users/user-list">{{ 'shared.cancel' | translate | titlecase }}</button>
          <button
            class="btn cst_btn"
            [ngClass]="{
              disabled: !addNewUserForm.valid || isError || inProcess
            }"
            [disabled]="!addNewUserForm.valid || isError || inProcess"
          >
            {{ 'shared.add' | translate | titlecase }}
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
