<app-base-header></app-base-header>

<!-- carousel -->

<div id="carouselIndicators" class="carousel slide cst-carousel" data-bs-ride="carousel">
  <div class="carousel-indicators">
    <button type="button" data-bs-target="#carouselIndicators" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
    <button type="button" data-bs-target="#carouselIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
    <button type="button" data-bs-target="#carouselIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
  </div>
  <div class="carousel-inner cursor-default">
    <div class="carousel-item active">
      <img src="assets/images/banner1.png" alt="banner1" class="d-block w-100 img-static" />
      <div class="carousel-caption d-none d-sm-block">
        <h5>{{ 'welcome.carousel.dch.title' | translate }}</h5>
        <p>
          {{ 'welcome.carousel.dch.sub-text' | translate }}
        </p>
        <button type="button" class="btn register_btn">{{ 'welcome.button.learn_more' | translate }}</button>
      </div>
    </div>
    <div class="carousel-item">
      <img src="assets/images/banner2.png" alt="banner2" class="d-block w-100 img-static" />
      <div class="carousel-caption d-none d-sm-block">
        <h5>{{ 'welcome.carousel.ddcc.title' | translate }}</h5>
        <p>
          {{ 'welcome.carousel.ddcc.sub-text' | translate }}
        </p>
        <button type="button" class="btn register_btn">{{ 'welcome.button.learn_more' | translate }}</button>
      </div>
    </div>
    <div class="carousel-item">
      <img src="assets/images/banner3.png" alt="banner3" class="d-block w-100 img-static" />
      <div class="carousel-caption d-none d-sm-block">
        <h5>{{ 'welcome.carousel.dch_country_governments.title' | translate }}</h5>
        <p>
          {{ 'welcome.carousel.dch_country_governments.sub-text' | translate }}
        </p>
        <button type="button" class="btn register_btn">{{ 'welcome.button.learn_more' | translate }}</button>
      </div>
    </div>
  </div>
</div>

<!-- About us  -->

<section id="about-us" class="about-us scroll-offset">
  <div class="container cursor-default">
    <div class="row">
      <div class="col-md-7 col-12">
        <h1 class="title">{{ 'welcome.about_us.title' | translate }}</h1>
        <p class="detail-text">
          {{ 'welcome.about_us.sub_text' | translate }}
          <span>
            {{ 'welcome.about_us.sub_text_highlight' | translate }}
          </span>
        </p>
        <p class="detail-text">
          {{ 'welcome.about_us.sub_text_2' | translate }}
          <span> {{ 'welcome.about_us.sub_text_2_highlight' | translate }} </span>
        </p>
        <p class="detail-text">
          {{ 'welcome.about_us.sub_text_3' | translate }}
        </p>
        <p class="detail-text">
          {{ 'welcome.about_us.sub_text_4' | translate
          }}<a target="_blank" rel="noopener" href="https://www.who.int/initiatives/global-initiative-on-digital-health">{{ 'welcome.about_us.sub_text_5' | translate }}</a>
          {{ 'welcome.about_us.sub_text_6' | translate }}
        </p>
      </div>
      <div class="col-md-5 col-12">
        <img class="about-us-img img-static" src="assets/images/about-us.png" alt="about-us" />
      </div>
    </div>
  </div>
</section>

<!-- Assessment Framework       -->

<section id="assessment-framework" class="framework-assessment scroll-offset">
  <div class="container cursor-default">
    <div class="row align-items-center">
      <div class="col-md-5 col-12">
        <img class="framework-img img-static" src="assets/images/executives-reviewing-last-report 1.png" alt="executives-reviewing-last-report 1" />
      </div>
      <div class="col-md-7 col-12">
        <h1 class="title">{{ 'welcome.assessment_framework.title' | translate }}</h1>
        <p class="detail-text">
          {{ 'welcome.assessment_framework.description' | translate }}
        </p>
        <div>
          <ul>
            <li class="yellow">
              <span>1</span>
              {{ 'welcome.assessment_framework.point_1' | translate }}
            </li>
            <li class="red">
              <span>2</span>
              {{ 'welcome.assessment_framework.point_2' | translate }}
            </li>
            <li class="blue">
              <span>3</span>
              {{ 'welcome.assessment_framework.point_3' | translate }}
            </li>
            <li class="purple">
              <span>4</span>
              {{ 'welcome.assessment_framework.point_4' | translate }}
            </li>
            <li class="green">
              <span>5</span>
              {{ 'welcome.assessment_framework.point_5' | translate }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- Benifits  -->

<section id="benefits" class="benifits scroll-offset">
  <div class="container cursor-default">
    <div class="row align-items-center">
      <div class="col-xl-4 col-12">
        <div class="benifits-title">
          <h1 class="title">{{ 'welcome.benefits.title' | translate }}</h1>
          <img class="benifitsbg-img img-static" src="assets/images/benifits.png" alt="benifits" />
        </div>
      </div>
      <div class="col-xl-8 col-12">
        <div class="d-flex flex-wrap justify-content-xl-end justify-content-center">
          <div class="benifits-box mt-3">
            <img class="icon img-static" src="assets/images/Solution_developers.svg" alt="Solution_developers" />
            <h4>{{ 'welcome.benefits.solution_developers.title' | translate }}</h4>
            <p class="text">
              {{ 'welcome.benefits.solution_developers.description' | translate }}
            </p>
            <p class="pdf">
              <img src="assets/images/pdf.svg" alt="pdf" class="img-static" />
              {{ 'welcome.benefits.solution_developers.pdf_name' | translate }}
            </p>
            <div class="text-center">
              <button (click)="downloadData('whoch_solutiondevelopersbrief_designed')" type="button" class="btn cursor">
                <em class="bi bi-download"></em>
                {{ 'welcome.button.download' | translate }}
                <span *ngIf="pdfs && pdfs.has('whoch_solutiondevelopersbrief_designed')"> ({{ getPdfSize('whoch_solutiondevelopersbrief_designed') }} KB) </span>
              </button>
            </div>
          </div>
          <div class="benifits-box purple mt-xl-5 mt-3">
            <img class="icon img-static" src="assets/images/Funding_organizations.svg" alt="Funding_organizations" />
            <h4>{{ 'welcome.benefits.funding_organizations.title' | translate }}</h4>
            <p class="text">
              {{ 'welcome.benefits.funding_organizations.description' | translate }}
            </p>
            <p class="pdf">
              <img src="assets/images/pdf.svg" alt="pdf" class="img-static" />
              {{ 'welcome.benefits.funding_organizations.pdf_name' | translate }}
            </p>
            <div class="text-center">
              <button (click)="downloadData('whoch_fundersbrief_designed')" class="btn">
                <em class="bi bi-download"></em>
                {{ 'welcome.button.download' | translate }}
                <span *ngIf="pdfs && pdfs.has('whoch_fundersbrief_designed')"> ({{ getPdfSize('whoch_fundersbrief_designed') }} KB) </span>
              </button>
            </div>
          </div>
          <div class="benifits-box yellow mt-3">
            <img class="icon img-static" src="assets/images/Country_governments.svg" alt="Country_governments" />
            <h4>{{ 'welcome.benefits.country_governments.title' | translate }}</h4>
            <p class="text">
              {{ 'welcome.benefits.country_governments.description' | translate }}
            </p>
            <p class="pdf">
              <img src="assets/images/pdf.svg" alt="pdf" class="img-static" />
              {{ 'welcome.benefits.country_governments.pdf_name' | translate }}
            </p>
            <div class="text-center">
              <button (click)="downloadData('whoch_countrybrief_designed')" type="button" class="btn">
                <em class="bi bi-download"></em>
                {{ 'welcome.button.download' | translate }}
                <span *ngIf="pdfs && pdfs.has('whoch_countrybrief_designed')"> ({{ getPdfSize('whoch_countrybrief_designed') }} KB) </span>
              </button>
            </div>
          </div>
          <div class="benifits-box green mt-xl-5 mt-3">
            <img class="icon img-static" src="assets/images/Implementers.svg" alt="Implementers" />
            <h4>{{ 'welcome.benefits.implementers.title' | translate }}</h4>
            <p class="text">
              {{ 'welcome.benefits.implementers.description' | translate }}
            </p>
            <p class="pdf">
              <img src="assets/images/pdf.svg" alt="pdf" class="img-static" />
              {{ 'welcome.benefits.implementers.pdf_name' | translate }}
            </p>
            <div class="text-center">
              <button (click)="downloadData('whoch_implementersbrief_designed')" type="button" class="btn">
                <em class="bi bi-download"></em>
                {{ 'welcome.button.download' | translate }}

                <span *ngIf="pdfs && pdfs.has('whoch_implementersbrief_designed')"> ({{ getPdfSize('whoch_implementersbrief_designed') }} KB) </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- Assessment Process  -->

<section id="process" class="assessment-process scroll-offset">
  <div class="container cursor-default">
    <h1 class="title">{{ 'welcome.process.title' | translate }}</h1>
    <div class="row justify-content-md-center">
      <div class="col-md-8 col-12">
        <div class="d-flex flex-wrap justify-content-xl-center justify-content-lg-between justify-content-center">
          <div class="process-box">
            <h1>{{ 'welcome.process.expression_of_interest.title' | translate }}</h1>
            <div class="detail-text">
              <img src="assets/images/expression.png" alt="expression" class="img-static" />
              <p>
                {{ 'welcome.process.expression_of_interest.description' | translate }}
              </p>
            </div>
          </div>
          <div>
            <img class="img1 img-static" src="assets/images/1.png" alt="1" />
          </div>
          <div class="process-box orange">
            <h1>{{ 'welcome.process.solution_intake.title' | translate }}</h1>
            <div class="detail-text">
              <img src="assets/images/solution.png" alt="solution" class="img-static" />
              <p>
                {{ 'welcome.process.solution_intake.description' | translate }}
              </p>
            </div>
            <img class="img2 img-static" src="assets/images/2.png" alt="2" />
          </div>

          <div class="process-box purple">
            <h1>{{ 'welcome.process.detailed_assessment.title' | translate }}</h1>
            <div class="detail-text">
              <img src="assets/images/detailed.png" alt="detailed" class="img-static" />
              <p>
                {{ 'welcome.process.detailed_assessment.description' | translate }}
              </p>
            </div>
          </div>
          <div>
            <img class="img3 img-static" src="assets/images/3.png" alt="3" />
          </div>
          <div class="process-box blue">
            <h1>{{ 'welcome.process.review.title' | translate }}</h1>
            <div class="detail-text">
              <img src="assets/images/review.png" alt="review" class="img-static" />
              <p>
                {{ 'welcome.process.review.description' | translate }}
              </p>
            </div>
            <img class="img4 img-static" src="assets/images/4.png" alt="4" />
          </div>
          <div class="green-process-box">
            <h1>{{ 'welcome.process.publication_of_results.title' | translate }}</h1>
            <div class="detail-text">
              <img src="assets/images/result.png" alt="result" class="img-static" />

              <p>
                {{ 'welcome.process.publication_of_results.description' | translate }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- Curated Solution  -->
<section id="curated-solutions" class="curated-solutions scroll-offset">
  <div class="container cursor-default">
    <h1 class="title">
      WHO Digital Clearinghouse
      <span class="d-block">Curated Solutions</span>
    </h1>
    <div *ngIf="!solutions.length" style="text-align: center">No Curated Solutions</div>
    <div class="row justify-content-md-start">
      <div class="col-lg-4 col-md-6 col-12" *ngFor="let solution of solutions; let i = index">
        <div class="solution-card-box mb-3" [routerLink]="'/search-solution/' + solution.solutionId">
          <div class="dha-badge" [ngClass]="getbgColor(solution.health_architecture_label)">{{ solution.health_architecture_label }}</div>
          <img class="solution-img" [src]="getImages(i)" />
          <h1>{{ solution.name }}</h1>
          <div class="row cst-border-bottom">
            <div class="col-6 card-details">
              <label>Cost modal:</label>
              <p>{{ solution.cost_model_label.split('||') }}</p>
            </div>
            <div class="col-6 card-details">
              <label>Distribution modal:</label>
              <p>{{ solution.distribution_model_label.split('||') }}</p>
            </div>
            <div class="col-12 card-details mb-3">
              <label>Language supported:</label>
              <p class="mat-tooltip-trigger language-ellipsis">{{ solution.languages_label.split('||') }}</p>
            </div>
          </div>
          <div class="row p-2">
            <div class="col-6 text-start browser-list p-0">
              <img *ngIf="solution.browsers_label.split('||').includes('Safari')" src="assets/images/Safari_browser_logo (1) 2.png" alt="safari_logo" />
              <img *ngIf="solution.browsers_label.split('||').includes('Chrome')" src="assets/images/Google_Chrome_icon_(February_2022) 2.png" alt="chrome_logo" />
              <img *ngIf="solution.browsers_label.split('||').includes('Internet Explorar')" src="assets/images/Internet_Explorer_10+11_logo 2.png" alt="internet_explorer_logo" />
            </div>
            <div class="col-6 text-end browser-list p-0">
              <img *ngIf="solution.operating_systems_label.includes('IOS')" src="assets/images/Apple_logo_black (1) 1.png" alt="apple_logo" />
              <img *ngIf="solution.operating_systems_label.includes('Android')" src="assets/images/android-logo (1) 2.png" alt="android_logo" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="text-center mt-5">
      <button type="button" class="btn register_btn" routerLink="/search">View All Solutions</button>
    </div>
  </div>
</section>

<!-- get in touch  -->

<section id="get-in-touch" class="get-in-touch">
  <div class="text-container">
    <p class="cursor-default">
      {{ 'welcome.get_in_touch.sub_text' | translate }}
    </p>
    <button type="button" class="btn">{{ 'welcome.button.get_in_touch' | translate }}</button>
  </div>
</section>

<!-- Footer  -->

<footer>
  <div class="container">
    <div class="row">
      <div class="col-md-8 col-12">
        <img class="logo img-static" src="assets/images/footer-logo.png" alt="home_whitelogo" />
        <p class="about cursor-default">
          {{ 'welcome.footer.sub_text' | translate }}
        </p>
      </div>
      <div class="col-md-4 col-12 mt-auto">
        <p class="social-media">
          {{ 'welcome.footer.follow_us' | translate }}
          <span><em class="bi bi-linkedin cursor-pointer"></em></span>
          <span><em class="bi bi-instagram cursor-pointer"></em></span>
          <span> <em class="bi bi-twitter cursor-pointer"></em></span>
        </p>
        <p class="copyright cursor-default">{{ 'welcome.footer.copy_right' | translate }}</p>
      </div>
    </div>
  </div>
</footer>
