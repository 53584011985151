import { ChangeDetectorRef, Component, HostListener, Injector, OnInit } from '@angular/core';
import { SharedService } from 'src/app/shared/services/shared.service';
import { lastValueFrom } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Location } from '@angular/common';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {
  healthArchs: { id: string; label: string }[] = [];
  filterForm: FormGroup = new FormGroup({});
  systemOptions: any = {};
  systems: { id: string; label: string }[] = [];
  searchedText!: string | null;
  selectedHealthArch: string[] | null = [];
  selectedSystem: string[] | null = [];
  solutions: any[] = [];
  solutionsCount: number = 0;
  currentPage: number = 1;
  totalPages: number = 0;
  loadingSolutions: boolean = false;
  showHeader: boolean = false;

  private sharedService: SharedService;
  private route: ActivatedRoute;
  private router: Router;
  private formBuilder: FormBuilder;
  private location: Location;
  private cdr: ChangeDetectorRef;

  constructor(private injector: Injector) {
    this.sharedService = this.injector.get<SharedService>(SharedService);
    this.route = this.injector.get<ActivatedRoute>(ActivatedRoute);
    this.router = this.injector.get<Router>(Router);
    this.formBuilder = this.injector.get<FormBuilder>(FormBuilder);
    this.location = this.injector.get<Location>(Location);
    this.filterForm = this.formBuilder.group({
      searchedText: ''
    });
    this.cdr = this.injector.get<ChangeDetectorRef>(ChangeDetectorRef);
  }
  @HostListener('window:scroll', ['$event'])
  onScroll() {
    // Detect when the user has scrolled to the bottom of the page
    if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
      this.loadMoreSolutions();
    }
  }
  async ngOnInit() {
    this.route.queryParamMap.subscribe((params) => {
      this.searchedText = params.get('searchedText');
      this.filterForm.controls['searchedText'].setValue(this.searchedText);
      this.selectedHealthArch = params.getAll('selectedHealthArch');
      this.selectedSystem = params.getAll('selectedSystem');
    });

    this.location.onUrlChange((url) => {
      if (url.includes('/curated-solution')) {
        this.showHeader = false;
      } else {
        this.showHeader = true;
      }
    });

    this.filterForm
      .get('searchedText')
      ?.valueChanges.pipe(
        debounceTime(500), // Adjust the debounce time as needed (in milliseconds)
        distinctUntilChanged() // Ensures only distinct values are passed
      )
      .subscribe((value) => {
        this.searchedText = value;
        this.getSolutions();
      });

    await this.getSolutions(true);
  }

  getbgColor(val: string) {
    switch (val) {
      case 'Point of service':
        return 'green';
      case 'Registries & Directories':
        return 'orange';
      case 'Data Management services':
        return 'blue';
      case 'Health system/Provider administration':
        return 'red';
      case 'Surveillance and Response':
        return 'purple';
      default:
        return '';
    }
  }

  getImages(i: number) {
    if (i % 3 === 0) {
      return '../../../assets/images/image 38.png';
    }
    if (i % 3 === 1) {
      return '../../../assets/images/image 31.png';
    }
    if (i % 3 === 2) {
      return '../../../assets/images/image 39.png';
    }
    return '';
  }

  goToSolution(solution: any) {
    const currentUrl = this.location.path();
    if (currentUrl.includes('/vendor/')) {
      this.router.navigate(['/vendor/curated-solution/' + solution.solutionId]);
    } else if (currentUrl.includes('/reviewer/')) {
      this.router.navigate(['/reviewer/curated-solution/' + solution.solutionId]);
    } else if (currentUrl.includes('/who-panel/')) {
      this.router.navigate(['/who-panel/curated-solution/' + solution.solutionId]);
    } else if (currentUrl.includes('/admin/')) {
      this.router.navigate(['/admin/curated-solution/' + solution.solutionId]);
    } else {
      this.router.navigate(['/search-solution/' + solution.solutionId]);
    }
  }

  healthValueChanged(value: string[]) {
    this.selectedHealthArch = value;
    if (value.length === 0) {
      this.systems = [];
      this.selectedSystem = [];
    }
    this.getSolutions();
    value.forEach((val) => {
      this.systems = this.systemOptions[val];
    });
  }

  systemValueChanged(value: string[]) {
    this.selectedSystem = value;
    this.getSolutions();
  }

  loadMoreSolutions() {
    if (this.currentPage < this.totalPages) {
      this.loadingSolutions = true;
      this.currentPage++;
      setTimeout(() => {
        (async () => {
          await this.getSolutions();
          this.cdr.detectChanges();

          this.loadingSolutions = false;
        })();
      }, 2000);
    }
  }

  updateFiltersInUrl() {
    const queryParams = {
      selectedHealthArch: this.selectedHealthArch,
      selectedSystem: this.selectedSystem,
      searchedText: this.searchedText
    };

    const newUrl = this.router
      .createUrlTree([], {
        relativeTo: this.route,
        queryParams,
        queryParamsHandling: 'merge'
      })
      .toString();

    this.location.replaceState(newUrl);
  }

  async getSolutions(isFilter = false) {
    const searchDetails = {
      page: this.currentPage,
      pageSize: 15,
      query: this.searchedText,
      healthArchitecture: this.selectedHealthArch,
      system: this.selectedSystem,
      isFilter
    };
    const solutions = await lastValueFrom(this.sharedService.searchSolution(searchDetails));
    this.solutionsCount = solutions.data.count;
    if (this.currentPage === 1) {
      this.solutions = solutions.data.rows;
    } else {
      this.solutions.push(...solutions.data.rows);
    }

    if (solutions.presubmissionData) {
      this.healthArchs = solutions.presubmissionData.health_architecture;
      this.systemOptions = solutions.presubmissionData.system;
    }

    const colors = ['green', 'pink', 'blue'];

    this.solutions.forEach((solution: any, index: number) => {
      const colorIndex = index % colors.length;
      solution['selectedColor'] = colors[colorIndex];
    });

    this.totalPages = Math.ceil(this.solutionsCount / searchDetails.pageSize);
    this.updateFiltersInUrl();
  }
}
