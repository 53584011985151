import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { BaseComponent } from './base.component';
import { BaseRoutingModule } from './base-routing.module';
import { SharedModule } from '../shared/shared.module';
import { WelcomeComponent } from './welcome/welcome.component';
import { SearchComponent } from './search/search.component';
import { SearchSolutionComponent } from './search/search-solution/search-solution.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgSelectModule } from '@ng-select/ng-select';
import { BaseHeaderComponent } from './base-header/base-header.component';
@NgModule({
  declarations: [BaseComponent, WelcomeComponent, SearchComponent, SearchSolutionComponent, BaseHeaderComponent],
  imports: [CommonModule, BaseRoutingModule, SharedModule, ReactiveFormsModule, FormsModule, MatTooltipModule, NgSelectModule]
})
export class BaseModule {}
