<div class="mb-36" *ngIf="!skipQuestion()">
  <p class="question">
    <span class="bold">{{ question.id }}</span> {{ question.label }}
    <span *ngIf="question.isRequired" class="mandatory">*</span>
  </p>
  <div class="form-check cst-radio" [ngClass]="{ invalid: hasError() }" *ngFor="let option of question.options">
    <input type="radio" id="{{ option.id }}" name="{{ question.id }}" [value]="option.value" [formControl]="radioControl" />
    <label for="{{ option.id }}">{{ option.label }}</label>
  </div>

  <!-- DropDown -->
  <div *ngIf="displayApplications">
    <div class="mt-2">{{ 'vendor.pre_submission.previous_applications' | translate }}</div>
    <app-ng-select-custom
      [isMultiple]="false"
      [isClearable]="false"
      [isSearchable]="false"
      [isPlaceholder]="true"
      [isInvalid]="hasError()"
      [items]="getOptions()"
      [selectedItems]="radioDropdownControl.value || null"
      (selectedItemChange)="selectValueChange($event)"
    ></app-ng-select-custom>

    <div class="invalid_feedback" *ngIf="options.length === 0">{{ 'shared.select.no_options' | translate }}</div>
    <div class="invalid_feedback" *ngIf="options.length && hasError()">{{ 'alert.field_invalid' | translate }}</div>
  </div>
</div>
