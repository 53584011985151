<div class="modal-backdrop"></div>
<div class="modal modal-dialog modal-dialog-centered">
  <!-- The content of the modal, displaying the warning message -->
  <div class="modal-content warning-modal">
    <div class="modal-header">
      <h5 class="modal-title" id="warning-modal"><em class="bi bi-exclamation-triangle-fill"></em>{{ header }}</h5>
    </div>
    <div class="modal-body d-flex">
      <div class="row">
        <p>{{ message }}</p>
        <ng-container *ngIf="currentUser; else logoutBlock">
          <p class="small-text">{{ 'shared.return_to_dashboard' | translate }}</p>
          <p class="small-text">{{ 'shared.changes_save_alert' | translate }}</p>
        </ng-container>
        <ng-template #logoutBlock>
          <p class="small-text">{{ 'shared.logout_message' | translate }}</p>
          <p class="small-text">{{ 'shared.changes_lost_alert' | translate }}</p>
        </ng-template>
      </div>
      <!-- The section displaying the remaining time in the modal -->
      <div id="remainingTime" class="remaining-time-modal">
        <div class="base-timer">
          <!-- SVG element for the circular timer -->
          <svg class="base-timer__svg" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
            <g class="base-timer__circle">
              <!-- The circle representing the elapsed time -->
              <circle class="base-timer__path-elapsed" cx="50" cy="50" r="45"></circle>
              <!-- The path representing the remaining time -->
              <path
                id="base-timer-path-remaining"
                stroke-dasharray="283"
                [class]="'base-timer__path-remaining ' + remainingPathColor"
                d="
                M 50, 50
                m -45, 0
                a 45,45 0 1,0 90,0
                a 45,45 0 1,0 -90,0
              "
              ></path>
            </g>
          </svg>
          <span id="base-timer-label" class="base-timer__label">{{ formatTime(remainingTime) }}</span>
        </div>
      </div>
      <!-- End of the section displaying the remaining time -->
    </div>
    <!-- The footer section with action buttons -->
    <div class="modal-footer justify-content-end" *ngIf="currentUser; else continueBtn">
      <button type="button" class="btn link_btn secondary" (click)="cancel()">{{ 'shared.save_and_close' | translate }}</button>
      <button type="button" class="btn cst_btn" (click)="continueToForm()">{{ 'shared.edit' | translate }}</button>
    </div>
    <ng-template #continueBtn>
      <div class="modal-footer justify-content-end">
        <button type="button" class="btn cst_btn" (click)="continueToForm()">{{ 'shared.continue' | translate }}</button>
      </div>
    </ng-template>
  </div>
</div>
