import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Question } from 'src/app/shared/models/question.model';
import { SharedService } from 'src/app/shared/services/shared.service';
import { FormService } from 'src/app/vendor/form/form.service';

@Component({
  selector: 'app-radio-with-other-input',
  templateUrl: './radio-with-other-input.component.html',
  styleUrls: ['./radio-with-other-input.component.scss']
})
export class RadioWithOtherInputComponent implements OnInit {
  @Input() question!: Question;
  @Input() formGroup!: FormGroup;
  @Input() answerData: any;
  @Input() isPreSubmission = false;
  radioControl!: FormControl;
  otherInputControl!: FormControl;
  radioIdForOtherInput = '';
  showInput = false;
  _isFormSubmitted = false;
  otherValue!: string;

  @Input()
  set isFormSubmitted(formSubmitted: boolean) {
    if (formSubmitted) {
      this._isFormSubmitted = formSubmitted;
      this.otherInputControl?.disable();
    }
  }
  constructor(private readonly formService: FormService, private readonly sharedService: SharedService) {}

  ngOnInit(): void {
    this.question.inputRequired = this.question.options.find((op: any) => op.inputRequired).id;
    this.question.options = this.question.options.sort((a: any, b: any) => a.label < b.label);

    this.radioIdForOtherInput = this.question.inputRequired;
    const validators = this.question.isRequired ? [Validators.required] : [];

    this.setRadioWithOtherInputForm(validators);
    this.setAnswerDataIfExists();
    this.initializeRadioControlSubscription();
    if (this._isFormSubmitted) {
      this.otherInputControl.disable();
      this.radioControl.disable();
    }

    this.formService.skipLogic(this.question.skipLogic, this.formGroup, 'other-' + this.question.id, this.question.isRequired, this.isPreSubmission);
  }

  hasError() {
    return this.sharedService.hasError(this.formGroup, this.question.id);
  }
  setRadioWithOtherInputForm(validators: any) {
    this.otherInputControl = new FormControl('', [...validators, Validators.maxLength(200)]);
    this.formGroup.addControl(this.question.id, this.otherInputControl);

    this.radioControl = new FormControl('', validators);
    this.formGroup.addControl('other-' + this.question.id, this.radioControl);
  }

  setAnswerDataIfExists() {
    if (this.answerData) {
      if (this.sharedService.isUUID(this.answerData)) {
        this.radioControl.setValue(this.answerData, { emitEvent: false });
        this.showInput = false;
      } else {
        this.radioControl.setValue(this.radioIdForOtherInput, { emitEvent: false });
        this.showInput = true;
        this.otherInputControl.setValue(this.answerData);
      }
    }
  }

  initializeRadioControlSubscription() {
    this.radioControl.valueChanges.subscribe((value) => {
      if (value === this.question.inputRequired) {
        this.showInput = true;
        if (this.sharedService.isUUID(this.answerData)) {
          this.answerData = '';
        }
        this.formGroup.controls[this.question.id]?.addValidators(Validators.required);
      } else {
        this.showInput = false;
        this.otherInputControl.setValue(this.radioControl.value, { emitEvent: false });
        this.answerData = '';
        this.formGroup.controls[this.question.id]?.clearValidators();
      }
      this.formGroup.controls[this.question.id].updateValueAndValidity({ emitEvent: false });
    });
  }

  skipQuestion(): boolean {
    return this.formService.question(this.formGroup, 'other-' + this.question.id)?.['skipQuestion'];
  }
}
