<mat-stepper [linear]="isLinear" #stepper>
  <mat-step [stepControl]="firstFormGroup">
    <ng-template matStepLabel>A</ng-template>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          <div>Collapsible Section 1-A</div>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <form [formGroup]="firstFormGroup">
        <mat-form-field appearance="fill">
          <mat-label>Name</mat-label>
          <input matInput placeholder="Last name, First name" formControlName="firstCtrl" required>
        </mat-form-field>

      </form>
    </mat-expansion-panel>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          <div>Collapsible Section 1-B</div>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <form [formGroup]="firstFormGroup">
        <mat-form-field appearance="fill">
          <mat-label>Name</mat-label>
          <input matInput placeholder="Last name, First name" formControlName="firstCtrl" required>
        </mat-form-field>

      </form>
    </mat-expansion-panel>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          <div>Collapsible Section 1-C</div>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <form [formGroup]="firstFormGroup">
        <mat-form-field appearance="fill">
          <mat-label>Name</mat-label>
          <input matInput placeholder="Last name, First name" formControlName="firstCtrl" required>
        </mat-form-field>
      </form>
    </mat-expansion-panel>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          <div>Collapsible Section 1-D</div>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <form [formGroup]="firstFormGroup">
        <mat-form-field appearance="fill">
          <mat-label>Name</mat-label>
          <input matInput placeholder="Last name, First name" formControlName="firstCtrl" required>
        </mat-form-field>
        <div>
          <button mat-button matStepperNext>Next</button>
        </div>
      </form>
    </mat-expansion-panel>
  </mat-step>
  <mat-step [stepControl]="secondFormGroup">
    <ng-template matStepLabel>B</ng-template>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          <div>Collapsible Section 2-A</div>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <form [formGroup]="secondFormGroup">
        <mat-form-field appearance="fill">
          <mat-label>Address</mat-label>
          <input matInput formControlName="secondCtrl" placeholder="Ex. 1 Main St, New York, NY"
                 required>
        </mat-form-field>
        <div>
          <button mat-button matStepperPrevious>Back</button>
          <button mat-button matStepperNext>Next</button>
        </div>
      </form>
    </mat-expansion-panel>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          <div>Collapsible Section 2-B</div>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <form [formGroup]="secondFormGroup">
        <mat-form-field appearance="fill">
          <mat-label>Address</mat-label>
          <input matInput formControlName="secondCtrl" placeholder="Ex. 1 Main St, New York, NY"
                 required>
        </mat-form-field>
        <div>
          <button mat-button matStepperPrevious>Back</button>
          <button mat-button matStepperNext>Next</button>
        </div>
      </form>
    </mat-expansion-panel>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          <div>Collapsible Section 2-C</div>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <form [formGroup]="secondFormGroup">
        <mat-form-field appearance="fill">
          <mat-label>Address</mat-label>
          <input matInput formControlName="secondCtrl" placeholder="Ex. 1 Main St, New York, NY"
                 required>
        </mat-form-field>
        <div>
          <button mat-button matStepperPrevious>Back</button>
          <button mat-button matStepperNext>Next</button>
        </div>
      </form>
    </mat-expansion-panel>
  </mat-step>

  <mat-step [stepControl]="thirdFormGroup">
    <ng-template matStepLabel>C</ng-template>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          <div>Collapsible Section 3-A</div>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <form [formGroup]="thirdFormGroup">
        <mat-form-field appearance="fill">
          <mat-label>Address</mat-label>
          <input matInput formControlName="thirdCtrl" placeholder="Ex. 1 Main St, New York, NY"
                 required>
        </mat-form-field>

      </form>
    </mat-expansion-panel>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          <div>Collapsible Section 3-B</div>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <form [formGroup]="thirdFormGroup">
        <mat-form-field appearance="fill">
          <mat-label>Address</mat-label>
          <input matInput formControlName="thirdCtrl" placeholder="Ex. 1 Main St, New York, NY"
                 required>
        </mat-form-field>

      </form>
    </mat-expansion-panel>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          <div>Collapsible Section 3-C</div>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <form [formGroup]="thirdFormGroup">
        <mat-form-field appearance="fill">
          <mat-label>Address</mat-label>
          <input matInput formControlName="thirdCtrl" placeholder="Ex. 1 Main St, New York, NY"
                 required>
        </mat-form-field>

      </form>
    </mat-expansion-panel>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          <div>Collapsible Section 3-D</div>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <form [formGroup]="thirdFormGroup">
        <mat-form-field appearance="fill">
          <mat-label>Address</mat-label>
          <input matInput formControlName="thirdCtrl" placeholder="Ex. 1 Main St, New York, NY"
                 required>
        </mat-form-field>

      </form>
    </mat-expansion-panel>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          <div>Collapsible Section 3-E</div>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <form [formGroup]="thirdFormGroup">
        <mat-form-field appearance="fill">
          <mat-label>Address</mat-label>
          <input matInput formControlName="thirdCtrl" placeholder="Ex. 1 Main St, New York, NY"
                 required>
        </mat-form-field>

      </form>
    </mat-expansion-panel>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          <div>Collapsible Section 3-F</div>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <form [formGroup]="thirdFormGroup">
        <mat-form-field appearance="fill">
          <mat-label>Address</mat-label>
          <input matInput formControlName="thirdCtrl" placeholder="Ex. 1 Main St, New York, NY"
                 required>
        </mat-form-field>

      </form>
    </mat-expansion-panel>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          <div>Collapsible Section 3-G</div>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <form [formGroup]="thirdFormGroup">
        <mat-form-field appearance="fill">
          <mat-label>Address</mat-label>
          <input matInput formControlName="thirdCtrl" placeholder="Ex. 1 Main St, New York, NY"
                 required>
        </mat-form-field>

      </form>
    </mat-expansion-panel>
  </mat-step>

  <mat-step [stepControl]="fourthFormGroup">
    <ng-template matStepLabel>D</ng-template>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          <div>Collapsible Section 4-A</div>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <form [formGroup]="fourthFormGroup">
        <mat-form-field appearance="fill">
          <mat-label>Address</mat-label>
          <input matInput formControlName="fourthCtrl" placeholder="Ex. 1 Main St, New York, NY"
                 required>
        </mat-form-field>
      </form>
    </mat-expansion-panel>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          <div>Collapsible Section 4-B</div>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <form [formGroup]="fourthFormGroup">
        <mat-form-field appearance="fill">
          <mat-label>Address</mat-label>
          <input matInput formControlName="fourthCtrl" placeholder="Ex. 1 Main St, New York, NY"
                 required>
        </mat-form-field>

      </form>
    </mat-expansion-panel>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          <div>Collapsible Section 4-C</div>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <form [formGroup]="fourthFormGroup">
        <mat-form-field appearance="fill">
          <mat-label>Address</mat-label>
          <input matInput formControlName="fourthCtrl" placeholder="Ex. 1 Main St, New York, NY"
                 required>
        </mat-form-field>

      </form>
    </mat-expansion-panel>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          <div>Collapsible Section 4-D</div>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <form [formGroup]="fourthFormGroup">
        <mat-form-field appearance="fill">
          <mat-label>Address</mat-label>
          <input matInput formControlName="fourthCtrl" placeholder="Ex. 1 Main St, New York, NY"
                 required>
        </mat-form-field>

      </form>
    </mat-expansion-panel>
  </mat-step>
</mat-stepper>
