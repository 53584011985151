<div class="main-content">
  <app-breadcrumb
    [title]="'breadcrumb.change_password' | translate | titlecase"
    [items]="[
      { title: 'home', link: securityService.getLink(userDetails.role) },
      { title: 'change_password', link: '' }
    ]"
  >
  </app-breadcrumb>

  <div class="whitebox_container">
    <div *ngIf="isError" class="alert alert-danger cst-alert d-flex" role="alert"><em class="pe-2 bi bi-exclamation-triangle"></em> {{ displayMessage | translate }}</div>

    <div class="row">
      <form class="cst_form-container mt-4" [formGroup]="changePasswordForm" (ngSubmit)="onUpdate()">
        <div class="col-lg-6 col-12">
          <div class="mb-4 cst_form me-lg-5">
            <label for="oldPassword" class="form-label">{{ 'change_password.old_password' | translate }}</label>
            <input
              type="password"
              id="oldPassword"
              class="form-control"
              formControlName="oldPassword"
              [class.invalid]="changePasswordForm.get('oldPassword')?.invalid && changePasswordForm.get('oldPassword')?.touched && !changePasswordForm.get('oldPassword')?.value"
            />
            <div *ngIf="changePasswordForm.get('oldPassword')?.touched && changePasswordForm.get('oldPassword')?.invalid">
              <div *ngIf="changePasswordForm.get('oldPassword')?.hasError('required')">
                <span class="invalid_feedback">{{ 'change_password.old_password_required' | translate }}</span>
              </div>
            </div>
          </div>
          <div class="mb-4 cst_form me-lg-5">
            <label for="newPassword" class="form-label">{{ 'change_password.new_password' | translate }}</label>
            <input
              type="password"
              id="newPassword"
              class="form-control"
              formControlName="password"
              [class.invalid]="changePasswordForm.get('password')?.invalid && changePasswordForm.get('password')?.touched && !changePasswordForm.get('password')?.value"
            />
            <div *ngIf="changePasswordForm.get('password')?.touched && changePasswordForm.get('password')?.invalid">
              <div *ngIf="changePasswordForm.get('password')?.hasError('required')">
                <span class="invalid_feedback">{{ 'alert.password_required' | translate }} </span>
              </div>
              <div *ngIf="changePasswordForm.get('password')?.hasError('minlength') || changePasswordForm.get('password')?.hasError('pattern')">
                <span class="invalid_feedback">{{ 'alert.password_invalid' | translate }}</span>
              </div>
            </div>
          </div>
          <div class="mb-4 cst_form me-lg-5">
            <label for="confirmPassword" class="form-label">{{ 'shared.confirm_password' | translate }}</label>
            <input
              type="password"
              id="confirmPassword"
              class="form-control"
              formControlName="confirmPassword"
              [class.invalid]="
                changePasswordForm.get('confirmPassword')?.invalid && changePasswordForm.get('confirmPassword')?.touched && !changePasswordForm.get('confirmPassword')?.value
              "
            />
            <div *ngIf="changePasswordForm.get('confirmPassword')?.touched && changePasswordForm.get('confirmPassword')?.invalid">
              <div *ngIf="changePasswordForm.get('confirmPassword')?.hasError('required')">
                <span class="invalid_feedback">{{ 'alert.confirm_password_required' | translate }}</span>
              </div>
            </div>
            <div
              *ngIf="
                changePasswordForm.get('confirmPassword')?.touched && changePasswordForm.hasError('notSame') && !changePasswordForm.get('confirmPassword')?.hasError('required')
              "
            >
              <span class="invalid_feedback">{{ 'alert.password_mismatch' | translate }}</span>
            </div>
          </div>
        </div>

        <div *ngIf="passwordUpdatedSuccessfully">
          <span class="success_message">{{ displayMessage }}</span>
        </div>
        <div class="col-12 text-end">
          <button type="button" class="btn link_btn me-4 cancel_hover" (click)="navigatToDashboard()">{{ 'shared.cancel' | translate | titlecase }}</button>
          <button
            [disabled]="changePasswordForm.invalid || isError"
            class="btn cst_btn"
            [ngClass]="{
              disabled: changePasswordForm.invalid || isError
            }"
          >
            {{ 'shared.update' | translate | titlecase }}
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
