import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { lastValueFrom } from 'rxjs';
import { SharedService } from 'src/app/shared/services/shared.service';
import { SecurityService } from '../security.service';

@Component({
  selector: 'app-verify-account',
  templateUrl: './verify-account.component.html'
})
export class VerifyAccountComponent implements OnInit {
  isLoading = true;
  errorMessage: string = '';
  constructor(
    private readonly activeRoute: ActivatedRoute,
    private readonly securityService: SecurityService,
    private readonly sharedService: SharedService,
    private readonly router: Router
  ) {}

  async ngOnInit(): Promise<void> {
    const token = this.activeRoute.snapshot.queryParamMap.get('token');
    try {
      if (!token) {
        // No token in query params
        throw new Error('user.token_not_found');
      } else {
        // token found
        await lastValueFrom(this.securityService.verifyAccount(token));
        this.isLoading = false;
      }
    } catch (error: any) {
      if (error?.error?.message) this.errorMessage = error.error.message;
      else if (error.message === 'user.token_not_found') this.errorMessage = error.message;
      else {
        this.errorMessage = 'admin.contact_admin';
      }
    }
  }

  returnToLogin() {
    this.router.navigate(['/login']);
  }
}
