<div class="modal-backdrop"></div>
<div class="modal modal-dialog modal-dialog-centered">
  <div class="modal-content warning-modal">
    <div class="modal-header">
      <h5 class="modal-title" id="warning-modal"><em class="bi bi-exclamation-triangle-fill"></em>{{ 'shared.warning.header' | translate }}</h5>
    </div>
    <div class="modal-body">
      <p>{{ 'shared.warning.message' | translate : { name } }}</p>
      <p>{{ 'shared.warning.wish_to_edit' | translate }}</p>
    </div>
    <div class="modal-footer justify-content-between">
      <button type="button" class="btn link_btn" (click)="cancel()">{{ 'shared.cancel' | translate }}</button>
      <div>
        <button type="button" class="btn outline_btn me-2" (click)="continueToForm('edit')">{{ 'shared.edit' | translate }}</button>
        <button type="button" class="btn cst_btn" (click)="continueToForm('read')">{{ 'shared.read_only' | translate }}</button>
      </div>
    </div>
  </div>
</div>
