import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Question } from '../../models/question.model';
import { VendorService } from 'src/app/vendor/vendor.service';
import { ActivatedRoute } from '@angular/router';
import { lastValueFrom } from 'rxjs';
import { FormService } from 'src/app/vendor/form/form.service';
import { SharedService } from '../../services/shared.service';
import { ALLOWED_FILE_TYPES } from '../../allowedFileTypes';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent {
  @Input() question!: Question;
  @Input()
  public set attachments(value: any) {
    this._attachments = value;
    this.setAttachments();
  }
  @Input() stepId!: string;
  @Input() isFormSubmitted: boolean = true;
  @Output() getAttachments = new EventEmitter();
  @ViewChild('newFileRef') newFileRef!: ElementRef;
  _attachments: any[] = [];
  inputFile: File | null | any = null;
  inputUrl = null;
  referenceText = '';
  questionAttachment: any[] = [];
  files: any[] = [];
  stageId: '';
  solutionId: '';
  hasError: boolean = false;
  hasTypeError: boolean = false;
  hasAttachError: boolean = false;
  baseUrl: any = environment.baseUrl;
  constructor(
    public readonly sharedService: SharedService,
    private readonly formService: FormService,
    private readonly vendorService: VendorService,
    private activeRoute: ActivatedRoute,
    private readonly cdr: ChangeDetectorRef
  ) {
    this.stageId = this.activeRoute.snapshot.params['stageid'];
    this.solutionId = this.activeRoute.snapshot.params['solutionid'];
  }

  checkId(id: string) {
    if (id[id.length - 1] === '_') {
      return id.substring(0, id.length - 1);
    }
    return id;
  }

  setAttachments() {
    this.questionAttachment = this._attachments.filter((attachment: any) => attachment.questionId === this.checkId(this.question.id));
    this.files = this._attachments
      .filter((attachment: any) => attachment.fileId && attachment.isFileUploaded)
      .map((attachment: any) => {
        return { fileId: attachment.fileId, fileName: attachment.fileName, questionId: attachment.questionId };
      });
  }

  validateFileType(file: File): boolean {
    const allowedTypes = Object.values(ALLOWED_FILE_TYPES).flatMap((fileType) => {
      return fileType.mimeTypes.concat(fileType.extensions);
    });

    return allowedTypes.some((allowedType) => {
      if (file?.type === allowedType || file?.name?.endsWith(allowedType)) {
        return true;
      } else return;
    });
  }

  onFileChange(event: any) {
    if (event.target.files) {
      const uploadedFile = event.target.files[0];
      const isValidFileType = this.validateFileType(uploadedFile);
      const isInputFileUploaded = this.files.some((file) => file.fileName === uploadedFile.name && file.questionId === this.checkId(this.question.id));

      if (!isValidFileType) {
        this.hasTypeError = true;
        this.hasError = false;
        return;
      }
      if (isInputFileUploaded) {
        this.hasError = true;
        this.hasTypeError = false;
      } else {
        this.hasError = false;
        this.hasTypeError = false;
        this.inputFile = event.target.files[0];
      }
    } else {
      const isFileExistsForSameQuestion = this._attachments.some(
        (attachment: any) => attachment.fileId === event.target.value && attachment.questionId === this.checkId(this.question.id)
      );
      if (isFileExistsForSameQuestion) this.hasAttachError = true;
      else {
        this.hasAttachError = false;
        this.inputFile = event.target.value;
      }
    }
    this.cdr.detectChanges();
  }

  resetConfig() {
    this.inputFile = null;
    this.inputUrl = null;
    this.referenceText = '';
    this.hasError = false;
    this.hasTypeError = false;
    this.hasAttachError = false;
    this.newFileRef.nativeElement.value = '';
  }

  async onSubmit(attachmentType: string) {
    if (attachmentType === 'file') {
      const formData = new FormData();
      formData.append('file', this.inputFile);
      formData.append('solutionId', this.solutionId);
      formData.append('stepId', this.stepId);
      formData.append('questionId', this.checkId(this.question.id));
      formData.append('attachmentType', attachmentType);
      formData.append('fileName', this.inputFile?.name);
      formData.append('referenceText', this.referenceText);
      await lastValueFrom(this.vendorService.saveSolutionAttachments(formData));
    } else {
      await lastValueFrom(
        this.vendorService.saveSolutionAttachments({
          solutionId: this.solutionId,
          stepId: this.stepId,
          questionId: this.checkId(this.question.id),
          attachmentType: attachmentType === 'url' ? 'url' : 'file',
          fileId: this.inputFile || null,
          attachmentUrl: this.inputUrl || null,
          referenceText: this.referenceText
        })
      );
    }

    this.resetConfig();
    this.getAttachments.emit();
  }

  async onDelete(attachmentId: string) {
    try {
      await lastValueFrom(this.vendorService.deleteSolutionAttachment({ attachmentId: attachmentId }));
      this.getAttachments.emit();
    } catch (error) {
      this.sharedService.showDynamicAlert('admin.something_went_wrong');
    }
  }
}
