import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PreSubmissionComponent } from './pre-submission/pre-submission.component';
import { StageComponent } from './stage/stage.component';
import { StepComponent } from './step/step.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { RadioWithDropdownComponent } from './pre-submission/radio-with-dropdown/radio-with-dropdown.component';
import { CategoryComponent } from './category/category.component';
import { SubCategoryComponent } from './sub-category/sub-category.component';
import { RadioWithOtherInputComponent } from './sub-category/radio-with-other-input/radio-with-other-input.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { VendorSummaryComponent } from '../vendor-summary/vendor-summary.component';
import { EvidenceComponent } from '../evidence/evidence.component';

@NgModule({
  declarations: [
    PreSubmissionComponent,
    RadioWithDropdownComponent,
    StageComponent,
    StepComponent,
    CategoryComponent,
    SubCategoryComponent,
    RadioWithOtherInputComponent,
    VendorSummaryComponent,
    EvidenceComponent
  ],
  imports: [CommonModule, SharedModule, MatTooltipModule],
  exports: [PreSubmissionComponent, StageComponent, StepComponent, VendorSummaryComponent, EvidenceComponent]
})
export class FormModule {}
