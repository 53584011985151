export const URLS = {
  login: '/login',
  userProfile: '/user/profile',
  userChangePassword: '/user/change-password',
  admin: {
    dashboard: '/admin/users/user-list',
    registrationRequest: '/admin/users/registration-request',
    assignmentRequest: '/admin/solution/assignment-request',
    publishSolutionRequest: '/admin/solution/publish-solution-request',
    curatedSolution: '/admin/solution/curated-solution',
    addNewUser: '/admin/users/add-user'
  },
  vendor: {
    dashboard: '/vendor/public-calls',
    curatedSolution: '/vendor/curated-solution'
  },
  reviewer: {
    dashboard: '/reviewer/call-list',
    curatedSolution: '/reviewer/curated-solution'
  },
  whopanel: {
    dashboard: '/who-panel/call-list',
    curatedSolution: '/who-panel/curated-solution'
  }
};
