import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { lastValueFrom } from 'rxjs';
import { VendorService } from '../vendor.service';

@Component({
  selector: 'app-add-solution',
  templateUrl: './add-solution.component.html'
})
export class AddSolutionComponent implements OnInit {
  isPreSubmission = false;
  callName!: string;
  callId!: string;
  questions!: object;
  constructor(private readonly router: Router, private readonly activeRoute: ActivatedRoute, private readonly vendorService: VendorService) {}

  async ngOnInit() {
    this.callId = this.activeRoute.snapshot.params['id'];

    // Fetch previous applications and check if the current callId is present in the applications
    try {
      const call = (await lastValueFrom(this.vendorService.solutionExists({ callId: this.callId })))['response'];
      if (call.solutionId && call.stageId) {
        this.router.navigate([`vendor/solution/${call.solutionId}/${call.stageId}`]);
        return;
      }
      this.callName = call.callName;
    } catch (error: any) {
      this.router.navigate(['vendor/public-calls']);
    }
  }

  showPreSubmission() {
    this.isPreSubmission = true;
    this.vendorService.setToggleValue('Pre-submission');
  }
}
