import { Component, ViewEncapsulation, Input, Output, EventEmitter, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import tinymce, { Editor } from 'tinymce';
import { SharedService } from '../../services/shared.service';
import { Location, ViewportScroller } from '@angular/common';

interface Accordion {
  id: number;
  title: string;
  content: string;
  expanded: boolean;
  decision: string;
}

interface ExtendedWindow extends Window {
  showMoreContent?: () => void;
  showLessContent?: () => void;
}

@Component({
  selector: 'app-text-editor',
  templateUrl: 'text-editor.component.html',
  styleUrls: ['text-editor.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EditorComponent implements AfterViewInit, OnDestroy {
  @Input()
  public set accordions(value: any[]) {
    this._accordions = value;
    this.nextAccordionId = value.length + 1;
  }
  @Input() accordionName: string = 'default-accordion-i';
  @Input() contentEditable: boolean = false;
  @Input() hasDecisions: boolean = false;
  @Input() defaultAccordion: string = 'default';
  @Input() newBtnText: string = 'add new';
  @Output() contentChange = new EventEmitter<any>();

  _accordions: any = [];

  editingTitleIndex: number | null = null;
  decisions: any = [
    { id: 'FM', label: 'Fully meets the requirements' },
    { id: 'FMPM', label: 'Fully meets all mandatory requirements and partially meets non-mandatory requirements' },
    { id: 'PM', label: 'Partially meets the requirements' },
    { id: 'DM', label: 'Does not meet the requirements' }
  ];
  nextAccordionId: number = 0;
  editors: Editor[] = [];
  hasEditorLoaded: boolean = false;

  constructor(private readonly sharedService: SharedService, private viewPortScroller: ViewportScroller, private location: Location) {}

  ngAfterViewInit() {
    setTimeout(() => {
      this.sharedService.showDisplayLoader();
    }, 300);
    setTimeout(() => this.initTinyMCE(), 2000);
  }

  async initTinyMCE() {
    const accPromises = [];
    for (const accordion of this._accordions) {
      accPromises.push(this.newEditor(accordion));
    }
    await Promise.all(accPromises);
    this.sharedService.hideDisplayLoader();
    this.hasEditorLoaded = true;
  }

  async newEditor(accordion: any) {
    const initialHeight = 300;
    const e = await tinymce.init({
      selector: `textarea#${this.accordionName}text-control-${accordion.id}`,
      highlight_on_focus: true,
      setup: (editor) => {
        editor.on('init', () => {
          const iframeElement = document.getElementById(`text-control-${accordion.id}_ifr`) as HTMLIFrameElement;
          const iframeWindow = iframeElement?.contentWindow as ExtendedWindow | undefined;

          if (iframeWindow) {
            iframeWindow.showMoreContent = () => {
              editor.setContent(accordion.content);

              tinymce.activeEditor?.execCommand('mceAutoResize');
              editor.getBody().innerHTML +=
                '<span id="read-less" style="cursor: pointer; color: #009ADE; text-decoration:underline;" onclick="window.showLessContent()">Read less</span>';
            };
            iframeWindow.showLessContent = () => {
              const truncatedContent = this.truncateStringByHeight(accordion.content, initialHeight);

              editor.setContent(truncatedContent);

              editor.getBody().innerHTML +=
                '<span id="read-more" style="cursor: pointer; color: #009ADE; text-decoration:underline;" onclick="window.showMoreContent()">Read more</span>';
            };
          }
          if (!this.contentEditable) {
            const truncatedContent = this.truncateStringByHeight(accordion.content, initialHeight);

            editor.setContent(truncatedContent);

            if (accordion.content.length > initialHeight) {
              editor.getBody().innerHTML +=
                '<span id="read-more" style="cursor: pointer; color: #009ADE; text-decoration:underline;" onclick="window.showMoreContent()">Read more</span>';
            }
          } else editor.setContent(accordion.content);
        });

        editor.on('change', () => {
          accordion.content = editor.getContent();

          this.contentChange.emit(this._accordions);
        });
      },

      readonly: !this.contentEditable,
      plugins:
        'lists link image table code wordcount searchreplace autolink fullscreen image quickbars advlist charmap preview anchor visualblocks insertdatetime media emoticons autoresize',
      toolbar: this.contentEditable ? 'undo redo' : false,
      menubar: this.contentEditable,
      statusbar: false,
      resize: true,
      base_url: '/tinymce/',
      suffix: '.min',
      min_height: !this.contentEditable ? 200 : 300,
      height: 300,
      spellchecker_language: 'en',
      browser_spellcheck: true,
      spellchecker_dialog: true,
      branding: false,
      link_title: false,
      target_list: false,
      quickbars_insert_toolbar: false,
      default_link_target: '_blank',
      link_default_protocol: 'https',
      promotion: false,
      placeholder: 'Write your comment here...',
      content_style: !this.contentEditable ? `html, body { overflow: hidden; }` : '',
      autoresize_bottom_margin: 10,
      block_unsupported_drop: true,
      image_title: true,
      automatic_uploads: true,
      file_picker_types: 'image',
      file_picker_callback: (cb) => {
        const input = document.createElement('input');
        input.setAttribute('type', 'file');
        input.setAttribute('accept', 'image/*');

        input.addEventListener('change', (e: any) => {
          const file = e?.target?.files[0];

          const reader: any = new FileReader();
          reader.addEventListener('load', () => {
            const id = 'blobid' + new Date().getTime();
            const blobCache: any = tinymce.activeEditor?.editorUpload.blobCache;
            const base64 = reader.result.split(',')[1];
            const blobInfo = blobCache.create(id, file, base64);
            blobCache.add(blobInfo);

            cb(blobInfo.blobUri(), { title: file.name });
          });
          reader.readAsDataURL(file);
        });

        input.click();
      }
    });

    this.editors.push(e[0]);
    this.sharedService.hideDisplayLoader();
  }

  truncateStringByHeight(originalString: string, maxHeight: number) {
    if (originalString.length <= maxHeight) {
      return originalString;
    }

    return originalString.slice(0, maxHeight) + '...';
  }

  addAccordion() {
    const newAccordion: any = {
      id: this.nextAccordionId,
      title: this.defaultAccordion, // Default title, can be changed by the user
      content: '', // Default content
      expanded: false // Initially collapsed
    };
    if (this.hasDecisions) {
      newAccordion['decision'] = '';
    }
    this.nextAccordionId += 1;
    this._accordions.push(newAccordion);
    setTimeout(() => {
      this.sharedService.showDisplayLoader();
    }, 300);
    setTimeout(async () => {
      await this.newEditor(newAccordion);
      this.editAccordionTitle(this._accordions.length - 1);
    }, 2000);
  }

  scrollToAccordion(id: number) {
    setTimeout(() => {
      const currentUrl = this.location.path();
      if (currentUrl.includes('/admin/publish-solution/')) {
        this.viewPortScroller.setOffset([0, 100]);
      }
      this.viewPortScroller.scrollToAnchor(this.accordionName + id);
    }, 100);
  }

  selectedDecision(id: number, dec: string) {
    for (let i = 0; i < this._accordions.length; i++) {
      if (id === this._accordions[i].id) {
        this._accordions[i].decision = dec;
      }
    }
    this.contentChange.emit(this._accordions);
  }

  editAccordionTitle(index: number) {
    this.editingTitleIndex = index;
    const el: any = document.querySelector(`#${this.accordionName}text-edit-${index}`);
    el?.focus();
  }

  // Method to stop editing accordion title
  stopEditingTitle() {
    this.editingTitleIndex = null;
  }

  // Method to check if accordion title is editable
  isTitleEditable(index: number): boolean {
    return this.editingTitleIndex === index;
  }

  // Method triggered on input change to update accordion title
  onAccordionTitleChange(event: Event, accordion: any) {
    accordion.title = (event.target as HTMLInputElement).value;
  }

  toggleAccordion(accordion: Accordion) {
    accordion.expanded = !accordion.expanded;
  }

  deleteAccordion(accordion: Accordion) {
    const index = this._accordions.indexOf(accordion);
    if (index !== -1) {
      this._accordions.splice(index, 1);
    }
  }

  ngOnDestroy(): void {
    this.editors.forEach((editor) => {
      editor.remove();
    });
    this.editors = [];
  }
}
