<div class="modal-backdrop"></div>
<div class="modal modal-dialog modal-dialog-centered">
  <div class="modal-content thankyou-modal">
    <div class="modal-body">
      <div>
        <img src="assets/images/thankyou.svg" alt="thankyou" class="img-static" />
        <h1>{{ title }}</h1>
        <p>{{ 'shared.thank_you.submission_recieved' | translate }}</p>
      </div>
      <button type="button" class="btn cst_btn" (click)="navigateToHomePage()">{{ 'shared.thank_you.back_to_home_page' | translate }}</button>
    </div>
  </div>
</div>
