<app-base-header *ngIf="showHeader"></app-base-header>

<!-- main content -->

<div class="container">
  <div class="search-min-content" [class.custom-min-contetnt]="!showHeader">
    <div class="cst-heading d-flex justify-content-between align-items-center">
      <h1>{{ 'welcome.curated_solutions' | translate }}</h1>
    </div>
    <div class="align-items-center justify-content-between filter-form mb-2">
      <div [formGroup]="filterForm" class="cst-form d-sm-flex align-items-center">
        <i class="bi bi-funnel me-3 mb-2 d-none d-sm-block"></i>
        <div class="me-3 mb-2" [class.custom-search-container]="showHeader">
          <app-ng-select-custom
            [isMultiple]="true"
            [isClearable]="true"
            [isSearchable]="true"
            [isPlaceholder]="true"
            [placeholderLabel]="'search.health_arch_label' | translate"
            [items]="healthArchs"
            [selectedItems]="selectedHealthArch"
            (selectedItemChange)="healthValueChanged($event)"
          ></app-ng-select-custom>
        </div>
        <div class="mb-2 me-3" [class.custom-search-container]="showHeader">
          <app-ng-select-custom
            [isMultiple]="true"
            [isClearable]="true"
            [isSearchable]="true"
            [isPlaceholder]="true"
            [placeholderLabel]="'search.system_label' | translate"
            [items]="systems"
            [selectedItems]="selectedSystem"
            (selectedItemChange)="systemValueChanged($event)"
            style="width: 100%"
          ></app-ng-select-custom>
        </div>
      </div>
      <div class="d-flex cst-btn-group mb-2" [formGroup]="filterForm">
        <div class="" id=""><em class="bi bi-search"></em></div>
        <input
          type="text"
          class="form-control"
          placeholder="Search by solution name, modal name etc..."
          aria-label="Input group example"
          aria-describedby="btnGroupAddon"
          formControlName="searchedText"
        />
      </div>
    </div>
    <div *ngIf="!solutions.length" style="text-align: center">No Curated Solutions</div>
    <div class="row justify-content-md-start">
      <div class="col-12" [ngClass]="{ 'col-xl-4 col-lg-6': !showHeader, 'col-lg-4 col-md-6': showHeader }" *ngFor="let solution of solutions; let i = index">
        <div class="solution-card-box mb-3" (click)="goToSolution(solution)">
          <div class="dha-badge" [ngClass]="getbgColor(solution.health_architecture_label)">{{ solution.health_architecture_label }}</div>
          <img class="solution-img" [src]="getImages(i)" />
          <h1>{{ solution.name }}</h1>
          <div class="row cst-border-bottom">
            <div class="col-6 card-details">
              <label>Cost model:</label>
              <p>{{ solution.cost_model_label.split('||') }}</p>
            </div>
            <div class="col-6 card-details">
              <label>Distribution model:</label>
              <p>{{ solution.distribution_model_label.split('||') }}</p>
            </div>
            <div class="col-12 card-details mb-3">
              <label>Language supported:</label>
              <p class="mat-tooltip-trigger language-ellipsis">{{ solution.languages_label.split('||') }}</p>
            </div>
          </div>
          <div class="row p-2">
            <div class="col-6 text-start browser-list p-0">
              <img *ngIf="solution.browsers_label.split('||').includes('Safari')" src="assets/images/Safari_browser_logo (1) 2.png" alt="safari_logo" />
              <img *ngIf="solution.browsers_label.split('||').includes('Chrome')" src="assets/images/Google_Chrome_icon_(February_2022) 2.png" alt="chrome_logo" />
              <img *ngIf="solution.browsers_label.split('||').includes('Internet Explorer')" src="assets/images/Internet_Explorer_10+11_logo 2.png" alt="internet_explorer_logo" />
            </div>
            <div class="col-6 text-end browser-list p-0">
              <img *ngIf="solution.operating_systems_label.includes('IOS')" src="assets/images/Apple_logo_black (1) 1.png" alt="apple_logo" />
              <img *ngIf="solution.operating_systems_label.includes('Android')" src="assets/images/android-logo (1) 2.png" alt="android_logo" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="load-solutions" *ngIf="loadingSolutions">Loading More Solutions...</div>
  </div>
</div>
