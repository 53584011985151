import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { lastValueFrom } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { SharedService } from 'src/app/shared/services/shared.service';
import { SecuritySubscriberService } from '../security-subscriber.service';
import { SecurityService } from '../security.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-two-factor-auth',
  templateUrl: './two-factor-auth.component.html',
  styleUrls: ['./two-factor-auth.component.scss']
})
export class TwoFactorAuthComponent implements OnInit {
  twoFactorAuthForm!: FormGroup;
  errorMessage: string = '';
  resendCodeActionAllowed: boolean = true;
  email: string = '';
  code!: number;
  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly securityService: SecurityService,
    private readonly activeRoute: ActivatedRoute,
    private readonly securitySubscriberService: SecuritySubscriberService,
    private readonly sharedService: SharedService
  ) {
    this.twoFactorAuthForm = this.formBuilder.group({
      code: ['', [Validators.required]]
    });
  }

  ngOnInit() {
    const encodedEmail = this.activeRoute.snapshot.params['encodedEmail'];

    // Decoding (Decrypting) a Base64 String back to the Original String
    const decodedEmail = atob(encodedEmail); // Decoding using atob

    this.email = this.obscureEmail(decodedEmail);
  }

  obscureEmail(email: string) {
    const atIndex = email.indexOf('@');
    if (atIndex !== -1) {
      // Extract the domain part of the email
      const domain = email.substring(atIndex);

      // Replace characters in the local part with asterisks, except for the first two characters
      const obscuredLocalPart = email.substring(0, 2) + '*'.repeat(atIndex - 2);

      // Combine the local and domain parts to form the obscured email
      return obscuredLocalPart + domain;
    }
    return email; // Return the original email if it doesn't contain an '@' symbol
  }

  async onSubmit() {
    try {
      //api call to validate the code
      const response = await lastValueFrom(this.securityService.twofactorAuth(+(this.twoFactorAuthForm.value.code)));

      if (response) {
        //set isLoggedIn value true
        this.securitySubscriberService.isLoggedIn.next(true);
        const userRole = response.details.role;

        //navigate the user to their dashboard as per their role
        this.securityService.navigateUser(userRole);

        //show successful login message
        // this.sharedService.showDynamicAlert('admin.login_success', 'success');
      }
    } catch (error: any) {
      if (error?.error?.message) {
        this.errorMessage = error.error.message;
        return;
      }
      this.errorMessage = 'admin.contact_admin';
      //show error message
      this.sharedService.showDynamicAlert(this.errorMessage);
    }
  }

  async resendCode() {
    this.resendCodeActionAllowed = false;

    //resend code action will be disabled for 30 seconds one clicked
    setTimeout(() => {
      if (this.errorMessage.includes('Max limit reached')) {
        this.resendCodeActionAllowed = false;
      } else this.resendCodeActionAllowed = true;
    }, environment.resend_twoFactor_code_timeout);
    try {
      //api call to resend code
      await lastValueFrom(this.securityService.resendTwoFactorCode());
      //show successful message
      this.sharedService.showDynamicAlert('twoFactorAuth.resend_code_success', 'success');
    } catch (error: any) {
      if (error?.error?.message) {
        this.errorMessage = error.error.message;

        return;
      }
      this.errorMessage = 'admin.contact_admin';
      //show error message
      this.sharedService.showDynamicAlert(this.errorMessage);
    }
  }
}
