import { AfterContentInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Question } from 'src/app/shared/models/question.model';
import { FormSubCategory } from './sub-category.model';

@Component({
  selector: 'app-sub-category',
  templateUrl: './sub-category.component.html'
})
export class SubCategoryComponent implements OnInit, AfterContentInit {
  @Input() subcat!: FormSubCategory;
  @Input() formGroup!: FormGroup;
  @Input() answerData: any;
  @Input() isFormSubmitted = false;
  @Input() noteGroup!: FormGroup;
  @Input() attachments: any[] = [];
  @Input() stepId!: string;
  @Output() getAttachments = new EventEmitter();
  questions!: Question[];
  contentLoaded = false;
  @ViewChild('noteComponent') noteComponent: any;

  ngOnInit(): void {
    this.questions = this.subcat.questions;
  }

  checkInputRequired(question: Question): boolean {
    return question.options.some((op: any) => op.inputRequired);
  }

  getAttachmentsHere() {
    this.getAttachments.emit();
  }

  checkKeysExist(formGroup: FormGroup, data: any[]): boolean {
    const formKeys = Object.keys(formGroup.value);
    return data.some((obj) => {
      const que: any = formGroup.controls[obj.id];
      return formKeys.includes(obj.id) && !que['skipQuestion'];
    });
  }

  ngAfterContentInit(): void {
    this.contentLoaded = true;
  }

  getSolutionName() {
    return this.answerData['solutionName'];
  }

  skipQuestion(formGroup: FormGroup, questionId: string, questionType: string, questionOptions: any): boolean {
    if (questionType === 'radio' && questionOptions.filter((opt: any) => opt.inputRequired).length) {
      questionId = 'other-' + questionId;
    }

    const que: any = formGroup.controls[questionId];
    return que?.['skipQuestion'];
  }

  availableNotes(id: string) {
    if (!this.isFormSubmitted) {
      return true;
    }
    if (!this.noteGroup.value[id]) {
      return false;
    }
    return true;
  }
}
