<div class="prelogin_rightside">
  <img routerLink="/welcome" class="logo cursor-pointer img-static" src="assets/images/logo_black.svg" alt="logo_black" />
  <div class="prelogin_form_container">
    <h1 class="center-header">{{ 'twoFactorAuth.title' | translate }}</h1>
    <p class="center-header">{{ 'twoFactorAuth.sub_text' | translate : { email } }}</p>
    <div *ngIf="errorMessage" class="alert alert-danger cst-alert d-flex" role="alert"><em class="pe-2 bi bi-exclamation-triangle"></em> {{ errorMessage | translate }}</div>
    <div class="form-group position-relative"></div>
    <form class="cst_form-container mt-4" [formGroup]="twoFactorAuthForm" (ngSubmit)="onSubmit()">
      <div class="mb-4 cst_form">
        <label for="code" class="form-label">{{ 'twoFactorAuth.label' | translate }}<span>*</span></label>
        <input
          type="text"
          class="form-control"
          id="code"
          formControlName="code"
          (input)="errorMessage = ''"
          [class.invalid]="(twoFactorAuthForm.get('code')?.invalid && (twoFactorAuthForm.get('code')?.dirty || twoFactorAuthForm.get('code')?.touched)) || errorMessage"
        />
        <div *ngIf="twoFactorAuthForm.controls['code']?.invalid && twoFactorAuthForm.controls['code']?.touched">
          <div *ngIf="twoFactorAuthForm.controls['code'].errors?.['required']">
            <span class="invalid_feedback"> {{ 'alert.code_required' | translate }}</span>
          </div>
        </div>
      </div>
      <!-- Works below for displacement -->

      <div class="text-center mt-5">
        <button
          [disabled]="twoFactorAuthForm.invalid || errorMessage || !twoFactorAuthForm.get('code')?.value"
          class="btn prelogin_btn"
          [ngClass]="{
            disabled: twoFactorAuthForm.invalid || errorMessage
          }"
        >
          {{ 'shared.submit' | translate | uppercase }}
        </button>
      </div>

      <div class="mt-4 text-center">
        <p>
          {{ 'twoFactorAuth.code_not_received_text' | translate }}
          <span class="back_link forgot-password-cursor" (click)="resendCode()" [class.resendCodeTextdisabled]="!resendCodeActionAllowed">{{
            'twoFactorAuth.resend_code_text' | translate
          }}</span>
        </p>
      </div>
    </form>
  </div>
</div>
