<div class="modal-dialog modal-fullscreen">
  <div class="modal-content summary-view-modal">
    <div class="modal-header">
      <h5 class="modal-title" id="exampleModalLabel">{{ 'reviewer.summary_modal.summary_view' | translate }}</h5>
      <button (click)="onClose()" type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
    </div>
    <div class="modal-body">
      <app-summary-sidebar [questions]="questionData" [previousQuestions]="previousQuestionData" (navigateTo)="navigateToId($event)"></app-summary-sidebar>
      <div class="summary-view-table">
        <div class="table-responsive">
          <table class="table cst_table table-striped" aria-describedby="summary view">
            <thead>
              <tr>
                <th scope="col">{{ 'reviewer.summary_modal.id' | translate }}</th>
                <th scope="col">{{ 'reviewer.summary_modal.question' | translate }}</th>
                <th scope="col">{{ 'reviewer.summary_modal.answer' | translate }}</th>
                <th scope="col">{{ 'reviewer.summary_modal.reviewed_status' | translate }}</th>
                <th scope="col">{{ 'reviewer.summary_modal.status' | translate }}</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let question of questionList">
                <td [id]="'sview' + question.cat">{{ question.que.id }}</td>
                <td class="que-ans">{{ question.que.label }}</td>
                <td class="que-ans">{{ question.que.answer }}</td>
                <td>
                  <span class="green-tick-box" *ngIf="checkReviewStatus(question.que.id)"><em class="bi bi-check2"></em></span>

                  <span class="red-box" *ngIf="!checkReviewStatus(question.que.id)"><em class="bi bi-x"></em></span>
                </td>
                <td [ngClass]="checkReviewStatus(question.que.id)">{{ checkReviewStatus(question.que.id) || '-' | titlecase }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
