import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UserGuard } from '../security/guard/auth.guard';
import { UserChangePasswordComponent } from '../security/user-change-password/user-change-password.component';
import { UserProfileComponent } from '../security/user-profile/user-profile.component';
import { BaseComponent } from './base.component';

const routes: Routes = [
  {
    path: '',
    component: BaseComponent,
    children: [
      {
        path: 'profile',
        component: UserProfileComponent,
        canActivate: [UserGuard]
      },
      {
        path: 'change-password',
        component: UserChangePasswordComponent,
        canActivate: [UserGuard]
      }
    ]
  },
  {
    path: '**',
    redirectTo: '/user/profile',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class BaseRoutingModule {}
