import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent {
  @Input() currentPage: any;
  @Output() pageChange = new EventEmitter();
  // @Output() onLimitChange = new EventEmitter();
  arrayPages: number[] = [];
  pagesToShow = 9;
  limit: any;
  @Input() limitSelected: number = 0;
  set setLimit(limit: any) {
    this.limit = limit;
  }

  /**
   * sets the total pages variable and set page array according to total pages
   */
  @Input() totalPages: number = 0;
  set updateTotalPages(totalPages: any) {
    this.totalPages = totalPages;
    this.setArrayPages();
  }

  /**
   * sets the array to display page numbers
   */
  setArrayPages() {
    this.arrayPages = [];
    for (let i = 1; i <= this.totalPages; i++) {
      this.arrayPages.push(i);
    }
    if (this.totalPages < 10) this.pagesToShow = this.totalPages;
  }

  onPrev() {
    if (this.currentPage > 1) {
      this.currentPage -= 1;
      this.pageChange.emit(this.currentPage);
    }
  }

  /**
   * Handles on click event of next
   */
  onNext() {
    if (this.currentPage < this.totalPages) {
      this.currentPage += 1;
      this.pageChange.emit(this.currentPage);
    }
  }

  pageChanged(page: any) {
    this.pageChange.emit(page);
    this.currentPage = page;
  }

  setToGivenPage(page: any) {
    this.pageChange.emit(page);
    this.currentPage = page;
  }
}
