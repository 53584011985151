<div class="main-content">
  <app-breadcrumb
    [title]="'breadcrumb.assign_reviewer' | translate | titlecase"
    [items]="[
      { title: 'solution', link: '/admin/solution/assignment-request' },
      { title: 'assign_reviewer', link: '/users' }
    ]"
  >
  </app-breadcrumb>

  <div class="table_container">
    <div class="d-md-flex mb-md-0 justify-content-between align-items-center">
      <div>
        <form class="table_search mt-2">
          <input
            name="search-pending-assignments"
            class="form-control search_input me-2"
            id="search-pending-assignments"
            placeholder="Search"
            type="search"
            [(ngModel)]="paginationOptions.searchTerm"
            (ngModelChange)="onSearchChange($event)"
          />
        </form>
      </div>
    </div>
    <div class="cst-dch-tabs">
      <ul class="nav nav-tabs" id="myTab" role="tablist">
        <li class="nav-item" role="presentation">
          <button
            class="nav-link"
            [ngClass]="{
              active: requestedDetails.status === 'ongoing'
            }"
            (click)="changeTab('ongoing')"
          >
            {{ 'assignment_request.ongoing' | translate }}
          </button>
        </li>
        <li class="nav-item" role="presentation">
          <button
            class="nav-link"
            [ngClass]="{
              active: requestedDetails.status === 'pending'
            }"
            (click)="changeTab('pending')"
          >
            {{ 'assignment_request.pending' | translate }}
          </button>
        </li>
        <li class="nav-item" role="presentation">
          <button
            class="nav-link"
            [ngClass]="{
              active: requestedDetails.status === 'accepted'
            }"
            (click)="changeTab('accepted')"
          >
            {{ 'assignment_request.accepted' | translate }}
          </button>
        </li>
        <li class="nav-item" role="presentation">
          <button
            class="nav-link"
            [ngClass]="{
              active: requestedDetails.status === 'rejected'
            }"
            (click)="changeTab('rejected')"
          >
            {{ 'assignment_request.rejected' | translate }}
          </button>
        </li>
      </ul>
      <div class="tab-content" id="myTabContent">
        <div class="table-responsive">
          <table class="table cst_table table-striped" aria-describedby="assignment request">
            <thead>
              <tr>
                <th scope="col">
                  <p>
                    {{ 'reviewer.review_list.call_name' | translate
                    }}<span
                      ><app-sorting [sortByColumn]="sortByColumn" [sortDirection]="sortDirection" (sortChanged)="onSortChanged($event)" columnName="callName"></app-sorting>
                    </span>
                  </p>
                </th>
                <th scope="col">
                  <p>
                    {{ 'reviewer.review_list.solution_name' | translate
                    }}<span
                      ><app-sorting [sortByColumn]="sortByColumn" [sortDirection]="sortDirection" (sortChanged)="onSortChanged($event)" columnName="solutionName"></app-sorting>
                    </span>
                  </p>
                </th>
                <th scope="col">
                  <p>
                    {{ 'reviewer.review_list.stage_order' | translate
                    }}<span
                      ><app-sorting [sortByColumn]="sortByColumn" [sortDirection]="sortDirection" (sortChanged)="onSortChanged($event)" columnName="stageOrder"></app-sorting>
                    </span>
                  </p>
                </th>
                <th scope="col">
                  <p>
                    {{ 'reviewer.review_list.company_name' | translate
                    }}<span
                      ><app-sorting [sortByColumn]="sortByColumn" [sortDirection]="sortDirection" (sortChanged)="onSortChanged($event)" columnName="companyName"></app-sorting>
                    </span>
                  </p>
                </th>
                <th scope="col">
                  <p>
                    {{ 'reviewer.review_list.submitted_by' | translate
                    }}<span
                      ><app-sorting [sortByColumn]="sortByColumn" [sortDirection]="sortDirection" (sortChanged)="onSortChanged($event)" columnName="email"></app-sorting>
                    </span>
                  </p>
                </th>
                <th scope="col">
                  <p>
                    {{ 'reviewer.review_list.submitted_on' | translate
                    }}<span
                      ><app-sorting [sortByColumn]="sortByColumn" [sortDirection]="sortDirection" (sortChanged)="onSortChanged($event)" columnName="submittedOn"></app-sorting>
                    </span>
                  </p>
                </th>
                <th scope="col" *ngIf="requestedDetails.status !== 'ongoing'">
                  {{ 'assignment_request.reviewers_emailid' | translate }}
                </th>
                <th scope="col" *ngIf="requestedDetails.status === 'rejected'">{{ 'assignment_request.reason' | translate }}</th>
                <th scope="col" *ngIf="['ongoing', 'rejected'].includes(requestedDetails.status)">{{ 'reviewer.review_list.action' | translate }}</th>
              </tr>
            </thead>
            <tbody *ngIf="!noDataFound">
              <tr
                *ngFor="
                  let request of requestedDetails.data | paginate : { itemsPerPage: paginationOptions.pageSize, currentPage: paginationOptions.page, totalItems: totalRequests };
                  let i = index
                "
              >
                <td>{{ request.callName }}</td>
                <td>{{ request.solutionName }}</td>
                <td>{{ request.stageOrder }}</td>
                <td>{{ request.companyName }}</td>
                <td>{{ request.vendorName?.trim() ? request.vendorName : request.email }}</td>

                <td>
                  <div class="date-badge">{{ request.submittedOn | date : 'dd/MM/yy' }}</div>
                </td>
                <td *ngIf="['pending', 'accepted', 'rejected'].includes(requestedDetails.status)">
                  {{ request.reviewerEmail }}
                </td>
                <td *ngIf="requestedDetails.status === 'rejected'">{{ request.rejectReason }}</td>
                <td *ngIf="['ongoing', 'rejected'].includes(requestedDetails.status)">
                  <button type="button" class="btn outline_btn me-2 assign-reviewer" (click)="getAllReviewers(request)" data-bs-toggle="modal" data-bs-target="#modal2">
                    <em class="pe-2 bi bi-plus-lg"></em
                    >{{ (requestedDetails.status === 'ongoing' ? 'assignment_request.assign_reviewer' : 'assignment_request.reassign') | translate }}
                  </button>
                </td>
              </tr>
            </tbody>
            <tbody *ngIf="noDataFound">
              <tr class="no-row-available">
                <td colspan="10" rowspan="2">{{ 'alert.no_records_found' | translate }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <app-pagination
          *ngIf="requestedDetails.data?.length"
          [currentPage]="paginationOptions.page"
          [totalPages]="totalPages"
          (pageChange)="pageChange($event)"
          [limitSelected]="paginationOptions.pageSize"
        >
        </app-pagination>
      </div>
    </div>
  </div>
  <div class="modal fade successfully-sent-modal" id="exampleModal" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-body text-center">
          <div class="container">
            <div class="col">
              <div class="row p-3">
                <div class="text-center"><img src="assets/images/success-checked 1.jpg" alt="success_checked" /></div>
              </div>
              <div class="row pb-3">
                <p class="text-center sent-text">{{ 'assignment_request.invitation_sent_message' | translate }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal fade add-reviewer-modal" id="modal2" tabindex="-1" aria-labelledby="modal2" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header border-none">
          <h5 class="modal-title" id="modal2">{{ 'assignment_request.assign_reviewer' | translate }}</h5>
          <p *ngIf="!activeSolution.minimumReviewerRequired" class="one-reviewer">({{ 'assignment_request.reviewer_selection_err_mssg' | translate }})</p>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body border-none">
          <input
            type="text"
            class="form-control input-custom mb-3"
            placeholder="Enter email to find"
            style="position: sticky; top: 0; z-index: 1"
            aria-label="Username"
            [(ngModel)]="reviewerSearched"
            (ngModelChange)="searchReviewer(reviewerSearched)"
          />
          <div class="list-group-container">
            <ul class="list-group">
              <li
                class="list-group-item border-none"
                *ngFor="let reviewer of reviewers"
                (click)="selectReviewers(reviewer.id)"
                [ngClass]="{
                  'selected-reviewer': selectedReviewers.includes(reviewer.id)
                }"
              >
                <div class="d-flex align-items-center justify-content-between">
                  <p class="mb-0">{{ reviewer.email }}</p>
                  <span><em *ngIf="selectedReviewers.includes(reviewer.id)" class="assign-check bi bi-check2"></em></span>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="modal-footer justify-content-center border-none">
          <button
            type="button"
            class="btn cst_btn"
            data-bs-toggle="modal"
            data-bs-target="#exampleModal"
            [disabled]="isInvited()"
            [class.disabled]="isInvited()"
            (click)="saveAssignmentRequest()"
          >
            {{ 'assignment_request.invite' | translate | uppercase }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
