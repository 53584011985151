import { Component, ChangeDetectorRef, OnInit } from '@angular/core';
import { SimpleModalComponent } from 'ngx-simple-modal';
import { URLS } from 'src/app/shared/urls';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

// Interface to define the content of the timeout modal
export interface TimeoutModalContent {
  header: string;
  message: string;
  currentUser: string;
}
@Component({
  selector: 'app-timeout-modal',
  templateUrl: './timeout-modal.component.html',
  styleUrls: ['./timeout-modal.component.scss']
})
export class TimeoutModalComponent extends SimpleModalComponent<TimeoutModalContent, string> implements OnInit, TimeoutModalContent {
  remainingTime!: number; // Remaining time in milliseconds
  header!: string; // Header text for the modal
  message!: string; // Main message to display in the modal
  currentUser!: string; //Logged in user details

  private countdownTimer: any; // Reference to the countdown timer
  FULL_DASH_ARRAY = 283; // Full dash array for the circular timer
  WARNING_THRESHOLD = 30; // Threshold in seconds to switch to the warning color
  ALERT_THRESHOLD = 10; // Threshold in seconds to switch to the alert color
  COLOR_CODES = {
    info: {
      color: 'green' // Color for the timer when time is not critical
    },
    warning: {
      color: 'orange', // Color for the timer when time is approaching the warning threshold
      threshold: this.WARNING_THRESHOLD
    },
    alert: {
      color: 'red', // Color for the timer when time is approaching the alert threshold
      threshold: this.ALERT_THRESHOLD
    }
  };
  remainingPathColor = this.COLOR_CODES.info.color; // Initial color of the timer path

  constructor(private router: Router, private readonly cdr: ChangeDetectorRef) {
    super();
  }

  ngOnInit(): void {
    this.remainingTime = environment.countdown_timeout; //initial remaining time in milliseconds
    this.startCountdown(); //start the  countdown timer
  }

  // Function to handle cancel button click
  cancel() {
    this.stopCountdown(); // Stop the countdown timer
    this.result = 'cancel';
    this.close(); // Close the modal
    //navigate to the Dashboard
    let dashboardUrl: string;
    switch (this.currentUser) {
      case 'vendor':
        dashboardUrl = URLS.vendor.dashboard;
        break;
      case 'reviewer':
        dashboardUrl = URLS.reviewer.dashboard;
        break;
      case 'whopanel':
        dashboardUrl = URLS.whopanel.dashboard;
        break;
      default:
        dashboardUrl = URLS.login;
        break;
    }

    // Navigate to the determined dashboard URL

    this.router.navigate([dashboardUrl]);
  }

  // Function to handle continue button click
  continueToForm() {
    this.result = 'continue';
    this.close(); // Close the modal
    this.stopCountdown(); // Stop the countdown timer
  }
  private startCountdown() {
    const interval = 1000; // Update countdown every 1 second (1000 milliseconds)

    this.countdownTimer = setInterval(() => {
      this.remainingTime -= interval; // Decrement the remaining time
      if (this.remainingTime <= 0) {
        this.cancel(); // If the time is up, cancel the modal
      } else {
        // Update the countdown timer in the modal
        this.setCircleDasharray(); // Update the circle dasharray based on the remaining time
        this.setRemainingPathColor(this.remainingTime / 1000); // Set the color of the timer path
      }
    }, interval);
  }

  // Function to stop the countdown timer
  private stopCountdown() {
    clearInterval(this.countdownTimer); // Clear the countdown timer interval
  }

  // Function to format the remaining time as 'minutes:seconds'
  formatTime(time: any) {
    const minutes = Math.floor(time % 1000);
    let seconds = time / 1000;

    if (seconds < 10) {
      seconds = Number(`0${seconds}`);
    }

    return `${minutes}:${seconds}`;
  }

  // Function to set the color of the timer path based on the remaining time
  setRemainingPathColor(timeLeft: any) {
    const { alert, warning, info } = this.COLOR_CODES;
    if (timeLeft <= alert.threshold) {
      document.getElementById('base-timer-path-remaining')?.classList.remove(warning.color);
      document.getElementById('base-timer-path-remaining')?.classList.add(alert.color);
    } else if (timeLeft <= warning.threshold) {
      document.getElementById('base-timer-path-remaining')?.classList.remove(info.color);
      document.getElementById('base-timer-path-remaining')?.classList.add(warning.color);
    }
  }

  // Function to calculate the time fraction for the circular timer
  calculateTimeFraction() {
    const rawTimeFraction = this.remainingTime / 1000 / 60;

    return rawTimeFraction - (1 / 60) * (1 - rawTimeFraction);
  }

  // Function to set the circle dasharray based on the remaining time
  setCircleDasharray() {
    const circleDasharray = `${(this.calculateTimeFraction() * this.FULL_DASH_ARRAY).toFixed(0)} 283`;
    document.getElementById('base-timer-path-remaining')?.setAttribute('stroke-dasharray', circleDasharray);
  }
}
