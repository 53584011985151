import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription, lastValueFrom } from 'rxjs';
import { ReviewerService } from 'src/app/reviewer/reviewer.service';
import { SecuritySubscriberService } from 'src/app/security/security-subscriber.service';
import { SharedService } from 'src/app/shared/services/shared.service';
import { VendorService } from 'src/app/vendor/vendor.service';
import { WhoPanelService } from 'src/app/who-panel/who-panel.service';

@Component({
  selector: 'app-call-list',
  templateUrl: './call-list.component.html',
  styleUrls: ['./call-list.component.scss']
})
export class CallListComponent implements OnInit {
  isSidebarToggle: any;
  solutionId = '';
  isFormActiveSubscription: Subscription | undefined;
  stageId: string = '';
  previousStageId: string = '';
  activeCalls: any = [];
  previousCalls: any = [];
  currentUser: any;
  constructor(
    private readonly sharedService: SharedService,
    private readonly router: Router,
    private readonly vendorService: VendorService,
    private readonly reviewerService: ReviewerService,
    private readonly whopanelService: WhoPanelService,
    private readonly securitySubscriberService: SecuritySubscriberService
  ) {}

  ngOnInit() {
    this.securitySubscriberService.getCurrentUser.subscribe((currentUser) => {
      (async () => {
        this.currentUser = currentUser;

        switch (currentUser?.role) {
          case 'vendor':
            this.vendorService.setToggleValue(false);
            await this.getCallDetails('Active');
            break;
          case 'reviewer':
            this.reviewerService.setToggleValue(false);
            await this.getCallDetails('Active');
            break;
          case 'whopanel':
            this.whopanelService.setToggleValue(false);
            await this.getCallDetails('Active');
            break;
          default:
            break;
        }
      })();
    });
  }

  navigateTo(callId: string, solution: any, previousStageId: string, status: string) {
    switch (this.currentUser.role) {
      case 'vendor':
        this.vendorNavigateTo(callId, solution, previousStageId, status);
        break;
      case 'reviewer':
        this.reiewerNavigateTo(callId);
        break;
      case 'whopanel':
        this.whoPanelNavigateTo(callId);
        break;
      default:
        break;
    }
  }

  vendorNavigateTo(callId: string, solution: any, previousStageId: string, status: string) {
    if (solution.id) {
      this.stageId = solution.stageId;
      this.solutionId = solution.id;
      if (previousStageId) {
        if (status.includes('Approved')) {
          this.router.navigate([`vendor/solution/${this.solutionId}/${this.stageId}/${previousStageId}`]);
          return;
        }
        this.router.navigate([`vendor/previous-solution/${this.solutionId}/${previousStageId}`]);
        return;
      }
      this.router.navigate([`vendor/solution/${this.solutionId}/${this.stageId}`]);
      return;
    }
    this.router.navigate(['vendor/solution', callId, 'add']);
  }

  reiewerNavigateTo(callId: string) {
    this.router.navigate(['reviewer/', callId, 'review-list'], {
      state: {
        callId
      }
    });
  }

  whoPanelNavigateTo(callId: string) {
    this.router.navigate(['who-panel/', callId, 'review-list'], {
      state: {
        callId
      }
    });
  }

  async getCallDetails(callDetails: any) {
    try {
      let calls;
      switch (this.currentUser.role) {
        case 'vendor':
          calls = await lastValueFrom(this.sharedService.getCalls({ callType: callDetails }));
          break;
        case 'reviewer':
        case 'whopanel':
          calls = await lastValueFrom(this.sharedService.getCallsWithPendingReviews({ callType: callDetails }));
          break;
        default:
          break;
      }

      if (callDetails === 'Active') {
        this.activeCalls = calls.publicCalls ?? [];
      } else {
        this.previousCalls = calls.publicCalls ?? [];
      }
    } catch (error) {
      this.sharedService.showDynamicAlert('Something went wrong');
    }
  }
}
