import { Component, HostListener, ChangeDetectorRef, Injector, OnDestroy, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { SimpleModalService } from 'ngx-simple-modal';
import { TimeoutModalComponent, TimeoutModalContent } from './timeout-modal/timeout-modal.component';
import { SharedService } from 'src/app/shared/services/shared.service';
import { VendorService } from '../../../vendor/vendor.service';
import { ReviewerService } from './../../../reviewer/reviewer.service';
import { WhoPanelService } from 'src/app/who-panel/who-panel.service';
import { FormService } from 'src/app/vendor/form/form.service';
import { ActivatedRoute, Router } from '@angular/router';
import { lastValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import { SecurityService } from 'src/app/security/security.service';
import { SecuritySubscriberService } from 'src/app/security/security-subscriber.service';
import { URLS } from 'src/app/shared/urls';

const IDLE_TIMEOUT = environment.idle_timeout; // 9 minutes in milliseconds

@Component({
  selector: 'app-timeout',
  template: ''
})
export class TimeoutComponent implements OnDestroy, OnInit {
  private idleTimer: any;
  private isModalOpen = false;
  private modalService: SimpleModalService;
  private router: Router;
  private readonly cdr: ChangeDetectorRef;
  private formService: FormService;
  private vendorService: VendorService;
  private sharedService: SharedService;
  private readonly activeRoute: ActivatedRoute;
  private reviewerService: ReviewerService;
  private whopanelService: WhoPanelService;
  private securityService: SecurityService;
  private securitySubscriberService: SecuritySubscriberService;

  solutionId: any = '';
  @Output() closeEditorInterval = new EventEmitter();
  @Input() currentUser: string = '';
  @Input() questionReviewStatus: string = '';
  @Input() status: string = '';
  @Input() comment: string = '';
  @Input() eSignature: string = '';
  constructor(private injector: Injector) {
    this.modalService = this.injector.get<SimpleModalService>(SimpleModalService);
    this.router = this.injector.get<Router>(Router);
    this.cdr = this.injector.get<ChangeDetectorRef>(ChangeDetectorRef);
    this.formService = this.injector.get<FormService>(FormService);
    this.vendorService = this.injector.get<VendorService>(VendorService);
    this.sharedService = this.injector.get<SharedService>(SharedService);
    this.activeRoute = this.injector.get<ActivatedRoute>(ActivatedRoute);
    this.reviewerService = this.injector.get<ReviewerService>(ReviewerService);
    this.whopanelService = this.injector.get<WhoPanelService>(WhoPanelService);
    this.securityService = this.injector.get<SecurityService>(SecurityService);
    this.securitySubscriberService = this.injector.get<SecuritySubscriberService>(SecuritySubscriberService);
  }

  ngOnInit(): void {
    this.solutionId = this.activeRoute.snapshot.params['solutionid'];
    this.startIdleTimer();
  }

  // Function to start the idle timer for user inactivity
  private startIdleTimer() {
    this.idleTimer = setTimeout(() => {
      this.showTimeoutModal(); // Show the timeout modal when the idle time is reached
    }, IDLE_TIMEOUT);
  }

  // Function to reset the idle timer on user interaction
  private resetIdleTimer() {
    clearTimeout(this.idleTimer);
    this.startIdleTimer();
  }

  // Function to show the timeout modal
  public showTimeoutModal() {
    this.modalService.removeAll(); // Remove any existing modals
    this.isModalOpen = true; // Set the modal open flag to true
    const modalContent: TimeoutModalContent = {
      header: 'You have been idle!',
      message: `You will get timed out in 1 minute`,
      currentUser: this.currentUser
    };
    this.modalService.addModal(TimeoutModalComponent, modalContent).subscribe((result) => {
      this.isModalOpen = false; // Set the modal open flag to false when the modal is closed
      (async () => {
        if (result === 'cancel') {
          clearTimeout(this.idleTimer); // Cancel the idle timer
          if (!this.currentUser) {
            await lastValueFrom(this.securityService.logoutUser());
            this.securitySubscriberService.isLoggedIn.next(false);
            this.securitySubscriberService.clearCurrentUserDetail();
            this.router.navigate([URLS.login]);
          } else {
            try {
              const successMessage = 'Draft Saved!';

              // Save the form response as a draft
              switch (this.currentUser) {
                case 'vendor':
                  await lastValueFrom(this.formService.saveResponse(this.solutionId, this.formService.intakeForm.value, true, false));

                  await lastValueFrom(this.vendorService.deleteFormEditorList(this.solutionId));
                  break;
                case 'reviewer':
                  await lastValueFrom(
                    this.reviewerService.saveAssessmentResponse({
                      solutionId: this.solutionId,
                      questionReviewStatus: this.questionReviewStatus,
                      isSubmitted: false,
                      status: this.status,
                      comment: this.comment,
                      eSignature: this.eSignature
                    })
                  );
                  break;
                case 'whopanel':
                  await lastValueFrom(
                    this.whopanelService.saveAssessmentResponse({
                      solutionId: this.solutionId,
                      questionReviewStatus: this.questionReviewStatus,
                      isSubmitted: false,
                      status: this.status,
                      comment: this.comment,
                      eSignature: this.eSignature
                    })
                  );
                  break;
                default:
                  break;
              }
              this.sharedService.showDynamicAlert(successMessage, 'success'); // Show a success alert
            } catch (err) {}
          }
        } else {
          this.resetIdleTimer(); // Reset the idle timer on user interaction with the modal
        }
      })();
    });
  }

  // Host listener for user interactions (mousemove and keydown events)
  @HostListener('window:mousemove')
  @HostListener('window:keydown')
  public onUserInteraction() {
    if (!this.isModalOpen) this.resetIdleTimer(); // Reset the idle timer if the modal is not open
  }

  // Function executed when the component is destroyed
  ngOnDestroy() {
    clearTimeout(this.idleTimer); // Clear the idle timer
    this.closeEditorInterval.emit(); // Emit the event to close the editorInterval
    // this.modalService.removeAll(); // Remove any existing modals (This line may not be necessary)
  }
}
