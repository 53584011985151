import { Component, Injector, OnInit } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { SharedService } from 'src/app/shared/services/shared.service';
import { ActivatedRoute, Router } from '@angular/router';
import * as d3 from 'd3';
import { URLS } from 'src/app/shared/urls';
import { Location } from '@angular/common';

@Component({
  selector: 'app-search-solution',
  templateUrl: './search-solution.component.html',
  styleUrls: ['./search-solution.component.scss']
})
export class SearchSolutionComponent implements OnInit {
  solutionId!: string;
  solutionName!: string;
  solutionDetails!: string;
  solutionScores: any = {
    flags: {
      isLmicValue: 1,
      isAssessmentFrameworkValue: 1,
      isCategoryValue: 1
    }
  };
  whopanelName: string = '';
  isEditable: boolean = false;
  isSubmittable: boolean = false;
  assessmentResult: any = [];
  solutionOverview: any = [];
  status!: string;
  version: string = '';
  websiteLink: string = '';
  browsers: string[] = [];
  operatingSystems: string = '';
  decisions: any = {
    FM: { label: 'Fully meets the requirements', bgcolor: '#BADBCC', stroke: '#95D4B8' },
    FMPM: { label: 'Fully meets all mandatory requirements and partially meets non-mandatory requirements', bgcolor: '#BADBCC', stroke: '' },
    PM: { label: 'Partially meets the requirements', bgcolor: '#FFE69C', stroke: '' },
    DM: { label: 'Does not meet the requirements', bgcolor: '#F5C2C7', stroke: '' }
  };
  attachmentUrl!: string;
  private sharedService: SharedService;
  private route: ActivatedRoute;
  private router: Router;
  private location: Location;

  constructor(private injector: Injector) {
    this.sharedService = this.injector.get<SharedService>(SharedService);
    this.route = this.injector.get<ActivatedRoute>(ActivatedRoute);
    this.router = this.injector.get<Router>(Router);
    this.solutionId = this.route.snapshot.params['solutionId'];
    this.location = this.injector.get<Location>(Location);
  }
  async ngOnInit() {
    this.isEditable = this.router.url.includes('publish-solution') || this.router.url.includes('curated-solution');
    this.isSubmittable = !this.router.url.includes('curated-solution');
    await this.loadSolutionData();
    if (this.solutionScores.flags.isCategoryValue) {
      this.createBarChart();
    }
    window.scrollTo({ top: 0 });
  }

  downloadResponse() {
    window.open(this.attachmentUrl, '_blank');
  }

  async loadSolutionData() {
    const scores = await lastValueFrom(this.sharedService.getScores(this.solutionId));

    this.solutionScores = scores.data;
    this.solutionName = scores.data.name;
    this.solutionDetails = scores.data.solutionDetails;
    this.whopanelName = scores.data.whopanelName;
    this.assessmentResult = scores.data.assessmentResult;
    this.assessmentResult = this.assessmentResult?.filter((com: any) => com.decision);
    if (this.assessmentResult?.[0]) this.assessmentResult[0]['show'] = true;
    this.solutionOverview = scores.data.solutionOverview;
    if (this.solutionOverview?.[0]) this.solutionOverview[0]['show'] = true;
    this.attachmentUrl = scores.data.attachmentUrl;
    this.status = scores.data.status;
    this.browsers = scores.data.browsers;
    this.version = scores.data.version;
    this.websiteLink = scores.data.websiteLink;
    this.operatingSystems = scores.data.operatingSystems;
  }

  goBack(): void {
    this.location.back();
  }

  updateEditorContent(newContent: string, type = 'assessment') {
    if (type === 'assessment') {
      this.assessmentResult = newContent;
      return;
    }
    this.solutionOverview = newContent;
  }

  changeOverviewResponse(id: number) {
    this.solutionOverview = this.solutionOverview?.map((res: any) => (res.id === id ? { ...res, show: true } : { ...res, show: false }));
  }

  changeAssessmentResponse(id: number) {
    this.assessmentResult = this.assessmentResult?.map((res: any) => (res.id === id ? { ...res, show: true } : { ...res, show: false }));
  }

  async addToCuratedSolution() {
    try {
      await lastValueFrom(this.sharedService.savePublishSolution(this.solutionId, this.assessmentResult, this.solutionOverview));
    } catch (error) {
      this.sharedService.showDynamicAlert();
    }

    this.router.navigate([URLS.admin.publishSolutionRequest]);
  }

  createBarChart() {
    // Your data (make sure each object includes a color property)
    const data: { name: string; score: number; description: string; color: string }[] = this.solutionScores.score?.reverse();

    data?.forEach((d) => {
      switch (d.name) {
        case 'Product Overview':
          d.color = 'rgba(41, 156, 219, 0.70)';
          break;
        case 'Health Content':
          d.color = 'rgba(64, 81, 137, 0.70)';
          break;
        case 'Sustainability':
          d.color = 'rgba(10, 179, 156, 0.70)';
          break;
        case 'Ease of Use':
          d.color = 'rgba(247, 184, 75, 0.70)';
          break;
        case 'Privacy and Security':
          d.color = 'rgba(240, 101, 72, 0.70)';
          break;
        case 'Tech Considerations':
          d.color = 'rgba(27.25, 172.42, 181.69, 0.70)';
          break;
        case 'Deployment Considerations':
          d.color = 'rgba(219.94, 106.74, 43.07, 0.70)';
          break;
        case 'Ethical considerations':
          d.color = 'rgba(247, 184, 100, 0.70)';
          break;
        default:
          d.color = 'rgba(41, 156, 219, 0.70)';
          break;
      }
    });
    // Set up the SVG canvas dimensions
    const margin = { top: 20, right: 200, bottom: 50, left: 200 };
    const width = 1000 - margin.left - margin.right;
    const height = 300 - margin.top - margin.bottom;
    // Custom x-axis tick values
    const xTicks = [-100, -75, -50, -25, 0, 25, 50, 75, 100];

    // Create an SVG element
    const svg = d3.select('.score-chart').append('svg').attr('viewBox', `0 0 1050 300`).append('g').attr('transform', `translate(${margin.left},${margin.top})`);

    // Define the x and y scales
    const x = d3.scaleLinear().range([0, 500]);
    const y = d3.scaleBand().range([height, 0]).padding(0.1);

    // Map the data to the x and y domains
    x.domain([-100, 100]);
    y.domain(data.map((d) => d.name));

    // Calculate the width of the faded bar
    const fadedBarWidth = x(100);

    // Append a faded bar for data points with no score
    svg
      .selectAll('.faded-bar')
      .data(data)
      .enter()
      .append('rect')
      .attr('class', 'faded-bar')
      .attr('y', (d) => y(d.name) ?? 0 + 2)
      .attr('height', 6)
      .attr('x', 0)
      .attr('width', fadedBarWidth)
      .attr('fill', '#DBDBDD')
      .attr('transform', 'translate(0, 9)');

    //custom tooltip
    const tooltip = d3.select('.score-chart').append('div').attr('class', 'tooltip');

    // Create bars with different colors
    svg
      .selectAll('.bar')
      .data(data)
      .enter()
      .append('rect')
      .attr('class', 'bar')
      .attr('y', (d) => y(d.name) ?? 0 + 5)
      .attr('height', 6)
      .attr('x', (d) => (d.score >= 0 ? x(0) : x(d.score)))
      .attr('width', (d) => Math.abs(x(d.score) - x(0)))
      .attr('fill', (d) => d.color)
      .attr('transform', 'translate(0, 9)')
      .on('mouseover', function (event, d) {
        tooltip
          .html(
            '<p style="line-height:14px; margin:5px;">' +
              d.name +
              '</p>' +
              '<hr class="m-0">' +
              '<p style="margin:5px;">' +
              `<i class="bi bi-circle-fill" style="color: ${d.color};border-radius: 9999px;font-size: 8px; margin-right:5px; line-height:12px;"></i>` +
              'Percentage: ' +
              d.score.toFixed(2) +
              '%' +
              '</p>'
          )
          .style('position', 'absolute')
          .style('left', event.pageX - 10 + 'px')
          .style('top', event.pageY - 60 + 'px')
          .style('background-color', 'black')
          .style('color', 'white')
          .style('font-size', '11px')
          .style('border-radius', '4px')
          .style('border', '1px solid rgb(2, 2, 2)')
          .style('opacity', '0.9')
          .style('min-width', '130px')
          .style('display', 'block');
      })
      .on('mouseout', function () {
        tooltip.style('display', 'none');
      });

    // Create x-axis label
    svg
      .append('text')
      .text('Percentage of scoring points')
      .attr('x', width / 2)
      .attr('y', height + 40)
      .attr('text-anchor', 'middle')
      .attr('font-family', 'Source Sans Pro')
      .attr('font-size', '12px')
      .attr('fill', 'black');

    // Create custom x-axis with specified tick values
    svg.append('g').attr('transform', `translate(0,${height})`).call(d3.axisBottom(x).tickSize(0).tickValues(xTicks));

    // Create y-axis with specified tick labels
    const yAxis = svg.append('g').attr('font-weight', '600').call(d3.axisLeft(y).tickSize(0).tickPadding(15));
    yAxis.selectAll('text').attr('font-family', 'Source Sans Pro').attr('font-size', '11px').attr('font-weight', '600').attr('fill', '#3E506A').attr('line-height', '18px');

    // Add details beside the bars
    svg
      .selectAll('.detail')
      .data(data)
      .enter()
      .append('text')
      .text((d) => d.description || '')
      .attr('x', 515)
      .attr('y', (d) => y(d.name) ?? 0)

      .attr('text-anchor', 'start')
      .attr('font-family', 'Source Sans Pro')
      .attr('font-size', '11px')
      .attr('font-weight', '400')
      .attr('fill', '#3E506A')
      .attr('width', '100%')
      .attr('transform', 'translate(0, 15)')
      .each(function (d) {
        const el = d3.select(this);
        const description = d.description;
        el.text('');

        const containerWidth = document.querySelector('.score-chart')?.getBoundingClientRect().width ?? 100;
        const maxWidth = containerWidth * 0.05;

        if (description.length > maxWidth) {
          const truncatedText = description.substring(0, maxWidth - 3) + '...';
          el.text(truncatedText);
          el.on('mouseover', function (event) {
            tooltip
              .text(d.description)
              .style('position', 'absolute')
              .style('left', event.pageX - 10 + 'px')
              .style('top', event.pageY + 10 + 'px')
              .style('background-color', 'black')
              .style('color', 'white')
              .style('font-size', '11px')
              .style('border-radius', '4px')
              .style('border', '1px solid rgb(2, 2, 2)')
              .style('opacity', '0.9')
              .style('min-width', '120px')
              .style('display', 'block')
              .style('padding', '2px')
              .style('margin', '0px');
          }).on('mouseout', function () {
            tooltip.style('display', 'none');
          });
        } else {
          el.text(description);
        }
      });

    d3.selectAll('path,line').remove();
  }

  generateInitials(name: string) {
    const words = name.trim().split(' ');

    if (words.length === 0) {
      return '';
    }

    return words
      .filter((word) => word !== '') // Remove empty words
      .map((word) => word[0].toUpperCase()) // Get the first character of each word
      .slice(0, 2) // Take the first two characters
      .join('');
  }
}
