<div class="accordion cst_accordian mt-0 " [id]="'category-' + getCategoryId(categories[0].id)">
  <!-- Loop over categories -->
  <div class="accordion-item" *ngFor="let category of categories; index as i">
    <!-- Category Title -->
    <h2 class="accordion-header" [id]="getCategoryId(category.id)">
      <button
        class="accordion-button"
        [ngClass]="{ collapsed: i !== 0, error: hasError(i) }"
        type="button"
        data-bs-toggle="collapse"
        [attr.data-bs-target]="'#collapse' + getCategoryId(category.id)"
        aria-expanded="true"
        [attr.aria-controls]="'collapse' + getCategoryId(category.id)"
        (click)="navigateToId(category.id)"
      >
        <span>{{ category.id }}</span> {{ category.name }}
      </button>
    </h2>

    <!-- Category Body -->
    <div
      [id]="'collapse' + getCategoryId(category.id)"
      class="accordian-collapse collapse"
      [ngClass]="{ show: i === 0 }"
      [attr.aria-labelledby]="'heading' + getCategoryId(category.id)"
      [attr.data-bs-parent]="'#category-' + getCategoryId(categories[0].id)"
    >
      <app-category
        [category]="category"
        [formGroup]="category['form']"
        [answerData]="answerData"
        [isFormSubmitted]="isFormSubmitted"
        [noteGroup]="noteGroup"
        [attachments]="attachments"
        [stepId]="stepId"
        (getAttachments)="getAttachmentsHere()"
      ></app-category>
    </div>
  </div>
</div>
