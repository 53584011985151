<div class="modal-backdrop"></div>
<div class="modal modal-dialog modal-dialog-centered">
  <div class="modal-content warning-modal">
    <div class="modal-header">
      <h5 class="modal-title" id="warning-modal"><em class="bi bi-exclamation-triangle-fill"></em>{{ 'shared.warning.header' | translate }}</h5>
    </div>
    <div class="modal-body">
      <p>You can Save Draft before going to dashboard</p>
      <p>Click Continue to go to dashboard anyway</p>
    </div>
    <div class="modal-footer justify-content-between">
      <button type="button" class="btn link_btn" (click)="cancel()">{{ 'shared.cancel' | translate }}</button>
      <div>
        <button type="button" class="btn cst_btn me-2" (click)="goBack()">Continue</button>
        <button type="button" class="btn cst_btn" (click)="saveDraft()">Save Draft</button>
      </div>
    </div>
  </div>
</div>
