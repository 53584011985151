<div class="container-fluid">
  <div class="row">
    <div class="col-lg-4 d-lg-block d-none p-0">
      <app-sidepanel></app-sidepanel>
    </div>
    <div class="col-lg-8 col-12">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
