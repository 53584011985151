import { Location } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { lastValueFrom } from 'rxjs';
import { User } from 'src/app/interfaces/user.interface';
import { SecuritySubscriberService } from 'src/app/security/security-subscriber.service';
import { SecurityService } from 'src/app/security/security.service';
import { VendorService } from 'src/app/vendor/vendor.service';
import { SharedService } from '../../services/shared.service';
import { URLS } from '../../urls';
import * as moment from 'moment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @Input() isToggled: boolean = false;
  userDetails!: User;
  @Input() transition = true;
  notifications: any = [];
  isNotification = false;
  fetchedOn: any;
  @Input() displayLogoHeader: any = null;
  @Output() sidebarToggle = new EventEmitter<boolean>();
  @Output() removeTransition = new EventEmitter<boolean>();
  constructor(
    private readonly sharedService: SharedService,
    private readonly router: Router,
    private readonly securityService: SecurityService,
    private readonly securitySubscriberService: SecuritySubscriberService,
    private readonly location: Location,
    private readonly vendorService: VendorService
  ) {}

  async ngOnInit() {
    this.securitySubscriberService.getCurrentUser.subscribe((user) => {
      this.userDetails = user;
    });

    const result = await lastValueFrom(this.sharedService.getNotification());
    this.notifications = result.data;
    this.fetchedOn = result.fetchedOn;
  }

  toggleSidebar() {
    this.isToggled = !this.isToggled;
    this.sidebarToggle.emit(this.isToggled);
    this.removeTransition.emit(false);
    this.transition = false;
  }

  toggleNotification() {
    this.isNotification = !this.isNotification;
  }

  onHomeClicked() {
    const url = this.location.path();
    if (this.userDetails.role === 'vendor' && url.includes('/vendor/solution/') && !url.endsWith('/add')) {
      this.sharedService.onHomeClicked.emit();
    } else {
      this.securityService.navigateUser(this.userDetails.role);
    }
    this.removeTransition.emit(!this.isToggled);
    this.transition = !this.isToggled;
  }

  async userDetail(visitToPage: string) {
    try {
      switch (visitToPage) {
        case 'profile':
          this.router.navigate([URLS.userProfile]);
          break;
        case 'sign-out':
          const url = this.location.path();
          if (!url.includes('add') && url.startsWith('/vendor/solution')) {
            try {
              const parts = url.split('/');
              const solutionId = parts[3];
              await lastValueFrom(this.vendorService.deleteFormEditorList(solutionId));
            } catch (error) {}
          }

          await this.logoutUser();
          break;
        case 'change-password':
          this.router.navigate([URLS.userChangePassword]);
          break;
        default:
          break;
      }
    } catch (error: any) {
      this.sharedService.showDynamicAlert('Something went wrong');
    }
  }

  formatedDate(date: any) {
    return moment(date).fromNow();
  }

  getColorByRole(role: string) {
    const temp: any = {
      admin: 'pink',
      whopanel: 'purple',
      reviewer: 'green',
      vendor: 'yellow'
    };
    return temp[role] ?? 'gray';
  }

  getNotificationName(notification: any) {
    if (notification.userFirstName) return `${notification.userFirstName} ${notification.userLastName ?? ''}`;
    else if (notification.userEmail) return notification.userEmail;
    return 'system';
  }

  async clearAllNotification() {
    await lastValueFrom(this.sharedService.deleteAllNotification(this.fetchedOn));
    this.notifications = [];
  }

  private async logoutUser() {
    await lastValueFrom(this.securityService.logoutUser());
    this.securitySubscriberService.isLoggedIn.next(false);
    this.securitySubscriberService.clearCurrentUserDetail();
    this.router.navigate([URLS.login]);
  }

  cancel() {
    this.isNotification = false;
  }
}
