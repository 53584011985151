import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FormService } from 'src/app/vendor/form/form.service';
import { Question } from '../../models/question.model';
import { SharedService } from '../../services/shared.service';
import { SecurityService } from 'src/app/security/security.service';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-multi-select',
  templateUrl: './multi-select.component.html',
  styleUrls: ['./multi-select.component.scss']
})
export class MultiSelectComponent implements OnInit {
  _isFormSubmitted = false;
  @Input() question!: Question;
  @Input() formGroup!: FormGroup;
  @Input() answerData: any;
  @Input()
  set isFormSubmitted(formSubmitted: boolean) {
    if (formSubmitted) {
      this._isFormSubmitted = formSubmitted;
      this.multiSelectControl?.disable();
      this.otherInputControl?.disable();
    }
  }

  multiSelectControl!: FormControl;
  @Input() isPreSubmission = false;
  showOtherInput = false;
  otherOptionId = '';
  otherInputControl!: FormControl;

  constructor(private readonly securityService: SecurityService, private readonly formService: FormService, private readonly sharedService: SharedService) {}

  async ngOnInit() {
    this.checkIfOtherInputExists();
    this.setMultiSelectForm();
    this.setAnswerDataIfExists();
    if (this.question?.apiData === 'countries') {
      this.question.options = await this.getCountries();
      if (this.answerData) {
        this.multiSelectControl.setValue(this.answerData);
      }
    }
  }

  /**
   * Sets the scroller on top and not to the latest selected item.
   */
  onOpen() {
    setTimeout(() => {
      const scrollContainer = document.querySelector('.ng-dropdown-panel-items');
      if (scrollContainer) {
        scrollContainer.scrollTop = 0;
      }
    }, 0);
  }

  hasError() {
    return this.sharedService.hasError(this.formGroup, this.question.id);
  }

  hasErrorInOtherInput() {
    return this.sharedService.hasError(this.formGroup, `multi-other-${this.question.id}`);
  }

  onAdd() {
    if (this.multiSelectControl.value.includes(this.otherOptionId)) {
      this.showOtherInput = true;
      this.otherInputControl.addValidators([Validators.required, Validators.maxLength(200)]);
      this.otherInputControl.updateValueAndValidity({ emitEvent: false });
      this.otherInputControl.markAsTouched();
      return;
    }
    this.showOtherInput = false;
  }

  onRemove(option: any) {
    if (option.value === this.otherOptionId) {
      this.onClear();
    }
  }

  onClear() {
    this.showOtherInput = false;
    this.otherInputControl?.clearValidators();
    this.otherInputControl?.updateValueAndValidity({ emitEvent: false });
    this.otherInputControl?.setValue('');
  }

  checkIfOtherInputExists() {
    const otherOption = this.question.options.find((option: any) => option.label.toLowerCase().includes('other'));
    if (otherOption) {
      this.otherOptionId = otherOption.id;
    }
  }

  setMultiSelectForm() {
    const validators = this.question.isRequired ? [Validators.required] : [];
    if (this.otherOptionId) {
      this.otherInputControl = new FormControl('');
      this.formGroup.addControl('multi-other-' + this.question.id, this.otherInputControl);
    }
    this.multiSelectControl = new FormControl('', validators);

    this.formGroup.addControl(this.question.id, this.multiSelectControl);
    this.formService.skipLogic(this.question.skipLogic, this.formGroup, this.question.id, this.question.isRequired, this.isPreSubmission);
    if (this._isFormSubmitted) {
      this.multiSelectControl.disable();
      this.otherInputControl?.disable();
    }
  }

  setAnswerDataIfExists() {
    if (this.answerData) {
      const uuidAnswers = this.answerData.filter((ans: string) => this.sharedService.isUUID(ans));
      this.multiSelectControl.setValue(uuidAnswers);
      const nonUUIDAnswer = this.answerData.find((ans: string) => !this.sharedService.isUUID(ans));
      if (nonUUIDAnswer || this.answerData.includes(this.otherOptionId)) {
        this.otherInputControl.setValue(nonUUIDAnswer ?? '');
        this.showOtherInput = true;
        this.otherInputControl.addValidators([Validators.required, Validators.maxLength(200)]);
        this.otherInputControl.updateValueAndValidity({ emitEvent: false });
        return;
      }
      this.showOtherInput = false;
    }
  }

  async getCountries() {
    return (await lastValueFrom(this.securityService.getCountries())).countries;
  }

  skipQuestion(): boolean {
    return this.formService.question(this.formGroup, this.question.id)['skipQuestion'];
  }
}
