import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FormService } from 'src/app/vendor/form/form.service';
import { Question } from '../../models/question.model';
import { SharedService } from '../../services/shared.service';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss']
})
export class InputComponent implements OnInit {
  _isFormSubmitted = false;
  @Input() question!: Question;
  @Input() formGroup!: FormGroup;
  @Input() answerData: any;
  @Input() isPreSubmission = false;
  @Input()
  set isFormSubmitted(formSubmitted: boolean) {
    if (formSubmitted) {
      this._isFormSubmitted = formSubmitted;
      this.inputFormControl?.disable();
    }
  }
  inputFormControl!: FormControl;

  constructor(private readonly formService: FormService, private readonly sharedService: SharedService) {}

  ngOnInit(): void {
    const validators = this.question.isRequired ? [Validators.required] : [];
    this.inputFormControl = new FormControl(this.answerData || '', [...validators, Validators.maxLength(200)]);
    this.formGroup.addControl(this.question.id, this.inputFormControl);
    this.formService.skipLogic(this.question.skipLogic, this.formGroup, this.question.id, this.question.isRequired, this.isPreSubmission);
    if (this._isFormSubmitted) {
      this.inputFormControl?.disable();
    }
  }

  skipQuestion(): boolean {
    return this.formService.question(this.formGroup, this.question.id)['skipQuestion'];
  }

  hasError() {
    return this.sharedService.hasError(this.formGroup, this.question.id);
  }
}
