import { NgModule } from '@angular/core'

import { MatStepperModule } from '@angular/material/stepper'
import { MatExpansionModule } from '@angular/material/expansion'
import { MatButtonModule } from '@angular/material/button'
import { MatInputModule } from '@angular/material/input'

@NgModule({
  declarations: [],
  imports: [
    MatButtonModule,
    MatStepperModule,
    MatInputModule,
    MatExpansionModule
  ],
  exports: [
    MatButtonModule,
    MatStepperModule,
    MatInputModule,
    MatExpansionModule
  ]
})
export class MaterialModule { }
