import { Location } from '@angular/common';
import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { from, lastValueFrom } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { User } from 'src/app/interfaces/user.interface';
import { SecuritySubscriberService } from '../security-subscriber.service';
import { SharedService } from 'src/app/shared/services/shared.service';
import { SecurityService } from '../security.service';

@Injectable({
  providedIn: 'root'
})
export class Interceptor implements HttpInterceptor {
  userDetails!: User;

  constructor(
    private readonly router: Router,
    private readonly location: Location,
    private readonly securitySubscriberService: SecuritySubscriberService,
    private readonly securityService: SecurityService,
    private readonly sharedService: SharedService
  ) {}
  intercept(req: HttpRequest<any>, next: HttpHandler): any {
    // convert promise to observable using 'from' operator
    return from(this.handle(req, next));
  }

  async handle(req: HttpRequest<any>, next: HttpHandler) {
    const finalReq = req.clone({
      withCredentials: true
    });

    this.sharedService.showDisplayLoader();

    const res = await lastValueFrom(
      next.handle(finalReq).pipe(
        catchError((err: any) => {
          this.sharedService.hideDisplayLoader();
          switch (err.status) {
            case 404:
              break;
            case 401:
              if (err?.error?.message?.includes('middleware.please_login_first') || err?.error?.message?.includes('user.user_does_not_exists')) {
                this.logoutUserAndRemoveData();
              }
              break;
            case 500:
              this.sharedService.showDynamicAlert();
              break;
          }
          throw err;
        })
      )
    );

    this.sharedService.hideDisplayLoader();

    return res;
  }

  /**
   * Handles logout of user account, on logging out removes state of user
   * @param message
   */
  async logoutUserAndRemoveData() {
    await lastValueFrom(this.securityService.logoutUser());

    // Updates user-details with empty object and update isLoggedIn flag to false
    this.securitySubscriberService.updateCurrentUserDetail(this.userDetails);
    this.securitySubscriberService.isLoggedIn.next(false);

    this.router.navigate(['/login']);
  }
}
