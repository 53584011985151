import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { SimpleModalComponent } from 'ngx-simple-modal';
import { URLS } from '../../urls';

export interface ThankYouModel {
  title: string;
}
@Component({
  selector: 'app-thank-you',
  templateUrl: './thank-you.component.html',
  styleUrls: ['./thank-you.component.scss']
})
export class ThankYouComponent extends SimpleModalComponent<ThankYouModel, boolean> implements ThankYouModel {
  title: string = '';
  constructor(private router: Router) {
    super();
  }

  navigateToHomePage() {
    this.close();
    this.router.navigate([URLS.vendor.dashboard]);
  }
}
