import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormSubCategory } from '../sub-category/sub-category.model';
import { FormCategory } from './category.model';

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html'
})
export class CategoryComponent implements OnInit {
  @Input() category!: FormCategory;
  @Input() formGroup!: FormGroup;
  @Input() answerData: any;
  @Input() isFormSubmitted!: boolean;
  @Input() noteGroup!: FormGroup;
  @Input() attachments: any[] = [];
  @Input() stepId!: string;
  @Output() getAttachments = new EventEmitter();
  subcats!: FormSubCategory[];
  displayQuestions = false;

  ngOnInit(): void {
    this.subcats = (this.category && this.category['sub-categories']) || [];
  }

  getAttachmentsHere() {
    this.getAttachments.emit();
  }
}
