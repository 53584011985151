import { BreakpointObserver } from '@angular/cdk/layout';
import { STEPPER_GLOBAL_OPTIONS, StepperOrientation } from '@angular/cdk/stepper';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Injector, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { ActivatedRoute, Router } from '@angular/router';
import { SimpleModalService } from 'ngx-simple-modal';
import { Observable, Subscription, lastValueFrom, map } from 'rxjs';
import { ConfirmComponent } from 'src/app/shared/components/confirm/confirm.component';
import { ThankYouComponent } from 'src/app/shared/components/thank-you/thank-you.component';
import { SharedService } from 'src/app/shared/services/shared.service';
import { VendorService } from '../../vendor.service';
import { FormService } from '../form.service';
import { FormStage } from '../stage/stage.model';
import { ViewportScroller } from '@angular/common';
import { URLS } from 'src/app/shared/urls';
import { SubmissionStatusComponent } from 'src/app/shared/components/submission-status/submission-status.component';
import { WarningComponent } from 'src/app/shared/components/warning/warning.component';
import { HomeWarningComponent } from 'src/app/shared/components/home-warning/home-warning.component';
@Component({
  selector: 'app-step',
  templateUrl: './step.component.html',
  styleUrls: ['./step.component.scss'],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false, showError: true }
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StepComponent implements OnInit, OnDestroy {
  // Input Variables
  @Input() answerData: any;
  @Input() prevStageAnswerData: any;
  @Input() intakeForm!: FormGroup;
  @Input() questionData!: FormStage[];
  @Input() isFormSubmitted!: boolean;
  @Input() noteGroup!: FormGroup;
  @Input() attachments: any[] = [];
  @Output() getAttachments = new EventEmitter();

  // Variables for mat-stepper
  isLinear = false;
  stepperOrientation: Observable<StepperOrientation>;
  @ViewChild('stepper') stepper!: MatStepper;
  @ViewChild('evidenceRef') evidenceRef!: any;

  // Local variables
  stageFormGroups: Array<{ name: string; answers: any[]; form: FormGroup }> = [];
  isSubmitted = false;
  stageNames: string[] = [];
  solutionId!: string;
  previousStageId: string = '';
  stepIds: string[] = [];
  isDisableNextState: boolean = true;
  stageId!: string;
  homeClickSubscription!: Subscription;

  private simpleModalService: SimpleModalService;
  private formService: FormService;
  private vendorService: VendorService;
  breakpointObserver: BreakpointObserver;
  private sharedService: SharedService;
  private readonly activeRoute: ActivatedRoute;
  private viewPortScroller: ViewportScroller;
  private router: Router;

  constructor(private injector: Injector) {
    this.simpleModalService = this.injector.get<SimpleModalService>(SimpleModalService);
    this.formService = this.injector.get<FormService>(FormService);
    this.vendorService = this.injector.get<VendorService>(VendorService);
    this.breakpointObserver = this.injector.get<BreakpointObserver>(BreakpointObserver);
    this.sharedService = this.injector.get<SharedService>(SharedService);
    this.activeRoute = this.injector.get<ActivatedRoute>(ActivatedRoute);
    this.viewPortScroller = this.injector.get<ViewportScroller>(ViewportScroller);
    this.router = this.injector.get<Router>(Router);

    this.stepperOrientation = this.breakpointObserver.observe('(min-width: 767.98px)').pipe(map(({ matches }) => (matches ? 'horizontal' : 'vertical')));
    this.sharedService.isSubmitted.subscribe((data: boolean) => {
      this.isSubmitted = data;
    });
  }

  ngOnInit(): void {
    this.solutionId = this.activeRoute.snapshot.params['solutionid'];
    this.stageId = this.activeRoute.snapshot.params['stageid'];
    const step_names = this.questionData.map((stage: FormStage) => {
      this.stageNames.push(stage.name);
      return stage.columnName;
    });
    this.previousStageId = this.activeRoute.snapshot.params['previousstageid'];
    if (this.previousStageId) this.isDisableNextState = false;
    this.stepIds = this.questionData.map((que: any) => que.id);
    this.createFormForAllStages(step_names);
    this.sharedService.isSubmitted.next(false);
    if (this.isFormSubmitted) {
      this.isSubmitted = true;
      this.onSelectionChange({ selectedIndex: 0 });
    }

    this.homeClickSubscription = this.sharedService.onHomeClicked.subscribe(async () => {
      if (this.isSubmitted || this.isFormSubmitted) {
        this.router.navigate([URLS.vendor.dashboard]);
        return;
      }
      await this.simpleModalService.removeAll();
      this.simpleModalService.addModal(HomeWarningComponent).subscribe(async (isConfirmed) => {
        if (isConfirmed === undefined) {
          return;
        }

        if (isConfirmed) {
          try {
            await this.saveResponse(false);
          } catch (error) {}
          this.sharedService.showDynamicAlert('Draft Saved!', 'success');
        }
        this.router.navigate([URLS.vendor.dashboard]);
      });
    });
  }
  createFormForAllStages(stepName: any) {
    if (!this.isDisableNextState) {
      this.stageFormGroups.push({
        name: 'summary',
        answers: [],
        form: new FormGroup({})
      });
    }

    stepName.map((step_name: string) => {
      const form = new FormGroup({});
      this.intakeForm.addControl(step_name, form);

      this.stageFormGroups.push({
        name: step_name,
        answers: this.answerData[step_name],
        form
      });
    });

    if (!this.isDisableNextState) {
      this.stageFormGroups.push({
        name: 'evidence',
        answers: [],
        form: new FormGroup({})
      });
    }

    this.formService.intakeForm = this.intakeForm;
  }

  getControls() {
    return this.intakeForm.controls as any;
  }

  hasError(i: number) {
    return {
      error:
        this.sharedService.isSubmitted.getValue() &&
        this.intakeForm.controls?.[this.questionData[i].columnName]?.status === 'INVALID' &&
        this.intakeForm.controls[this.questionData[i].columnName].touched
    };
  }

  async saveResponse(final: boolean) {
    this.removeControlsStartingWithOther(this.intakeForm);
    this.deleteFormGroup(this.intakeForm, 'PS');

    Object.keys(this.intakeForm.value).forEach((solName: string) => {
      if (!solName.includes(this.stageId)) {
        this.deleteFormGroup(this.intakeForm, solName);
      }
    });

    const submissionStatus = await lastValueFrom(this.vendorService.checkSolutionSubmission(this.solutionId));

    if (submissionStatus.isSubmitted) {
      try {
        this.simpleModalService
          .addModal(SubmissionStatusComponent, {
            title: 'Submission Status',
            lang_msg: 'Someone from your organization has already submitted the form',
            type: 'BACK'
          })
          .subscribe(async () => {
            await lastValueFrom(this.vendorService.deleteFormEditorList(this.solutionId));
            location.reload();
          });
      } catch (error: any) {
        this.sharedService.showDynamicAlert('Something went wrong');
      }
      return;
    }

    try {
      const successMessage = final ? 'Form Submitted!' : 'Draft Saved!';
      if (final) {
        this.simpleModalService
          .addModal(ConfirmComponent, {
            title: 'Confirm Submission',
            showImg: false,
            lang_msg: 'Are you sure you want to submit the form? Once submitted you will not be able to edit the form',
            email: ''
          })
          .subscribe(async (isConfirmed) => {
            // Get modal result
            if (isConfirmed) {
              await lastValueFrom(this.formService.saveResponse(this.solutionId, this.intakeForm.value, false, true));
              try {
                await lastValueFrom(this.vendorService.deleteFormEditorList(this.solutionId));
              } catch (error) {}
              this.sharedService.showDynamicAlert(successMessage, 'success');

              const title = 'Thank You';
              this.simpleModalService.addModal(ThankYouComponent, {
                title: title
              });
            }
          });
      } else {
        await lastValueFrom(this.formService.saveResponse(this.solutionId, this.intakeForm.value, true, false));
        try {
          await lastValueFrom(this.vendorService.addFormEditList(this.solutionId));
        } catch (error) {}
        this.sharedService.showDynamicAlert(successMessage, 'success');
      }
    } catch (error: any) {
      this.sharedService.showDynamicAlert('Something went wrong');
    }
  }

  checkError(idx?: number) {
    const stepperElements = document.querySelectorAll('.mat-step-icon');

    this.stepper._steps.forEach((step, stepIndex) => {
      if (idx === stepIndex) {
        stepperElements[stepIndex].classList.remove('mat-step-show-error', 'mat-step-show-done');
        stepperElements[stepIndex].nextElementSibling?.classList.remove('mat-step-show-label-error', 'mat-step-show-label-done');
      } else if (step.stepControl.status === 'INVALID') {
        stepperElements[stepIndex].classList.add('mat-step-show-error');
        stepperElements[stepIndex].nextElementSibling?.classList.add('mat-step-show-label-error');
      } else {
        stepperElements[stepIndex].classList.add('mat-step-show-done');
        stepperElements[stepIndex].nextElementSibling?.classList.add('mat-step-show-label-done');
      }
    });
  }

  /**
   * 1. Checks all the questions for validity by calling @function checkFormGroup from data service
   * 2. Sends a request to save the current response in the backend by calling @function saveResponse
   */
  onSubmit() {
    if (this.isFormSubmitted) {
      this.router.navigate([URLS.vendor.dashboard]);
      return;
    }
    this.sharedService.isSubmitted.next(true);
    this.checkError(this.isDisableNextState ? this.stageNames.length - 1 : this.stageNames.length + 1);
    const invalidQuestionIds = this.formService.checkFormGroup(this.intakeForm);
    if (invalidQuestionIds.length === 0) {
      this.saveResponse(true);
      return;
    }
    this.sharedService.showDynamicAlert('Form Incomplete');
  }
  removeControlsStartingWithOther(formGroup: FormGroup): void {
    Object.keys(formGroup.controls).forEach((controlName: string) => {
      const control: AbstractControl = formGroup.controls[controlName];

      if (control instanceof FormGroup) {
        // Recursively call the function for nested form groups
        this.removeControlsStartingWithOther(control);
      } else if (controlName.startsWith('other-')) {
        // Remove the control if its name starts with 'other-'
        formGroup.removeControl(controlName);
      } else if (controlName.startsWith('multi-other-')) {
        // Get the ID without the "multi-other-" prefix
        const id = controlName.substring('multi-other-'.length);
        // Check if there is a corresponding control without the prefix
        if (formGroup.controls.hasOwnProperty(id)) {
          const originalControl = formGroup.controls[id];
          if (control.value) {
            originalControl.value.push(control.value);
          }
          if (control.valid) {
            formGroup.removeControl(controlName);
          }
        }
      }
    });
  }

  deleteFormGroup(formGroup: any, groupNameToRemove: any) {
    if (formGroup.controls[groupNameToRemove] instanceof FormGroup) {
      formGroup.removeControl(groupNameToRemove);
    }
  }

  getAttachmentsHere() {
    this.getAttachments.emit();
  }

  async onSelectionChange(event: any) {
    setTimeout(() => {
      this.viewPortScroller.scrollToPosition([0, 0]);
    }, 0);

    await lastValueFrom(this.vendorService.addFormEditList(this.solutionId));
    const index = event.selectedIndex;
    this.isSubmitted && this.checkError(index);

    if (!this.isDisableNextState && index === this.stageFormGroups.length - 1) {
      this.evidenceRef.updateData(this.getControls(), this.attachments);
    }
  }

  ngOnDestroy() {
    this.simpleModalService.removeAll();
    this.homeClickSubscription.unsubscribe();
  }
}
