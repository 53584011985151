<div class="cst-list-group pb-5 cursor-default" style="overflow-x: hidden">
  <h2 *ngIf="previousQuestions.length" class="stage-title">stage 1</h2>
  <ul *ngFor="let data of previousQuestions" class="custom-list-group">
    <h3>{{ data.name }}</h3>
    <li [class.bg]="selectedId === getId(configItem.id)" (click)="navigateToId(configItem.id)" class="cursor-pointer" *ngFor="let configItem of data.config">
      {{ configItem.name }}
    </li>
  </ul>
  <h2 *ngIf="!previousQuestions.length" class="stage-title">stage 1</h2>
  <h2 *ngIf="previousQuestions.length" class="stage-title mt-4">stage 2</h2>
  <ul *ngFor="let data of questions">
    <h3>{{ data.name }}</h3>
    <li [class.bg]="selectedId === getId(configItem.id)" (click)="navigateToId(configItem.id)" class="cursor-pointer" *ngFor="let configItem of data.config">
      {{ configItem.name }}
    </li>
  </ul>
</div>
