import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, forkJoin, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { URLS } from '../shared/urls';

@Injectable({
  providedIn: 'root'
})
export class SecurityService {
  constructor(private readonly http: HttpClient, private readonly router: Router) {}

  createNewUser(userData: any): Observable<any> {
    return this.http.post(`${environment.baseUrl}/user/create-user`, userData);
  }

  login(email: string, password: string, role: string, remember = false): Observable<any> {
    return this.http.post(`${environment.baseUrl}/login`, { email, password, role, remember });
  }

  twofactorAuth(twoFactorCode: number): Observable<any> {
    return this.http.post(`${environment.baseUrl}/two-factor-auth`, { code: twoFactorCode });
  }

  resendTwoFactorCode(): Observable<any> {
    return this.http.get(`${environment.baseUrl}/user/resend-two-factor-code`);
  }

  getCurrentUser(): Observable<any> {
    return this.http.get(`${environment.baseUrl}/user/get-current-user`);
  }

  logoutUser(): Observable<any> {
    return this.http.post(`${environment.baseUrl}/logout`, {});
  }

  getCookie(name: string): string | undefined {
    const value = RegExp(`(^|;)\\s*${name}\\s*=\\s*([^;]+)`).exec(document.cookie);
    return value ? value.pop() : '';
  }

  forgotPassword(email: string, role: string): Observable<any> {
    return this.http.post(`${environment.baseUrl}/user/forgot-password`, { email, role });
  }

  changePassword(userDetails: any): Observable<any> {
    return this.http.post(`${environment.baseUrl}/user/change-password`, userDetails);
  }

  resetPassword(token: string, newPassword: any): Observable<any> {
    return this.http.post(`${environment.baseUrl}/user/reset-password`, { token, newPassword });
  }

  updateUser(userDetails: any): Observable<any> {
    return this.http.put(`${environment.baseUrl}/user/update-user`, userDetails);
  }

  getCountries(): Observable<any> {
    return this.http.get(`${environment.baseUrl}/countries.json`);
  }

  verifyAccount(token: string): Observable<any> {
    return this.http.put(`${environment.baseUrl}/user/verify-account`, { token });
  }

  getSolutionDevelopersPDFs(): Observable<Map<string, Blob>> {
    const pdfUrls = [
      { key: 'whoch_countrybrief_designed', url: `${environment.baseUrl}/whoch_countrybrief_designed.pdf` },
      { key: 'whoch_fundersbrief_designed', url: `${environment.baseUrl}/whoch_fundersbrief_designed.pdf` },
      { key: 'whoch_implementersbrief_designed', url: `${environment.baseUrl}/whoch_implementersbrief_designed.pdf` },
      { key: 'whoch_solutiondevelopersbrief_designed', url: `${environment.baseUrl}/whoch_solutiondevelopersbrief_designed.pdf` }
    ];

    const requests = pdfUrls.map((pdf) => {
      return this.http.get(pdf.url, { responseType: 'blob' }).pipe(map((pdfBlob: Blob) => ({ key: pdf.key, value: pdfBlob })));
    });

    return forkJoin(requests).pipe(
      map((pdfBlobsArray: Array<{ key: string; value: Blob }>) => {
        const pdfBlobsMap = new Map<string, Blob>();
        pdfBlobsArray.forEach((pdfBlob) => {
          pdfBlobsMap.set(pdfBlob.key, pdfBlob.value);
        });
        return pdfBlobsMap;
      })
    );
  }

  navigateUser(role: string) {
    if (role === 'admin') {
      this.router.navigate([URLS.admin.dashboard]);
    } else if (role === 'vendor') {
      this.router.navigate([URLS.vendor.dashboard]);
    } else if (role === 'reviewer') {
      this.router.navigate([URLS.reviewer.dashboard]);
    } else if (role === 'whopanel') {
      this.router.navigate([URLS.whopanel.dashboard]);
    }
  }
  getLink(role: string) {
    switch (role) {
      case 'admin':
        return URLS.admin.dashboard;
      case 'vendor':
        return URLS.vendor.dashboard;
      case 'reviewer':
        return URLS.reviewer.dashboard;
      case 'whopanel':
        return URLS.whopanel.dashboard;
      default:
        this.logoutUser();
        return URLS.login;
    }
  }
  verifyReCaptcha(token: string) {
    return this.http.post(`${environment.baseUrl}/user/verify-recaptcha`, { token });
  }
}
