<div class="accordion cst-accordion" [id]="accordionName + 'accordionExample'">
  <div class="accordion-item" *ngFor="let accordion of _accordions; let i = index">
    <h2 class="accordion-header" [id]="accordionName + accordion.id">
      <button
        class="accordion-button"
        [class.collapsed]="i !== 0"
        type="button"
        data-bs-toggle="collapse"
        [attr.data-bs-target]="!isTitleEditable(i) ? '#' + accordionName + 'collapse' + accordion.id : ''"
        [attr.aria-expanded]="i === 0 ? true : false"
        [attr.aria-controls]="!isTitleEditable(i) ? '#' + accordionName + 'collapse' + accordion.id : ''"
        (click)="scrollToAccordion(accordion.id)"
      >
        <input
          [id]="accordionName + 'text-edit-' + i"
          type="text"
          [class.custom-input]="!isTitleEditable(i)"
          [(ngModel)]="accordion.title"
          (blur)="stopEditingTitle()"
          placeholder="Accordion Title"
        />
        <span class="cst-title" *ngIf="!isTitleEditable(i)">
          {{ accordion.title }}
        </span>
        <span class="edit-icons">
          <i class="bi bi-pencil-square" (click)="editAccordionTitle(i)"></i>
          <i class="bi bi-trash3" (click)="deleteAccordion(accordion)"></i>
        </span>
      </button>
    </h2>
    <div
      [id]="accordionName + 'collapse' + accordion.id"
      class="accordion-collapse collapse"
      [attr.data-bs-parent]="'#' + accordionName + 'accordionExample'"
      [class.show]="i === 0 && hasEditorLoaded"
    >
      <div class="accordion-body">
        <div *ngIf="hasDecisions" class="mb-3 row">
          <label for="staticEmail" class="col-sm-3 col-form-label">Select your Decision :</label>
          <div class="col-sm-9">
            <app-ng-select-custom
              [isMultiple]="false"
              [isClearable]="false"
              [isSearchable]="false"
              [isPlaceholder]="true"
              [items]="decisions"
              [selectedItems]="accordion.decision || null"
              (selectedItemChange)="selectedDecision(accordion.id, $event)"
            ></app-ng-select-custom>
          </div>
        </div>
        <div class="mb-3 row">
          <label for="inputPassword" class="col-sm-3 col-form-label">Description :</label>
          <div class="col-sm-9">
            <textarea [id]="accordionName + 'text-control-' + accordion.id" [(ngModel)]="accordion.content" placeholder="Enter text"></textarea>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Plus sign to add new accordion -->
<button mat-raised-button color="primary" (click)="addAccordion()" class="add-button">
  <mat-icon>add</mat-icon>
  {{ newBtnText }}
</button>
