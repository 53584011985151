<div class="main-content">
  <app-breadcrumb
    [title]="'breadcrumb.registration_request' | translate | titlecase"
    [items]="[
      { title: 'users', link: '/admin/users/user-list' },
      { title: 'registration_request', link: '/users' }
    ]"
  >
  </app-breadcrumb>
  <div class="table_container">
    <div class="d-md-flex mb-md-0 mb-2 justify-content-between align-items-center">
      <div>
        <form class="table_search mt-2">
          <input
            name="search-pending-users"
            class="form-control search_input me-2"
            [placeholder]="'shared.search' | translate | titlecase"
            id="search-pending-users"
            type="search"
            [(ngModel)]="paginationOptions.searchTerm"
            (ngModelChange)="onSearchChange($event)"
          />
        </form>
      </div>
    </div>
    <div class="table-responsive">
      <table class="table cst_table table-striped" aria-describedby="user registeration">
        <thead>
          <tr>
            <th scope="col">
              <p>
                {{ 'shared.email' | translate }}
                <span><app-sorting [sortByColumn]="sortByColumn" [sortDirection]="sortDirection" (sortChanged)="onSortChanged($event)" columnName="email"></app-sorting> </span>
              </p>
            </th>
            <th scope="col">
              <p>
                {{ 'shared.company_name' | translate }}
                <span
                  ><app-sorting [sortByColumn]="sortByColumn" [sortDirection]="sortDirection" (sortChanged)="onSortChanged($event)" columnName="companyName"></app-sorting>
                </span>
              </p>
            </th>
            <th scope="col">
              <p>
                {{ 'shared.role' | translate }}
                <span><app-sorting [sortByColumn]="sortByColumn" [sortDirection]="sortDirection" (sortChanged)="onSortChanged($event)" columnName="role"></app-sorting> </span>
              </p>
            </th>
            <th scope="col">{{ 'shared.action' | translate }}</th>
          </tr>
        </thead>
        <tbody *ngIf="!noDataFound">
          <tr *ngFor="let user of users | paginate : { itemsPerPage: paginationOptions.pageSize, currentPage: paginationOptions.page, totalItems: totalUsers }; let i = index">
            <td>
              {{ user.email }}
            </td>
            <td>{{ user.companyName }}</td>
            <td>{{ user.role }}</td>
            <td>
              <img (click)="onChangeStatus(user, 'Approved')" class="me-1 img-static" src="assets/images/approve.svg" alt="approve" />
              <img (click)="onChangeStatus(user, 'Rejected')" src="assets/images/reject.svg" alt="reject" class="img-static" />
            </td>
          </tr>
          <tr class="no-row-available" *ngIf="noDataFound">
            <td colspan="4" rowspan="2">{{ 'alert.no_records_found' | translate }}</td>
          </tr>
        </tbody>
        <tbody *ngIf="noDataFound">
          <tr class="no-row-available">
            <td colspan="6" rowspan="2">{{ 'alert.no_records_found' | translate }}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <app-pagination
      *ngIf="users?.length"
      [currentPage]="paginationOptions.page"
      [totalPages]="totalPages"
      (pageChange)="pageChange($event)"
      [limitSelected]="paginationOptions.pageSize"
    >
    </app-pagination>
  </div>
</div>
