import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AddSolutionComponent } from './add-solution/add-solution.component';
import { CallListComponent } from '../shared/components/call-list/call-list.component';
import { FormComponent } from './form/form.component';
import { VendorRoleGuard } from './security/vendor-role.guard';
import { VendorComponent } from './vendor.component';
import { VendorSummaryComponent } from './vendor-summary/vendor-summary.component';
import { SearchSolutionComponent } from '../base/search/search-solution/search-solution.component';
import { SearchComponent } from '../base/search/search.component';

const routes: Routes = [
  {
    path: '',
    component: VendorComponent,
    children: [
      {
        path: 'public-calls',
        component: CallListComponent,
        canActivate: [VendorRoleGuard]
      },
      {
        path: 'solution/:id/add',
        component: AddSolutionComponent,
        canActivate: [VendorRoleGuard]
      },
      {
        path: 'previous-solution/:solutionid/:previousstageid',
        component: VendorSummaryComponent,
        canActivate: [VendorRoleGuard]
      },
      {
        path: 'solution/:solutionid/:stageid/:previousstageid',
        component: FormComponent,
        canActivate: [VendorRoleGuard]
      },
      {
        path: 'solution/:solutionid/:stageid',
        component: FormComponent,
        canActivate: [VendorRoleGuard]
      },
      {
        path: 'curated-solution',
        component: SearchComponent,
        canActivate: [VendorRoleGuard]
      },
      {
        path: 'curated-solution/:solutionId',
        component: SearchSolutionComponent,
        canActivate: [VendorRoleGuard]
      },
      {
        path: '**',
        redirectTo: 'public-calls',
        pathMatch: 'full'
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class VendorRoutingModule {}
