import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { LoginComponent } from './login/login.component'
import { SignupComponent } from './signup/signup.component'
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component'
import { UserChangePasswordComponent } from './user-change-password/user-change-password.component'
import { UserProfileComponent } from './user-profile/user-profile.component'
import { ReactiveFormsModule } from '@angular/forms'
import { HttpClientModule } from '@angular/common/http'
import { SharedModule } from '../shared/shared.module'
import { VerifyAccountComponent } from './verify-account/verify-account.component'
import { SecurityComponent } from './security.component'
import { SecurityRoutingModule } from './security-routing.module'
import { TwoFactorAuthComponent } from './two-factor-auth/two-factor-auth.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
@NgModule({
  declarations: [
    LoginComponent,
    SignupComponent,
    ForgotPasswordComponent,
    UserChangePasswordComponent,
    UserProfileComponent,
    VerifyAccountComponent,
    SecurityComponent,
    TwoFactorAuthComponent,
    ResetPasswordComponent
  ],
  imports: [CommonModule, ReactiveFormsModule, HttpClientModule, SharedModule, SecurityRoutingModule]
})
export class SecurityModule {}
