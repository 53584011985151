<div class="mb-36">
  <div *ngIf="noteGroup['controls'][question.id] && !checkIfControlDisabled(question.id) && !isNoteHidden(question.id)" class="comment-container mb-5 d-flex">
    <textarea
      [id]="'noteTextarea' + question.id.replaceAll('.', '')"
      class="form-control text-area-control"
      [formControl]="getCommonControl(noteGroup, question.id)"
      placeholder="Maximum 500 characters"
      rows="2"
      maxlength="500"
    ></textarea>
    <button
      [class.disabled]="noteGroup['controls'][question.id].value.length === 0"
      [disabled]="noteGroup['controls'][question.id].value.length === 0"
      (click)="submitNote(question.id); $event.preventDefault()"
      class="btn submit-comment-btn"
    >
      <em class="bi bi-send"></em><span>{{ 'shared.submit' | translate }}</span>
    </button>
  </div>
  <div *ngIf="checkIfControlDisabled(question.id) && !isNoteHidden(question.id) && deletedNoteQuesId !== question.id" class="comment-container">
    <div>
      <label>Notes:</label>
      <p *ngIf="noteGroup['controls'][question.id]?.value" class="note-value">
        {{ noteGroup['controls'][question.id].value }}
      </p>
    </div>
    <div class="cmt-edit-delete-btn">
      <a class="cursor-pointer edit-button" (click)="onEdit(question.id)" *ngIf="!_isFormSubmitted">
        <img src="assets/images/edit-comment.png" alt="edit-comment" class="img-static" />
      </a>
      <a class="cursor-pointer delete-button" (click)="onDelete(question.id)" *ngIf="!_isFormSubmitted">
        <img src="assets/images/delete-comment.png" alt="delete-comment" class="img-static" />
      </a>
    </div>
  </div>
</div>
