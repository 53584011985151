import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BehaviorSubject, Observable, lastValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  showAlert: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  closeAlert = new BehaviorSubject(null);
  isSubmitted = new BehaviorSubject(false);
  showLoader = new BehaviorSubject(false);
  onHomeClicked = new EventEmitter();
  loaderTimer = 0;

  constructor(private readonly http: HttpClient) {}

  showDynamicAlert(lang_msg = 'admin.something_went_wrong', status = 'danger', autoFade = true, dynamicValues?: object) {
    const loaderData = {
      options: {
        type: status,
        autoFade,
        lang_msg,
        dynamicValues
      }
    };
    this.showAlert.next(loaderData);
  }

  showDisplayLoader() {
    this.showLoader.next(true);
    this.loaderTimer++;
    setTimeout(() => {
      if (this.loaderTimer > 1) {
        this.loaderTimer--;
      }
    }, 300);
  }

  hideDisplayLoader() {
    setTimeout(() => {
      if (this.loaderTimer <= 1) {
        this.showLoader.next(false);
      }
    }, 300);
  }

  hasError(formGroup: FormGroup, questionId: string) {
    return this.isSubmitted.getValue() && formGroup.controls[questionId]?.status === 'INVALID' && formGroup.controls[questionId].touched;
  }

  isUUID(data: string) {
    const pattern = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
    return pattern.test(data);
  }

  async openAttachment(attachmentUrl: string, attachmentId: string) {
    if (attachmentUrl) {
      window.open(attachmentUrl, '_blank');
    } else {
      const result = await lastValueFrom(this.getSolutionAttachmentUrl(attachmentId));
      window.open(result.url, '_blank');
    }
  }

  getSolutionAttachmentUrl(attachmentId: string): Observable<any> {
    return this.http.get(`${environment.baseUrl}/solution/attachment/url`, { params: { attachmentId } });
  }

  getNotification(): Observable<any> {
    return this.http.get(`${environment.baseUrl}/user/notification`);
  }

  deleteAllNotification(fetchedOn: any) {
    const options = {
      headers: new HttpHeaders({
        'Content-': 'application/json'
      }),
      body: {
        fetchedOn
      }
    };
    return this.http.delete(`${environment.baseUrl}/user/notification`, options);
  }

  getPublishdSolutions(paginationOptions: any): Observable<any> {
    return this.http.get(`${environment.baseUrl}/solution/publish-solution`, {
      params: {
        searchTerm: paginationOptions.searchTerm,
        page: paginationOptions.page,
        pageSize: paginationOptions.pageSize,
        orderColumn: paginationOptions.orderColumn,
        orderByAsc: paginationOptions.orderByAsc,
        isPublished: true
      }
    });
  }

  searchSolution(searchDetails: any): Observable<any> {
    const baseUrl = `${environment.baseUrl}/solution/search`;
    let params = new HttpParams().set('page', searchDetails.page.toString()).set('pageSize', searchDetails.pageSize.toString());

    if (searchDetails.query) {
      params = params.append('query', searchDetails.query);
    }

    if (searchDetails.callId) {
      searchDetails.callId.forEach((callId: string) => {
        params = params.append('callId[]', callId);
      });
    }

    if (searchDetails.system) {
      searchDetails.system.forEach((system: string) => {
        params = params.append('system[]', system);
      });
    }

    if (searchDetails.healthArchitecture) {
      searchDetails.healthArchitecture.forEach((healthArch: string) => {
        params = params.append('healthArchitecture[]', healthArch);
      });
    }

    if (searchDetails.registeredCountry) {
      searchDetails.registeredCountry.forEach((country: string) => {
        params = params.append('countryRegisteredIn[]', country);
      });
    }

    if (searchDetails.supportedCountry) {
      searchDetails.supportedCountry.forEach((country: string) => {
        params = params.append('countryForSolutionSupport[]', country);
      });
    }

    if (searchDetails.rating) {
      params = params.append('rating', searchDetails.rating);
    }

    if (searchDetails.isFilter) {
      params = params.append('isFilter', true);
    }

    return this.http.get(baseUrl, { params: params });
  }

  setAnswerByType(apiData: any, ans: any, que: any, options: any, { cat, type }: any, countries: any, questionList: any) {
    // if country type then based on uuid fetch name of country
    if (apiData === 'countries' && ans) {
      que['answer'] = ans.map((a: string) => countries.find((country: any) => country.id === a)?.label);
    } else {
      // check if uuid then fetch lebal from option otherwise only set string data
      switch (type) {
        case 'radio':
        case 'single-select':
          que['answer'] = this.getAnswerFromId(ans, options);
          break;
        case 'multi-select':
          if (ans && Array.isArray(ans)) {
            que['answer'] = ans.map((a: string) => this.getAnswerFromId(a, options));
          }
          break;
        default:
          que['answer'] = ans;
          break;
      }
    }

    // push category with question for use in sidebar and scrolling higlight
    if (que.answer?.length) {
      questionList.push({ cat: this.getId(cat.id), que });
    }
  }

  getId(id: string) {
    return id.replaceAll('.', '');
  }

  getAnswerFromId(ans: any, options: any) {
    return this.isUUID(ans) ? options.find((opt: any) => opt.id === ans)?.label : ans;
  }

  getAllCalls(): Observable<any> {
    return this.http.get(`${environment.baseUrl}/public-call/all`);
  }

  getCalls(callDetails: any): Observable<any> {
    return this.http.get(`${environment.baseUrl}/public-call`, { params: callDetails });
  }

  getCallsWithPendingReviews(callDetails: any): Observable<any> {
    return this.http.get(`${environment.baseUrl}/public-call/with-pending-review`, { params: callDetails });
  }

  getScores(solutionId: string): Observable<any> {
    return this.http.get(`${environment.baseUrl}/solution/scores`, { params: { solutionId } });
  }

  getPanelDetails(solutionId: string): Observable<any> {
    return this.http.get(`${environment.baseUrl}/solution/panelDetails`, { params: { solutionId } });
  }

  savePublishSolution(solutionId: string, comment: any[], solutionOverview: any[]): Observable<any> {
    return this.http.post(`${environment.baseUrl}/solution/publish-solution`, { solutionId, comment, solutionOverview });
  }
}
