import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { SecurityService } from '../security.service';
import { Router } from '@angular/router';
import { SecuritySubscriberService } from '../security-subscriber.service';
import { lastValueFrom } from 'rxjs';
import { SharedService } from 'src/app/shared/services/shared.service';
import { Buffer } from 'buffer';
import { User } from 'src/app/interfaces/user.interface';

@Component({
  selector: 'app-user-change-password',
  templateUrl: './user-change-password.component.html',
  styleUrls: ['./user-change-password.component.scss']
})
export class UserChangePasswordComponent implements OnInit {
  changePasswordForm;
  isError = false;
  userDetails!: User;
  displayMessage = '';
  passwordUpdatedSuccessfully = false;

  constructor(
    private readonly sharedService: SharedService,
    private readonly formBuilder: FormBuilder,
    readonly securityService: SecurityService,
    private readonly router: Router,
    private readonly securitySubscriberService: SecuritySubscriberService
  ) {
    this.changePasswordForm = this.formBuilder.group(
      {
        oldPassword: ['', [Validators.required]],
        password: ['', [Validators.required, Validators.minLength(8), Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).+$/)]],
        confirmPassword: ['', Validators.required]
      },
      { validators: this.checkPasswords }
    );
    this.changePasswordForm.valueChanges.subscribe(() => {
      this.isError = false;
      this.passwordUpdatedSuccessfully = false;
    });
  }

  ngOnInit(): void {
    this.securitySubscriberService.getCurrentUser.subscribe((user) => {
      this.userDetails = user;
    });
  }

  checkPasswords: ValidatorFn = (group: AbstractControl): ValidationErrors | null => {
    const pass = group?.get('password')?.value;
    const confirmPass = group?.get('confirmPassword')?.value;
    return pass === confirmPass ? null : { notSame: true };
  };

  async onUpdate() {
    this.displayMessage = '';
    this.isError = false;
    if (this.changePasswordForm.value.oldPassword === this.changePasswordForm.value.password) {
      this.isError = true;
      this.displayMessage = 'change_password.same_password_alert';
      return;
    }
    try {
      const userDetails = {
        oldPassword: Buffer.from(this.changePasswordForm.value.oldPassword || '', 'utf-8').toString('base64'),
        newPassword: Buffer.from(this.changePasswordForm.value.password || '', 'utf-8').toString('base64')
      };
      const response = await lastValueFrom(this.securityService.changePassword(userDetails));
      this.passwordUpdatedSuccessfully = true;
      this.changePasswordForm.reset();
      this.securitySubscriberService.isLoggedIn.next(false);
      this.securitySubscriberService.updateCurrentUserDetail(this.userDetails);

      this.displayMessage = response.lang_msg || response.message;
      try {
        await lastValueFrom(this.securityService.logoutUser());
        this.securitySubscriberService.isLoggedIn.next(false);
        this.securitySubscriberService.clearCurrentUserDetail();
        this.router.navigate(['/login']);
      } catch (error: any) {
        this.sharedService.showDynamicAlert();
      }

      this.sharedService.showDynamicAlert(this.displayMessage, 'success');
    } catch (error: any) {
      if (error?.error?.message) {
        this.isError = true;
        this.displayMessage = error.error.message;
        this.sharedService.showDynamicAlert(this.displayMessage);
        return;
      }
      this.sharedService.showDynamicAlert('admin.contact_admin');
    }
  }

  navigatToDashboard() {
    this.securityService.navigateUser(this.userDetails.role);
  }
}
