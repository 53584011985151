import { Component, OnDestroy } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Buffer } from 'buffer';
import { lastValueFrom, Subscription } from 'rxjs';
import { SharedService } from 'src/app/shared/services/shared.service';
import { SecurityService } from '../security.service';
import { ReCaptchaV3Service } from 'ng-recaptcha';
@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnDestroy {
  signupForm: FormGroup;
  isError: string = '';
  isUserCreated: boolean = false;
  roleItems: any;
  private subscription!: Subscription;
  constructor(
    private readonly sharedService: SharedService,
    private readonly formBuilder: FormBuilder,
    private readonly securityService: SecurityService,
    private readonly router: Router,
    private recaptchaV3Service: ReCaptchaV3Service
  ) {
    this.roleItems = [
      { id: 'vendor', label: 'Vendor' },
      { id: 'reviewer', label: 'Reviewer' },
      { id: 'whopanel', label: 'Who Panel' }
    ];

    this.signupForm = this.formBuilder.group(
      {
        email: ['', [Validators.required, Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$')]],
        companyName: ['', [Validators.required]],
        password: ['', [Validators.required, Validators.minLength(8), Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).+$/)]],
        confirmPassword: ['', Validators.required],
        role: ['vendor', Validators.required],
        termsAndConditions: [false, Validators.requiredTrue]
      },
      { validators: this.checkPasswords }
    );
  }

  roleChange(role: string) {
    this.signupForm.controls['role'].setValue(role);
  }

  onSubmit() {
    this.isUserCreated = false;
    const userDetails = this.signupForm.value;
    userDetails.password = Buffer.from(this.signupForm.value.password || '', 'utf-8').toString('base64');
    this.subscription = this.recaptchaV3Service.execute('submit').subscribe((token) => {
      if (token) {
        (async () => {
          await lastValueFrom(this.securityService.verifyReCaptcha(token));

          try {
            await lastValueFrom(this.securityService.createNewUser(userDetails));
            this.isUserCreated = true;
            this.sharedService.showDynamicAlert('admin.user_created', 'success');
          } catch (error: any) {
            this.isError = error?.error?.message || 'admin.contact_admin';
            this.sharedService.showDynamicAlert(this.isError);
          }
        })().catch((error) => {
          this.sharedService.showDynamicAlert(error.error.message);
        });
      } else {
        this.sharedService.showDynamicAlert('Something went wrong');
      }
    });
  }

  checkPasswords: ValidatorFn = (group: AbstractControl): ValidationErrors | null => {
    const pass = group?.get('password')?.value;
    const confirmPass = group?.get('confirmPassword')?.value;
    return pass === confirmPass ? null : { notSame: true };
  };

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
}
