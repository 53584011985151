<div class="form_wrapper">
  <div class="form_main_content pre_submission_main">
    <div class="pre_submission_box">
      <div *ngFor="let question of questions">
        <div class="mb-36" [ngSwitch]="question.type">
          <app-input *ngSwitchCase="'text'" [question]="question" [formGroup]="preSubmissionForm" [isPreSubmission]="true"></app-input>
          <app-radio *ngSwitchCase="'radio'" [question]="question" [formGroup]="preSubmissionForm" [isPreSubmission]="true"></app-radio>
          <app-select *ngSwitchCase="'single-select'" [question]="question" [formGroup]="preSubmissionForm" [isPreSubmission]="true"></app-select>
          <app-radio-with-dropdown
            *ngSwitchCase="'radio-with-dropdown'"
            [question]="question"
            [formGroup]="preSubmissionForm"
            [options]="previousApplicationOptions"
            [isPreSubmission]="true"
          ></app-radio-with-dropdown>
        </div>
      </div>

      <div class="col-12 text-center">
        <button type="button" class="btn presubmission_btn" [ngClass]="{ disabled: formInvalid || inProcess }" [disabled]="formInvalid || inProcess" (click)="onSubmit()">
          {{ 'vendor.pre_submission.start_assessment' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
