import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FormService } from 'src/app/vendor/form/form.service';
import { Question } from '../../models/question.model';
import { SharedService } from '../../services/shared.service';
@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss']
})
export class SelectComponent implements OnInit {
  @Input() question!: Question;
  @Input() formGroup!: FormGroup;
  @Input() answerData: any;
  @Input() isPreSubmission = false;
  selectControl!: FormControl;
  dependsOn = false;
  questionOptions!: any;
  _isFormSubmitted = false;

  @Input()
  set isFormSubmitted(formSubmitted: boolean) {
    if (formSubmitted) {
      this._isFormSubmitted = formSubmitted;
      this.selectControl?.disable();
    }
  }
  constructor(private formService: FormService, private sharedService: SharedService) {}

  ngOnInit(): void {
    const validators = this.question.isRequired ? [Validators.required] : [];
    this.selectControl = new FormControl(this.answerData || '', validators);

    this.formGroup.addControl(this.question.id, this.selectControl);

    if (this.question.dependsOn) {
      this.dependsOn = true;
      this.formGroup.controls[this.question.dependsOn].valueChanges.subscribe((value) => {
        this.questionOptions = this.question.options[value];
        this.selectControl.setValue('');
      });
      return;
    }
    this.questionOptions = this.question.options;

    this.formService.skipLogic(this.question.skipLogic, this.formGroup, this.question.id, this.question.isRequired, this.isPreSubmission);

    if (this._isFormSubmitted) {
      this.selectControl?.disable();
    }
  }

  hasError() {
    return this.sharedService.hasError(this.formGroup, this.question.id);
  }

  skipQuestion(): boolean {
    return this.formService.question(this.formGroup, this.question.id)['skipQuestion'];
  }
}
