import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-ng-select-custom',
  templateUrl: './ng-select-custom.component.html',
  styleUrls: ['./ng-select-custom.component.scss']
})
export class NgSelectCustomComponent {
  @Input() isMultiple: boolean = false;
  @Input() isClearable: boolean = false;
  @Input() isPlaceholder: boolean = false;
  @Input() placeholderLabel: string = '--Select--';
  @Input() isSearchable: boolean = false;
  @Input() isInvalid: boolean = false;
  @Input() isDisabled: boolean = false;
  @Input() items: any;
  @Input() selectedItems: any;
  @Output() selectedItemChange: any = new EventEmitter<any>();

  onOpen() {
    setTimeout(() => {
      const scrollContainer = document.querySelector('.ng-dropdown-panel-items');
      if (scrollContainer) {
        scrollContainer.scrollTop = 0;
      }
    }, 0);
  }

  onChange() {
    this.selectedItemChange.emit(this.selectedItems);
  }
}
