<div class="accordion-body">
  <div [ngClass]="{ 'mb-66': i === 0 }" *ngFor="let subcat of subcats; index as i">
    <div>
      <app-sub-category
        [subcat]="subcat"
        [formGroup]="formGroup"
        [answerData]="answerData"
        [isFormSubmitted]="isFormSubmitted"
        [noteGroup]="noteGroup"
        [attachments]="attachments"
        [stepId]="stepId"
        (getAttachments)="getAttachmentsHere()"
      ></app-sub-category>
    </div>
  </div>
</div>
