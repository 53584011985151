import { Injectable } from '@angular/core';
import { BehaviorSubject, lastValueFrom } from 'rxjs';
import { SecurityService } from './security.service';
import { User } from '../interfaces/user.interface';

@Injectable({
  providedIn: 'root'
})
export class SecuritySubscriberService {
  userDetails!: User;
  emptyUser!: User;
  isLoggedIn = new BehaviorSubject(false);
  getCurrentUser = new BehaviorSubject<User>(this.userDetails);
  isFormActive = new BehaviorSubject('');

  constructor(private readonly securityService: SecurityService) {}

  /**
   * updates current user details
   * @param user User details to update
   */
  updateCurrentUserDetail(user: User) {
    this.getCurrentUser.next(user);
  }

  clearCurrentUserDetail() {
    this.getCurrentUser.next(this.emptyUser);
  }

  /**
   * get local user
   * @returns User details
   */
  async getLocalUser() {
    try {
      const response = await lastValueFrom(this.securityService.getCurrentUser());
      this.updateCurrentUserDetail(response.details);
      this.isLoggedIn.next(true);
      return response.details;
    } catch (error) {
      return {};
    }
  }
}
