import { HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http'
import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { NgxPaginationModule } from 'ngx-pagination'
import { SimpleModalModule } from 'ngx-simple-modal'
import { TranslateLoader, TranslateModule } from '@ngx-translate/core'
import { TranslateHttpLoader } from '@ngx-translate/http-loader'
import { AdminModule } from './admin/admin.module'
import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { Interceptor } from './security/interceptors/interceptor'
import { SecurityModule } from './security/security.module'
import { SharedModule } from './shared/shared.module'
import { VendorModule } from './vendor/vendor.module'
import { BaseModule } from './base/base.module'
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';
import { environment } from 'src/environments/environment';

export function HttpLoaderFactory (http: HttpClient) {
  return new TranslateHttpLoader(http, `${environment.baseUrl}/`, '.json')
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SharedModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    SecurityModule,
    AdminModule,
    VendorModule,
    FormsModule,
    NgxPaginationModule,
    BaseModule,
    SimpleModalModule.forRoot({ container: 'modal-container' }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
      defaultLanguage: 'en'
    }),
    RecaptchaV3Module
  ],
  exports: [],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: Interceptor,
      multi: true
    },
    { provide: RECAPTCHA_V3_SITE_KEY, useValue: environment.recaptcha_site_key }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
