import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SecurityComponent } from './security.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { LoginComponent } from './login/login.component';
import { SignupComponent } from './signup/signup.component';
import { VerifyAccountComponent } from './verify-account/verify-account.component';
import { LoginGuard } from './guard/auth.guard';
import { WelcomeComponent } from '../base/welcome/welcome.component';
import { TwoFactorAuthComponent } from './two-factor-auth/two-factor-auth.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
const routes: Routes = [
  {
    path: '',
    component: SecurityComponent,
    children: [
      { path: '', redirectTo: 'welcome', pathMatch: 'full' },
      { path: 'welcome', component: WelcomeComponent },
      { path: 'forgot-password', component: ForgotPasswordComponent, canActivate: [LoginGuard] },
      { path: 'reset-password/:token', component: ResetPasswordComponent, canActivate: [LoginGuard] },
      { path: 'login', component: LoginComponent, canActivate: [LoginGuard] },
      { path: 'signup', component: SignupComponent, canActivate: [LoginGuard] },
      { path: 'two-factor-auth/:encodedEmail', component: TwoFactorAuthComponent, canActivate: [LoginGuard] },
      { path: 'verify-account', component: VerifyAccountComponent, canActivate: [LoginGuard] }
    ]
  },
  {
    path: '**',
    redirectTo: '/welcome',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SecurityRoutingModule {}
