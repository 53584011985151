<div class="prelogin_rightside">
  <img routerLink="/welcome" class="logo cursor-pointer img-static" src="assets/images/logo_black.svg" alt="logo_black" />
  <div class="prelogin_form_container">
  <div *ngIf="!isResetPassword" class="prelogin_form_container">
    <h1 class="center-header">{{ 'reset_password.title' | translate | uppercase }}</h1>
    <p class="center-header">{{ 'reset_password.sub_text' | translate }}</p>
    <div *ngIf="isError" class="alert alert-danger cst-alert d-flex" role="alert"><em class="pe-2 bi bi-exclamation-triangle"></em> {{ displayMessage | translate }}</div>

    <div class="form-group position-relative"></div>
    <form class="cst_form-container mt-4" [formGroup]="resetPasswordForm" (ngSubmit)="onUpdate()">
      <div class="mb-4 cst_form me-lg-5">
        <label for="newPassword" class="form-label">{{ 'change_password.new_password' | translate }}</label>
        <input
          type="password"
          id="newPassword"
          class="form-control"
          formControlName="password"
          [class.invalid]="resetPasswordForm.get('password')?.invalid && resetPasswordForm.get('password')?.touched && !resetPasswordForm.get('password')?.value"
        />
        <div *ngIf="resetPasswordForm.get('password')?.touched && resetPasswordForm.get('password')?.invalid">
          <div *ngIf="resetPasswordForm.get('password')?.hasError('required')">
            <span class="invalid_feedback">{{ 'alert.password_required' | translate }} </span>
          </div>
          <div *ngIf="resetPasswordForm.get('password')?.hasError('minlength') || resetPasswordForm.get('password')?.hasError('pattern')">
            <span class="invalid_feedback">{{ 'alert.password_invalid' | translate }}</span>
          </div>
        </div>
      </div>
      <div class="mb-4 cst_form me-lg-5">
        <label for="confirmPassword" class="form-label">{{ 'shared.confirm_password' | translate }}</label>
        <input
          type="password"
          id="confirmPassword"
          class="form-control"
          formControlName="confirmPassword"
          [class.invalid]="
            resetPasswordForm.get('confirmPassword')?.invalid && resetPasswordForm.get('confirmPassword')?.touched && !resetPasswordForm.get('confirmPassword')?.value
          "
        />
        <div *ngIf="resetPasswordForm.get('confirmPassword')?.touched && resetPasswordForm.get('confirmPassword')?.invalid">
          <div *ngIf="resetPasswordForm.get('confirmPassword')?.hasError('required')">
            <span class="invalid_feedback">{{ 'alert.confirm_password_required' | translate }}</span>
          </div>
        </div>
        <div *ngIf="resetPasswordForm.get('confirmPassword')?.touched && resetPasswordForm.hasError('notSame') && !resetPasswordForm.get('confirmPassword')?.hasError('required')">
          <span class="invalid_feedback">{{ 'alert.password_mismatch' | translate }}</span>
        </div>
      </div>

      <div *ngIf="passwordUpdatedSuccessfully">
        <span class="success_message">{{ displayMessage }}</span>
      </div>
      <div class="text-center mt-5">
        <button
          [disabled]="resetPasswordForm.invalid || isError || inProcess"
          class="btn prelogin_btn"
          [ngClass]="{
            disabled: resetPasswordForm.invalid || isError || inProcess
          }"
        >
          {{ 'shared.submit' | translate | titlecase }}
        </button>
      </div>
    </form>
  </div>

  <div *ngIf="isResetPassword" class="success-msg">
    <img src="assets/images/success-checked.png" alt="success-checked" class="img-static" />
    <h1>{{ 'reset_password.thank_you_message' | translate }}</h1>

    <p>{{ 'reset_password.acknowledgement_message' | translate }}</p>
    <button routerLink="/login" type="button" class="btn prelogin_btn">{{ 'shared.return_to_login' | translate }}</button>
  </div>
</div>