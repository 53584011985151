<div class="cst_sidebar" [ngClass]="{ 'hideSidebar transition-enable': isSidebarToggle }">
  <div class="sidebar_menu">
    <a (click)="toggleSidebar()" class="d-flex align-items-center mb-md-0 me-md-auto link-body-emphasis text-decoration-none">
      <img src="assets/images/menu.svg" alt="menu" class="img-static" />
    </a>
  </div>
  <a class="justify-content-center mb-4 d-flex align-items-center text-decoration-none cursor-pointer" (click)="goToHome()">
    <img src="assets/images/logo_white.png" alt="logo_white" class="img-static" />
  </a>
  <div>
    <div class="hr_border1"></div>
    <div class="hr_border2"></div>
  </div>
  <ul class="nav nav-pills mt-4 flex-column mb-auto">
    <li
      class="nav-item cursor-pointer"
      [ngClass]="{ active: isVendorDashboardActive() || isReviewerDashboardActive() || isWhoPanelDashboardActive() }"
      *ngIf="userDetails['role'] === 'vendor' || userDetails['role'] === 'reviewer' || userDetails['role'] === 'whopanel'"
      (click)="navigateToDashboard()"
    >
      <a class="nav-link" aria-current="page">
        <img class="pe-3 img-static" src="assets/images/dashboard.svg" alt="dashboard" />
        Dashboard
      </a>
    </li>

    <li
      class="nav-item cursor-pointer"
      [ngClass]="{ active: isAssignReqActive() }"
      *ngIf="userDetails['role'] === 'reviewer'"
      (click)="navigateToPageWithParams('assignment-request')"
    >
      <a class="nav-link" aria-current="page">
        <em class="pe-3 img-static bi bi-person-add"></em>
        Assignment Request
      </a>
    </li>

    <li
      class="nav-item cursor-pointer"
      [ngClass]="{ active: isPublishedSolutionActive() }"
      *ngIf="userDetails['role'] !== 'admin'"
      (click)="navigateToPageWithParams('curated-solution')"
    >
      <a class="nav-link" (click)="navigateToPage('curated-solution')">
        <em class="bi bi-journals pe-3"></em>
        {{ 'sidebar.published_solution' | translate }}
      </a>
    </li>

    <li class="nav-item" *ngIf="userDetails['role'] === 'admin'">
      <a
        (click)="isUserOpen ? (isUserOpen = false) : (isUserOpen = true)"
        class="nav-link btn-toggle align-items-center rounded cursor-pointer"
        data-bs-toggle="collapse"
        data-bs-target="#user-collapse"
        aria-expanded="true"
      >
        <img class="pe-3 img-static" src="assets/images/users.svg" alt="users" />
        {{ 'sidebar.users' | translate }}
        <em [ngClass]="isUserOpen ? 'bi-chevron-down' : 'bi-chevron-right'" class="bi icon-spacing"></em>
      </a>
      <div class="collapse show" id="user-collapse">
        <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
          <li class="nav-item cursor-pointer" [ngClass]="{ active: isUserListActive() }">
            <a class="submenu_link active navigation-button" (click)="navigateToPage('users-list')">
              <em class="bi bi-people pe-3"></em>
              {{ 'sidebar.all_users' | translate }}</a
            >
          </li>
          <li class="nav-item cursor-pointer" [ngClass]="{ active: isUserRegActive() }">
            <a class="submenu_link navigation-button" (click)="navigateToPage('registration-request')">
              <em class="bi bi-person-check pe-3"></em> {{ 'sidebar.registration_request' | translate }}</a
            >
          </li>
        </ul>
      </div>
    </li>

    <li class="nav-item" *ngIf="userDetails['role'] === 'admin'">
      <a
        (click)="isSolutionOpen ? (isSolutionOpen = false) : (isSolutionOpen = true)"
        class="nav-link btn-toggle align-items-center rounded cursor-pointer"
        data-bs-toggle="collapse"
        data-bs-target="#solution-collapse"
        aria-expanded="true"
      >
        <img class="pe-3 img-static" src="assets/images/users.svg" alt="solution" />
        {{ 'sidebar.solution' | translate }}
        <em [ngClass]="isSolutionOpen ? 'bi-chevron-down' : 'bi-chevron-right'" class="bi icon-spacing"></em>
      </a>
      <div class="collapse show" id="solution-collapse">
        <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
          <li class="nav-item cursor-pointer" [ngClass]="{ active: isAssignReqActive() }">
            <a class="submenu_link navigation-button" (click)="navigateToPage('assignment-request')">
              <em class="bi bi-person pe-3"></em> {{ 'sidebar.assign_reviewer' | translate }}</a
            >
          </li>
          <li class="nav-item cursor-pointer" [ngClass]="{ active: isPublishSolutionReqActive() }">
            <a class="submenu_link navigation-button" (click)="navigateToPage('publish-solution-request')">
              <em class="bi bi-journals pe-3"></em> {{ 'sidebar.publish_solution' | translate }}</a
            >
          </li>
          <li class="nav-item cursor-pointer" [ngClass]="{ active: isPublishedSolutionActive() }">
            <a class="submenu_link navigation-button" (click)="navigateToPage('curated-solution')">
              <em class="bi bi-journals pe-3"></em> {{ 'sidebar.published_solution' | translate }}</a
            >
          </li>
        </ul>
      </div>
    </li>
  </ul>
</div>
