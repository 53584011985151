import { Component, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription, lastValueFrom } from 'rxjs';
import { SharedService } from 'src/app/shared/services/shared.service';
import { SecurityService } from '../security.service';
import { ReCaptchaV3Service } from 'ng-recaptcha';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnDestroy {
  forgotPasswordForm: FormGroup;
  showForgotPasswordError = '';
  inProcess: boolean = false;
  isForgotPassword = false;
  roleItems!: any;

  private subscription!: Subscription | undefined;

  constructor(
    private readonly sharedService: SharedService,
    private readonly formBuilder: FormBuilder,
    private readonly securityService: SecurityService,
    private readonly router: Router,
    private recaptchaV3Service: ReCaptchaV3Service
  ) {
    this.forgotPasswordForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$')]],
      role: ['vendor', Validators.required]
    });
    this.roleItems = [
      { id: 'vendor', label: 'Vendor' },
      { id: 'reviewer', label: 'Reviewer' },
      { id: 'whopanel', label: 'Who Panel' },
      { id: 'admin', label: 'Admin' }
    ];
  }

  roleChange(role: string) {
    this.forgotPasswordForm.controls['role'].setValue(role);
  }

  onSubmit() {
    this.inProcess = true;
    this.isForgotPassword = false;
    this.showForgotPasswordError = '';
    this.subscription = this.recaptchaV3Service.execute('submit').subscribe((token) => {
      if (token) {
        (async () => {
          await lastValueFrom(this.securityService.verifyReCaptcha(token));

          try {
            await lastValueFrom(this.securityService.forgotPassword(this.forgotPasswordForm.value.email, this.forgotPasswordForm.value.role));
            this.forgotPasswordForm.reset();
          } catch (error: any) {
            this.sharedService.showDynamicAlert(error.error.message);
          } finally {
            this.inProcess = false;
            this.isForgotPassword = true;
          }
        })().catch((error) => {
          this.sharedService.showDynamicAlert(error.error.message);
        });
      } else {
        this.sharedService.showDynamicAlert('Something went wrong');
      }
    });
  }

  goToLogin() {
    this.router.navigate(['/login']);
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
}
