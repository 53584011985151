import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { SharedService } from '../shared/services/shared.service';
import { VendorService } from './vendor.service';
import { SecuritySubscriberService } from '../security/security-subscriber.service';

@Component({
  selector: 'app-base',
  templateUrl: './vendor.component.html'
})
export class VendorComponent implements OnInit, OnDestroy {
  isSidebarToggle: any;
  isFormActiveSubscription: Subscription | undefined;
  isFormActive: any = '';
  removetransition: boolean = false;

  constructor(
    private readonly sharedService: SharedService,
    private readonly vendorService: VendorService,
    private readonly cdr: ChangeDetectorRef,
    private securitySubscriberService: SecuritySubscriberService
  ) {}

  ngOnInit(): void {
    this.isFormActive = '';
    this.securitySubscriberService.isFormActive.next('');
    this.isFormActiveSubscription = this.vendorService.isFormActive$.subscribe((value) => {
      this.isFormActive = value;
      this.securitySubscriberService.isFormActive.next(value);
      this.cdr.detectChanges();
    });
  }

  ngOnDestroy() {
    this.isFormActiveSubscription?.unsubscribe();
  }
}
