<div class="modal-backdrop" (click)="cancel()"></div>
<div class="modal modal-dialog modal-dialog-centered" fade>
  <div class="confirm-modal modal-content">
    <div class="modal-header">
      <h5 class="modal-title" id="confirm-modal"><em style="font-size: 18px" class="pe-2 bi bi-exclamation-triangle"></em>{{ title | translate }}</h5>
    </div>
    <div class="modal-body" [innerHTML]="lang_msg | translate : { email }"></div>
    <div class="modal-footer mt-5">
      <button (click)="cancel()" type="button" class="btn outline_btn cancel_hover" data-bs-dismiss="modal">{{ 'shared.cancel' | translate }}</button>
      <button (click)="confirm()" type="button" class="btn cst_btn" *ngIf="lang_msg === 'admin.accept_user_confirm'">
        {{ 'shared.approve' | translate }}
      </button>
      <button (click)="confirm()" type="button" class="btn cst_btn" *ngIf="lang_msg === 'admin.reject_user_confirm'">
        {{ 'shared.reject' | translate }}
      </button>
      <button (click)="confirm()" type="button" class="btn cst_btn" *ngIf="lang_msg !== 'admin.accept_user_confirm' && lang_msg !== 'admin.reject_user_confirm'">
        {{ 'shared.confirm' | translate }}
      </button>
    </div>
  </div>
</div>
