import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { SecuritySubscriberService } from 'src/app/security/security-subscriber.service';
import { VendorAuthGuard } from './vendor-auth.guard';

@Injectable({
  providedIn: 'root'
})
export class VendorRoleGuard implements CanActivate {
  constructor(private readonly vendorAuthGuard: VendorAuthGuard, private readonly securitySubscriberService: SecuritySubscriberService) {}
  /**
   * Checks if the admin is authorized
   * @param route object of activated route
   * @param state state of snapshot of route
   * @returns Boolean whether admin is authorized to access the route
   */
  async canActivate(): Promise<any> {
    if (!this.vendorAuthGuard.canActivate()) return false;

    const result = await this.securitySubscriberService.getLocalUser();
    if (!result?.role || result.role !== 'vendor') return false;

    return true;
  }
}
