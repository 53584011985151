import { AfterContentChecked, AfterViewInit, ChangeDetectorRef, Component, ElementRef, Injector, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { WhoPanelService } from '../../who-panel/who-panel.service';
import { ViewportScroller } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { SimpleModalService } from 'ngx-simple-modal';
import { lastValueFrom } from 'rxjs';
import SignaturePad from 'signature_pad';
import { SecurityService } from 'src/app/security/security.service';
import { SharedService } from 'src/app/shared/services/shared.service';
import { URLS } from 'src/app/shared/urls';
import { FormService } from 'src/app/vendor/form/form.service';
import { VendorService } from '../vendor.service';

@Component({
  selector: 'app-vendor-summary',
  templateUrl: './vendor-summary.component.html',
  styleUrls: ['./vendor-summary.component.scss']
})
export class VendorSummaryComponent implements OnInit, OnDestroy, AfterContentChecked, AfterViewInit {
  @ViewChild('signaturePadCanvas') signaturePadCanvas!: ElementRef;
  signaturePad!: SignaturePad;

  @Input() prevStageAnswerData: any = {};

  private whoPanelService: WhoPanelService;
  private vendorService: VendorService;
  private formService: FormService;
  private sharedService: SharedService;
  private viewPortScroller: ViewportScroller;
  private securityService: SecurityService;
  private activeRoute: ActivatedRoute;
  private router: Router;
  private simpleModalService: SimpleModalService;
  private cdr: ChangeDetectorRef;

  constructor(private injector: Injector) {
    this.whoPanelService = this.injector.get<WhoPanelService>(WhoPanelService);
    this.vendorService = this.injector.get<VendorService>(VendorService);
    this.formService = this.injector.get<FormService>(FormService);
    this.sharedService = this.injector.get<SharedService>(SharedService);
    this.viewPortScroller = this.injector.get<ViewportScroller>(ViewportScroller);
    this.securityService = this.injector.get<SecurityService>(SecurityService);
    this.activeRoute = this.injector.get<ActivatedRoute>(ActivatedRoute);
    this.router = this.injector.get<Router>(Router);
    this.simpleModalService = this.injector.get<SimpleModalService>(SimpleModalService);
    this.cdr = this.injector.get<ChangeDetectorRef>(ChangeDetectorRef);

    this.stageId = this.activeRoute.snapshot.params['previousstageid'];
    this.solutionId = this.activeRoute.snapshot.params['solutionid'];

    this.accordianObserver = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const ele: any = entry.target;

          if (entry.isIntersecting) {
            this.visibleSubList.push(ele.getAttribute('data-que'));
          } else {
            this.visibleSubList = this.visibleSubList.filter((vl) => vl != ele.getAttribute('data-que'));
          }
        });

        this.visibleSubList = this.sortArrayBasedOnOrder(this.visibleSubList, this.subCatList);
        this.selectedId = this.visibleSubList[0] ?? '';
      },
      { root: document.querySelector('#intersection_div'), threshold: 0, rootMargin: '-120px' }
    );
  }
  accordianObserver!: IntersectionObserver;
  visibleSubList: string[] = [];
  subCatList: string[] = [];
  stageId = '';
  solutionId = '';
  activeAccordion: string = '';
  questionData: any;
  answerData: any;
  countries: any = [];
  reviewerData: any = {};
  whoPanelCommentData: any;
  contentLoaded = false;
  questionList: any = [];
  selectedId: any;
  whoPanelNotes: any = {};
  assessmentData: any = {};
  isNextStageDisabled: boolean = false;
  pageLoaded: boolean = false;
  statusItems = [
    { id: 'Rejected', label: 'Rejected' },
    { id: 'Pending additional details', label: 'Pending additional details' },
    { id: 'Approved with conditions', label: 'Approved with conditions' },
    { id: 'Approved', label: 'Approved' }
  ];
  async ngOnInit() {
    await this.setQuestions();
    await this.getCountries();
    await this.getAssessmentResponse();
    await this.getComments();
    this.setAnswers();
    await this.getNotes();
    this.isNextStageDisabled ? this.vendorService.setToggleValue('Stage1 Preview') : this.vendorService.setToggleValue('Stage 2');
    this.pageLoaded = true;
    this.cdr.detectChanges();
  }
  sortArrayBasedOnOrder(array: string[], orderArray: string[]) {
    return array.sort((a, b) => {
      const indexA = orderArray.indexOf(a);
      const indexB = orderArray.indexOf(b);

      if (indexA === -1 && indexB === -1) {
        return 0; // Maintain relative order if both elements are not found in the order array
      } else if (indexA === -1) {
        return 1; // Move elements not found in the order array to the end of the sorted array
      } else if (indexB === -1) {
        return -1; // Move elements not found in the order array to the end of the sorted array
      }

      return indexA - indexB; // Sort based on the indices in the order array
    });
  }

  ngAfterContentChecked(): void {
    if (!this.contentLoaded) {
      const divs = document.querySelectorAll('.cst_accordian');
      if (divs.length) {
        this.contentLoaded = true;
      }

      const accDivs = document.querySelectorAll('.accordion-item');
      accDivs.forEach((div) => {
        this.accordianObserver.observe(div);
      });
    }
  }

  ngAfterViewInit(): void {
    const canvas = this.signaturePadCanvas.nativeElement;
    const context = canvas.getContext('2d');
    context.canvas.width = 350;
    context.canvas.height = 150;

    this.signaturePad = new SignaturePad(canvas);
    this.signaturePad.off();
  }

  ngOnDestroy(): void {
    this.simpleModalService.removeAll();
    this.accordianObserver.disconnect();
  }

  async setQuestions() {
    try {
      const data: any = await lastValueFrom(this.formService.getQuestions(this.stageId));
      this.questionData = data.questions;
      this.subCatList = this.questionData.reduce((acc: any, que: any) => {
        return acc.concat(que.config.map((subCat: any) => this.getId(subCat.id)));
      }, []);
      this.activeAccordion = this.getId(this.questionData[0]['config'][0].id);
      this.selectedId = this.getId(this.questionData[0]['config'][0].id);
    } catch (error) {
      this.router.navigate([URLS.reviewer.dashboard]);
      this.sharedService.showDynamicAlert('Something went wrong');
    }
  }
  async setAnswers() {
    try {
      if (!Object.entries(this.prevStageAnswerData).length) {
        const data: any = await lastValueFrom(this.formService.getResponse(this.solutionId));

        Object.entries(data.solution).forEach(([solName, value]) => {
          if (solName.includes(this.stageId)) {
            this.prevStageAnswerData[solName] = data.solution[solName];
          }
          if (!value) {
            data.solution[solName] = {};
          }
        });
      }

      Object.entries(this.prevStageAnswerData).forEach(([solName, value]) => {
        if (!value) {
          this.prevStageAnswerData[solName] = {};
        }
      });
      this.answerData = this.formService.flattenJSON(this.prevStageAnswerData);

      const outputArray: any = [];

      // taking only key and label to answerData array
      Object.values(this.answerData).forEach((innerObject: any) => {
        Object.entries(innerObject).forEach(([key, label]) => {
          outputArray.push({ key, label });
        });
      });

      this.answerData = outputArray;

      // setting answer to questionList based on type of question
      this.questionData.forEach((step: any) => {
        step.config.forEach((cat: any) => {
          cat['sub-categories'].forEach((subCat: any) => {
            subCat.questions.forEach((que: any) => {
              const { id, apiData, type, options } = que;
              const ansData = this.answerData.find((answer: any) => answer.key === id);
              const ans = ansData ? ansData.label : null;

              this.sharedService.setAnswerByType(apiData, ans, que, options, { cat, type }, this.countries, this.questionList);
            });
          });
        });
      });
    } catch (error) {
      this.router.navigate([URLS.reviewer.dashboard]);
      this.sharedService.showDynamicAlert('Something went wrong');
    }
  }

  isDataForSubCategory(questions: any) {
    return questions.filter((q: any) => q?.answer?.length).length;
  }

  checkIdInComment(id: string) {
    if (this.whoPanelCommentData) {
      return this.whoPanelCommentData.some((obj: any) => obj.questionId === id);
    }
  }

  async getCountries() {
    this.countries = (await lastValueFrom(this.securityService.getCountries())).countries;
  }

  getId(id: string) {
    return id.replaceAll('.', '');
  }

  async getAssessmentResponse() {
    const response = await lastValueFrom(this.whoPanelService.getAssessmentResponse({ solutionId: this.solutionId, stageId: this.stageId }));

    this.assessmentData['status'] = response?.assessmentResponse?.status || '';
    this.assessmentData['eSignature'] = response?.assessmentResponse?.eSignature || '';
    this.assessmentData['comment'] = response?.assessmentResponse?.comment || '';

    if (response?.assessmentResponse?.eSignature) {
      this.signaturePad.fromDataURL(response?.assessmentResponse?.eSignature);
    }
    this.isNextStageDisabled = !response?.assessmentResponse?.status.includes('Approved');
    if (this.isNextStageDisabled) {
      this.router.navigate([`vendor/previous-solution/${this.solutionId}/${this.stageId}`]);
    }

    this.reviewerData = response?.assessmentResponse?.questionReviewStatus;
  }

  checkMyCommentStatus() {
    return this.whoPanelCommentData?.length < 1;
  }

  async getCommentsWithoutStateChange() {
    const response: any = await lastValueFrom(this.whoPanelService.getSolutionComments({ solutionId: this.solutionId, stageId: this.stageId }));
    this.whoPanelCommentData = response.solutionComments.map((comment: any) => ({
      [comment.questionId]: comment
    }));
  }

  async getComments() {
    const response = await lastValueFrom(this.whoPanelService.getSolutionComments({ solutionId: this.solutionId, stageId: this.stageId }));
    this.whoPanelCommentData = {};
    response.solutionComments.forEach((comment: any) => {
      this.whoPanelCommentData[comment.questionId] = comment.comment;
    });
  }

  async getNotes() {
    const response = await lastValueFrom(this.whoPanelService.getSolutionNotes({ solutionId: this.solutionId, stageId: this.stageId }));

    response.solutionNotes.forEach((note: any) => {
      this.whoPanelNotes[note.questionId] = note.note;
    });
  }

  navigateToId(id: string) {
    const newId = this.getId(id);
    if (this.activeAccordion === newId) return;

    this.activeAccordion = newId;
    const buttonId = 'button' + this.getId(this.activeAccordion);
    const buttonDocument = document.getElementById(buttonId);
    buttonDocument?.setAttribute('aria-expanded', 'false');
    buttonDocument?.classList.add('collapsed');
    const accordianDocument = document.getElementById('collapse' + this.getId(this.activeAccordion));
    accordianDocument?.classList.remove('show');

    setTimeout(() => {
      const y_offset = this.isNextStageDisabled ? 120 : 240;
      this.viewPortScroller.setOffset([0, y_offset]);
      this.viewPortScroller.scrollToAnchor(newId);
    }, 0);
  }
}
