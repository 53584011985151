<div class="form_main_content">
  <form [formGroup]="intakeForm" (submit)="onSubmit()">
    <div class="fixed-top"></div>
    <mat-stepper
      (selectionChange)="onSelectionChange($event)"
      class="cst-stepper fixed-stepper"
      [orientation]="(stepperOrientation | async)!"
      [linear]="isLinear"
      [animationDuration]="'0ms'"
      #stepper
    >
      <mat-step [stepControl]="formGroup.form" [state]="'number'" *ngFor="let formGroup of stageFormGroups; index as i">
        <ng-template matStepperIcon="edit" let-index="index">
          {{ !isDisableNextState ? index : index + 1 }}
        </ng-template>
        <ng-template matStepperIcon="done" let-index="index">
          {{ !isDisableNextState ? index : index + 1 }}
        </ng-template>
        <ng-template matStepperIcon="error" let-index="index">
          <div>{{ !isDisableNextState ? index : index + 1 }}</div>
        </ng-template>
        <ng-template matStepperIcon="number" let-index="index">
          {{ !isDisableNextState ? index : index + 1 }}
        </ng-template>
        <!-- Label -->
        <ng-template matStepLabel
          ><span [ngClass]="!isDisableNextState && (i === 0 || i === stageFormGroups.length - 1) ? '' : hasError(isDisableNextState ? i : i - 1)">{{
            isDisableNextState ? stageNames[i] : i === 0 ? 'Stage 1 Summary' : i === stageFormGroups.length - 1 ? 'Evidence' : stageNames[i - 1]
          }}</span></ng-template
        >

        <div *ngIf="!isDisableNextState && (i === 0 || i === stageFormGroups.length - 1); else appStage">
          <app-vendor-summary *ngIf="i === 0" [prevStageAnswerData]="prevStageAnswerData"></app-vendor-summary>
          <app-evidence
            #evidenceRef
            *ngIf="i === stageFormGroups.length - 1"
            [questionData]="questionData"
            [formGroup]="getControls()"
            [attachmentData]="attachments"
            (getAttachments)="getAttachmentsHere()"
            [isFormSubmitted]="isFormSubmitted"
          ></app-evidence>
        </div>

        <!-- Content -->
        <ng-template #appStage>
          <app-stage
            [categories]="questionData[isDisableNextState ? i : i - 1].config"
            [formGroup]="formGroup.form"
            [answerData]="formGroup.answers"
            [isSubmitted]="isSubmitted"
            [isFormSubmitted]="isFormSubmitted"
            [noteGroup]="noteGroup"
            [attachments]="attachments"
            [stepId]="stepIds[isDisableNextState ? i : i - 1]"
            (getAttachments)="getAttachmentsHere()"
          ></app-stage>
        </ng-template>
      </mat-step>
    </mat-stepper>
    <!-- Navigation Buttons -->
    <div class="cst-stepper text-end">
      <button type="button" *ngIf="stepper.selectedIndex !== 0" (click)="stepper.previous()" class="btn prev_btn me-2 float-start">{{ 'shared.previous' | translate }}</button>
      <button
        [disabled]="isFormSubmitted"
        *ngIf="!isFormSubmitted && (isDisableNextState || stepper.selectedIndex !== 0)"
        type="button"
        (click)="saveResponse(false)"
        class="btn outline_btn me-2"
      >
        {{ 'shared.save_as_draft' | translate }}
      </button>
      <button type="button" *ngIf="stepper.selectedIndex !== stageFormGroups.length - 1; else submit" (click)="stepper.next()" class="btn cst_btn">
        {{ 'shared.next' | translate }}
      </button>
      <ng-template #submit>
        <button type="submit" class="btn cst_btn" [style.width]="isFormSubmitted ? 'auto' : null">
          {{ isFormSubmitted ? ('shared.backToDashboard' | translate) : ('shared.submit' | translate) }}
        </button>
      </ng-template>
    </div>
  </form>
</div>
