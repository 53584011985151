import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-sorting',
  templateUrl: './sorting.component.html',
  styleUrls: ['./sorting.component.scss']
})
export class SortingComponent {
  showDefault = false;
  @Input() sortDirection: any;
  @Output() sortChanged = new EventEmitter();
  @Input() columnName: any;
  @Input() isDefault = false;
  @Input() sortByColumn: any;

  sortColumn(columnName: string) {
    this.sortDirection = !this.sortDirection;
    this.sortChanged.emit({ columnName, sortDirection: this.sortDirection });
  }
}
