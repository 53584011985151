import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { SecuritySubscriberService } from 'src/app/security/security-subscriber.service';
import { URLS } from '../../urls';
import { SecurityService } from 'src/app/security/security.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  transition = true;
  @Output() sidebarToggle = new EventEmitter<boolean>();
  @Output() removeTransition = new EventEmitter<boolean>();
  isUserOpen = true;
  isSolutionOpen = true;
  userDetails: any = {};
  sharedValue: any;
  constructor(
    private securityService: SecurityService,
    readonly router: Router,
    private readonly securitySubscriberService: SecuritySubscriberService,
    private readonly cdRef: ChangeDetectorRef
  ) {}
  isVendorDashboardActive(): boolean {
    return this.router.url.includes('public-calls');
  }
  isReviewerDashboardActive(): boolean {
    return this.router.url.includes('reviewer/call-list') && !this.router.url.includes('reviewer/call-list/assignment-request');
  }

  isWhoPanelDashboardActive(): boolean {
    return this.router.url.includes('who-panel/call-list');
  }

  isUserListActive(): boolean {
    return this.router.url.includes('user-list');
  }

  isUserRegActive(): boolean {
    return this.router.url.includes('registration-request');
  }

  isReviewerReview(): boolean {
    return this.router.url.includes('review-list');
  }

  isAssignReqActive(): boolean {
    return this.router.url.includes('assignment-request');
  }

  isPublishSolutionReqActive(): boolean {
    return this.router.url.includes('publish-solution-request');
  }

  isPublishedSolutionActive(): boolean {
    return this.router.url.includes('curated-solution');
  }

  @Input() isSidebarToggle: boolean = false;

  ngOnInit(): void {
    this.toggleSidebar();
    this.securitySubscriberService.getCurrentUser.subscribe((user) => {
      this.userDetails = user;
    });
  }

  navigateToPage(pageToVisit: string) {
    this.toggleSidebar();
    if (pageToVisit === 'registration-request') {
      this.router.navigate([URLS.admin.registrationRequest]);
    } else if (pageToVisit === 'assignment-request') {
      this.router.navigate([URLS.admin.assignmentRequest]);
    } else if (pageToVisit === 'publish-solution-request') {
      this.router.navigate([URLS.admin.publishSolutionRequest]);
    } else if (pageToVisit === 'curated-solution') {
      if (this.userDetails.role === 'admin') {
        this.router.navigate([URLS.admin.curatedSolution]);
      } else if (this.userDetails.role === 'vendor') {
        this.router.navigate([URLS.vendor.curatedSolution]);
      } else if (this.userDetails.role === 'reviewer') {
        this.router.navigate([URLS.reviewer.curatedSolution]);
      } else if (this.userDetails.role === 'whopanel') {
        this.router.navigate([URLS.whopanel.curatedSolution]);
      }
    } else {
      this.router.navigate([URLS.admin.dashboard]);
    }
  }

  navigateToPageWithParams(pageToVisit: string) {
    this.toggleSidebar();
    if (pageToVisit === 'review-list') {
      let id = this.router.url.split('/')[2];
      if (id === 'curated-solution') id = 'call-list';
      this.router.navigate([`/reviewer/${id}/review-list`]);
    } else if (pageToVisit === 'assignment-request') {
      let id = this.router.url.split('/')[2];
      if (id === 'curated-solution') id = 'call-list';
      this.router.navigate([`/reviewer/${id}/assignment-request`]);
    }
  }

  goToHome() {
    this.router.navigate(['/welcome']);
  }

  navigateToDashboard() {
    this.toggleSidebar();
    this.securityService.navigateUser(this.userDetails.role);
  }

  toggleSidebar() {
    const sidebarElement = document.querySelector('.sidebar_menu') as HTMLElement;
    if (sidebarElement && window.getComputedStyle(sidebarElement).display === 'block') {
      setTimeout(() => {
        this.isSidebarToggle = !this.isSidebarToggle;
        this.sidebarToggle.emit(this.isSidebarToggle);
        this.removeTransition.emit(false);
        this.transition = false;
        this.cdRef.detectChanges();
      });
    }
  }
}
