<div *ngIf="!skipQuestion()">
  <p class="question">
    <span class="bold">{{ question.id }}</span> {{ question.label }}
    <span *ngIf="question.isRequired" class="mandatory">*</span>
    <em class="bi bi-info-circle-fill" *ngIf="question.guidanceForSubmitters" [matTooltip]="question.guidanceForSubmitters"></em>
  </p>
  <div class="d-flex">
    <div>
      <div class="form-check cst-radio" [ngClass]="{ invalid: hasError(), 'read-only-radio': _isFormSubmitted }" *ngFor="let option of question.options">
        <input type="radio" id="{{ option.id }}" name="{{ question.id }}" [formControl]="radioControl" value="{{ option.id }}" />
        <label for="{{ option.id }}">{{ option.label }}</label>
      </div>
    </div>
    <div class="dropdown ms-sm-0 ms-sm-3" *ngIf="question.isFileAttachment">
      <button
        class="btn btn-attach dropdown-toggle"
        type="button"
        id="dropdownMenuButton1"
        data-bs-toggle="dropdown"
        aria-expanded="false"
        [class.comment-disabled]="_isFormSubmitted"
      >
        <em class="bi bi-paperclip"></em> Attach
      </button>
      <ul class="dropdown-menu attachment-dropdown" aria-labelledby="dropdownMenuButton1">
        <li>
          <a class="dropdown-item" type="button" data-bs-toggle="modal" [attr.data-bs-target]="'#' + question.id.replaceAll('.', '') + 'staticBackdrop'"
            ><em class="bi bi-link"></em>URL</a
          >
        </li>
        <li>
          <a class="dropdown-item" type="button" data-bs-toggle="modal" [attr.data-bs-target]="'#' + question.id.replaceAll('.', '') + 'staticBackdrop1'"
            ><em class="bi bi-file-earmark-diff"></em>New File</a
          >
        </li>
        <li>
          <a
            class="dropdown-item"
            type="button"
            data-bs-toggle="offcanvas"
            [attr.data-bs-target]="'#' + question.id.replaceAll('.', '') + 'offcanvasRight'"
            aria-controls="offcanvasRight"
            ><em class="bi bi-file-earmark-text"></em>Existing file</a
          >
        </li>
      </ul>
    </div>
  </div>
  <input *ngIf="showInput" [ngClass]="{ invalid: hasError() }" [formControl]="otherInputControl" type="text" class="form-control" placeholder="Enter value" maxlength="200" [ngModel]="answerData" />
  <div class="invalid_feedback" *ngIf="hasError()">{{ 'alert.field_invalid' | translate }}</div>
</div>
