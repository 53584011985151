import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { SharedService } from 'src/app/shared/services/shared.service';
import { VendorService } from '../vendor.service';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-evidence',
  templateUrl: './evidence.component.html',
  styleUrls: ['./evidence.component.scss']
})
export class EvidenceComponent implements OnInit, OnChanges {
  @Input() questionData: any;
  @Input() formGroup: any;
  @Input()
  public set attachmentData(value: any) {
    this._attachmentData = value;
    this.updateData(this.formGroup, this._attachmentData);
  }
  @Input() isFormSubmitted: boolean = false;
  @Output() getAttachments = new EventEmitter();
  _attachmentData: any;
  questionList: any;
  lastScrollPositions: number[] = [];

  @ViewChildren('evidenceTable') evidenceTables!: QueryList<ElementRef>; // Query all evidence tables
  constructor(public sharedService: SharedService, public cdr: ChangeDetectorRef, private readonly vendorService: VendorService) {}

  ngOnInit(): void {
    this.updateData(this.formGroup, this._attachmentData);
  }

  ngOnChanges() {
    this.evidenceTables?.forEach((table, index) => {
      this.changeScrollPosition(this.lastScrollPositions[index], index);
    });
  }

  getAttachmentsHere(index: number) {
    this.getAttachments.emit();
    if (this.evidenceTables) {
      const evidenceTable = this.evidenceTables.toArray()[index];

      if (evidenceTable) {
        this.lastScrollPositions[index] = evidenceTable.nativeElement.scrollTop;
      }
    }
  }

  changeScrollPosition(newScrollTop: number, index: number) {
    const evidenceTable = this.evidenceTables.toArray()[index];

    if (evidenceTable) {
      evidenceTable.nativeElement.scrollTop = newScrollTop;
    }
  }

  updateData(form: any, attachmentsData: any) {
    const attachments: any = {};
    attachmentsData.forEach((attachment: any) => {
      if (attachments[attachment.questionId]) {
        attachments[attachment.questionId].push(attachment);
      } else {
        attachments[attachment.questionId] = [attachment];
      }
    });

    this.questionList = [];
    this.questionData.forEach((step: any, i: number) => {
      this.questionList.push({
        stepId: step.id,
        stepName: step.name,
        questions: [],
        evidenceCount: 0
      });

      step.config.forEach((cat: any) => {
        cat['sub-categories'].forEach((subCat: any) => {
          this.questionList[i]['questions'].push(
            ...subCat.questions.flatMap((que: any) => {
              this.questionList[i]['evidenceCount'] += attachments[que.id]?.length ?? 0;

              return que.isFileAttachment && !this.canSkip(form, step.columnName, cat.name, que.id)
                ? {
                    category: cat.name,
                    subCategory: subCat.name,
                    ...que,
                    id: que.id + '_',
                    label: que.label,
                    attachments: attachments[que.id] ?? []
                  }
                : [];
            })
          );
        });
      });
    });

    this.cdr.detectChanges();
  }

  canSkip(form: any, stepColumnName: string, catName: string, questionId: string) {
    return form[stepColumnName].controls[catName].controls[questionId]?.['skipQuestion'];
  }

  async onDelete(attachmentId: string) {
    try {
      await lastValueFrom(this.vendorService.deleteSolutionAttachment({ attachmentId: attachmentId }));
      this.getAttachments.emit();
    } catch (error) {
      this.sharedService.showDynamicAlert('Some error occurred while deleting the Attachment');
    }
  }
}
