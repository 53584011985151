import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SharedService } from 'src/app/shared/services/shared.service';
import { AdminService } from '../admin.service';
import { lastValueFrom } from 'rxjs';
import { Debounce } from 'lodash-decorators';

@Component({
  selector: 'app-publish-solution-request',
  templateUrl: './publish-solution-request.component.html',
  styleUrls: ['./publish-solution-request.component.scss']
})
export class PublishSolutionRequestComponent implements OnInit {
  isSidebarCollapsed: boolean = false;
  paginationOptions = {
    searchTerm: '',
    page: 1,
    pageSize: 10,
    verificationStatus: 'Approved',
    orderColumn: '',
    orderByAsc: true
  };
  publishSolutionRequsts: any;
  totalPages: any;
  totalSolutions: any;
  sortDirection: any;
  sortByColumn: any = {};
  noDataFound: boolean = false;

  constructor(private readonly adminService: AdminService, private readonly router: Router, private readonly sharedService: SharedService) {}

  async ngOnInit() {
    await this.getPublishSolutionRequests();
  }

  @Debounce(500)
  async onSearchChange(searchFilter: any) {
    this.paginationOptions.searchTerm = searchFilter;
    this.paginationOptions.page = 1;
    await this.getPublishSolutionRequests();
  }

  pageChange(page: any) {
    this.paginationOptions.page = page;

    this.getPublishSolutionRequests();
  }

  async getPublishSolutionRequests() {
    this.noDataFound = false;
    try {
      const solutionList = await lastValueFrom(this.adminService.getPublishSolutionRequests(this.paginationOptions));
      this.publishSolutionRequsts = this.groupByCallName(solutionList.solutions.rows);
      if (this.publishSolutionRequsts.length === 0) {
        this.noDataFound = true;
      }
      this.totalPages = Math.ceil(solutionList.solutions.count / this.paginationOptions.pageSize) || 0;
      this.totalSolutions = solutionList.solutions?.count || 0;
    } catch (err) {
      this.sharedService.showDynamicAlert();
    }
  }

  async onSortChanged(event: { columnName: string; sortDirection: any }) {
    const { columnName, sortDirection } = event;
    this.sortDirection = sortDirection;
    this.sortByColumn = {};
    if (sortDirection !== 'default') {
      this.sortByColumn[columnName] = sortDirection;
    }
    this.paginationOptions.orderByAsc = sortDirection;
    this.paginationOptions.orderColumn = columnName;
    await this.getPublishSolutionRequests();
  }

  onEditSolution(solution: any) {
    this.router.navigate([`admin/publish-solution/${solution.solutionId}`]);
  }

  groupByCallName(rows: any) {
    let currentCallName = '';
    return rows.map((row: any) => {
      if (row.callName !== currentCallName) {
        currentCallName = row.callName;
      } else {
        row.callName = '';
      }
      return row;
    });
  }
}
