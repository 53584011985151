import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SecurityService } from 'src/app/security/security.service';
import { URLS } from 'src/app/shared/urls';

@Component({
  selector: 'app-base-header',
  templateUrl: './base-header.component.html',
  styleUrls: ['./base-header.component.scss']
})
export class BaseHeaderComponent implements OnInit {
  @Input() hasBoxShadow = true;
  role = '';
  activeRouteFragement: string | null = null;

  constructor(private readonly router: Router, private readonly securityService: SecurityService, private route: ActivatedRoute) {
    const userRoleCookie = this.securityService.getCookie('userRole');
    if (!userRoleCookie) return;
    this.role = userRoleCookie;
  }

  ngOnInit() {
    this.route.fragment.subscribe((fragment) => {
      this.activeRouteFragement = fragment;
    });
  }

  backToDashboard() {
    this.navigateUser(this.role);
  }

  navigateUser(role: string) {
    if (role === 'admin') {
      this.router.navigate([URLS.admin.dashboard]);
    } else if (role === 'vendor') {
      this.router.navigate([URLS.vendor.dashboard]);
    } else if (role === 'reviewer') {
      this.router.navigate([URLS.reviewer.dashboard]);
    } else if (role === 'whopanel') {
      this.router.navigate([URLS.whopanel.dashboard]);
    }
  }
}
