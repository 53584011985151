import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StepperComponent } from './components/stepper/stepper.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../shared/material/material.module';
import { TextareaComponent } from './components/textarea/textarea.component';
import { InputComponent } from './components/input/input.component';
import { SelectComponent } from './components/select/select.component';
import { MultiSelectComponent } from './components/multi-select/multi-select.component';
import { RadioComponent } from './components/radio/radio.component';
import { FileUploadComponent } from './components/file-upload/file-upload.component';
import { NoteComponent } from './components/note/note.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { HeaderComponent } from './components/header/header.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import { AlertComponent } from './components/alert/alert.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { ConfirmComponent } from './components/confirm/confirm.component';
import { SortingComponent } from './components/sorting/sorting.component';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { RouterModule } from '@angular/router';
import { SidepanelComponent } from './components/sidepanel/sidepanel.component';
import { TranslateModule } from '@ngx-translate/core';
import { NgSelectModule } from '@ng-select/ng-select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ThankYouComponent } from './components/thank-you/thank-you.component';
import { WarningComponent } from './components/warning/warning.component';
import { TimeoutComponent } from './components/timeout/timeout.component';
import { TimeoutModalComponent } from './components/timeout/timeout-modal/timeout-modal.component';
import { LoaderComponent } from './components/loader/loader.component';
import { SummaryViewModalComponent } from './components/summary-view-modal/summary-view-modal.component';
import { SummarySidebarComponent } from './components/summary-sidebar/summary-sidebar.component';
import { CallListComponent } from './components/call-list/call-list.component';
import { NgSelectCustomComponent } from './components/ng-select-custom/ng-select-custom.component';
import { EditorComponent } from './components/text-editor/text-editor.component';
import { EditorModule, TINYMCE_SCRIPT_SRC } from '@tinymce/tinymce-angular';
import { SubmissionStatusComponent } from './components/submission-status/submission-status.component';
import { MatIconModule } from '@angular/material/icon';
import { HomeWarningComponent } from './components/home-warning/home-warning.component';
@NgModule({
  declarations: [
    StepperComponent,
    TextareaComponent,
    InputComponent,
    SelectComponent,
    MultiSelectComponent,
    RadioComponent,
    FileUploadComponent,
    NoteComponent,
    SidebarComponent,
    HeaderComponent,
    PaginationComponent,
    AlertComponent,
    ConfirmComponent,
    SubmissionStatusComponent,
    SortingComponent,
    BreadcrumbComponent,
    SidepanelComponent,
    ThankYouComponent,
    WarningComponent,
    TimeoutComponent,
    TimeoutModalComponent,
    LoaderComponent,
    SummaryViewModalComponent,
    SummarySidebarComponent,
    CallListComponent,
    NgSelectCustomComponent,
    EditorComponent,
    HomeWarningComponent
  ],
  imports: [CommonModule, MaterialModule, ReactiveFormsModule, NgxPaginationModule, RouterModule, TranslateModule, FormsModule, NgSelectModule, MatTooltipModule, EditorModule, MatIconModule],
  exports: [
    StepperComponent,
    MaterialModule,
    StepperComponent,
    TextareaComponent,
    InputComponent,
    SelectComponent,
    MultiSelectComponent,
    RadioComponent,
    FileUploadComponent,
    NoteComponent,
    SidebarComponent,
    HeaderComponent,
    PaginationComponent,
    AlertComponent,
    LoaderComponent,
    PaginationComponent,
    SortingComponent,
    BreadcrumbComponent,
    SidepanelComponent,
    TranslateModule,
    ReactiveFormsModule,
    TimeoutComponent,
    TimeoutModalComponent,
    SummaryViewModalComponent,
    SummarySidebarComponent,
    CallListComponent,
    NgSelectCustomComponent,
    EditorComponent
  ],
  providers: [TimeoutComponent, TimeoutModalComponent, { provide: TINYMCE_SCRIPT_SRC, useValue: 'tinymce/tinymce.min.js' }]
})
export class SharedModule {}
