import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { SecuritySubscriberService } from 'src/app/security/security-subscriber.service';
import { AdminAuthGuard } from './admin-auth.guard';

@Injectable({
  providedIn: 'root'
})
export class AdminRoleGuard implements CanActivate {
  constructor(private readonly adminAuthGuard: AdminAuthGuard, private readonly securitySubscriberService: SecuritySubscriberService) {}
  /**
   * Checks if the admin is authorized
   * @param route object of activated route
   * @param state state of snapshot of route
   * @returns Boolean whether admin is authorized to access the route
   */
  async canActivate(): Promise<any> {
    if (!this.adminAuthGuard.canActivate()) return false;

    const result = await this.securitySubscriberService.getLocalUser();
    if (!result?.role || result.role !== 'admin') return false;

    return true;
  }
}
