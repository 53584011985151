<header
  class="d-flex flex-wrap mb-4 cst_header"
  [class.no-shadow]="displayLogoHeader === 'Publish Solution'"
  [class.cst_header-toogle]="isToggled || displayLogoHeader"
  [ngClass]="{ 'transition-enable': isToggled || !transition, 'form_header transition-disable': displayLogoHeader, 'transition-disable': transition }"
>
  <ng-container *ngIf="!displayLogoHeader; else logoHeader">
    <a style="cursor: pointer" (click)="toggleSidebar()" class="d-flex align-items-center mb-md-0 me-md-auto link-body-emphasis text-decoration-none">
      <img src="assets/images/menu.svg" alt="menu" class="img-static" />
    </a>
  </ng-container>

  <ng-template #logoHeader>
    <a (click)="onHomeClicked()" class="d-flex align-items-center link-body-emphasis text-decoration-none cursor-pointer">
      <img src="assets/images/logo_black_sm.svg" alt="logo_black_sm" class="img-static" />
    </a>
  </ng-template>

  <h3 class="cursor-default" *ngIf="displayLogoHeader">{{ displayLogoHeader }}</h3>
  <ul class="nav nav-pills">
    <li class="nav-item notification-bell">
      <a class="nav-link notification-icon" (click)="toggleNotification()"
        ><img src="assets/images/Bell.svg" alt="Bell" class="img-static" />
        <span *ngIf="notifications?.length" class="red-circle">
          {{ notifications?.length }}
        </span>
      </a>
      <div *ngIf="isNotification" class="modal-backdrop" (click)="cancel()"></div>
      <div *ngIf="isNotification" class="notification-box">
        <div class="title">
          <h2>Notifications</h2>
          <h2 *ngIf="notifications?.length" class="clear-all" (click)="clearAllNotification()">Clear all</h2>
        </div>
        <div class="data-body">
          <ul *ngIf="notifications?.length">
            <li *ngFor="let notification of notifications">
              <div class="name-circle" [class]="getColorByRole(notification.userRole)" [matTooltip]="getNotificationName(notification)">
                {{ getNotificationName(notification)[0] | uppercase }}
              </div>
              <div>
                <h5>
                  {{ notification.message | translate : notification.data }}
                  <span class="time"><em class="bi bi-clock"></em> {{ formatedDate(notification.createdAt) }}</span>
                </h5>
              </div>
            </li>
          </ul>
          <div *ngIf="!notifications?.length" class="empty-notification">
            <img src="assets/images/no-notification.png" alt="no-notification" />
            <h2>Hey! You have no notifications</h2>
            <h3>We'll notify you when something new arrives</h3>
          </div>
        </div>
      </div>
    </li>
    <li class="nav-item user_detail">
      <div style="cursor: pointer" class="dropdown">
        <a class="d-flex align-items-center text-decoration-none dropdown-toggle" id="dropdownUser2" data-bs-toggle="dropdown" aria-expanded="false">
          <div class="shortname">
            {{ userDetails['firstName'] && userDetails['lastName'] ? userDetails['firstName'][0] + userDetails['lastName'][0] : userDetails['email'][0].toUpperCase() }}
          </div>
          <div class="name">
            {{ userDetails['firstName'] && userDetails['lastName'] ? userDetails['firstName'] + ' ' + userDetails['lastName'] : userDetails['email'] }}
            <span *ngIf="userDetails['role'] === 'vendor'; else nonVendor"> {{ userDetails['companyName'] | titlecase }} - {{ userDetails['role'] | titlecase }} </span>
            <ng-template #nonVendor>
              <span>{{ userDetails['role'] | titlecase }}</span>
            </ng-template>
          </div>
        </a>
        <ul class="dropdown-menu text-small shadow" aria-labelledby="dropdownUser2">
          <li (click)="userDetail('profile')">
            <a class="dropdown-item navigation-button">
              <em class="bi bi-person-circle"></em>
              {{ 'header.profile' | translate }}</a
            >
          </li>
          <li (click)="userDetail('change-password')">
            <a class="dropdown-item navigation-button">
              <em class="bi bi-gear"></em>
              {{ 'header.change_password' | translate }}</a
            >
          </li>
          <li (click)="userDetail('sign-out')">
            <a class="dropdown-item navigation-button">
              <em class="bi bi-box-arrow-left"></em>
              {{ 'header.signout' | translate }}</a
            >
          </li>
        </ul>
      </div>
    </li>
  </ul>
</header>
