import { Location } from '@angular/common';
import { AfterViewInit, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { SecuritySubscriberService } from './security/security-subscriber.service';
import { SecurityService } from './security/security.service';
import { User } from '../app/interfaces/user.interface';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, AfterViewInit {
  title = 'client';
  currentUrl: string = '';
  currentUser!: User;
  isFormActive = '';
  constructor(
    private readonly securitySubscriberService: SecuritySubscriberService,
    private readonly router: Router,
    private readonly location: Location,
    private readonly securityService: SecurityService,
    private readonly cdr: ChangeDetectorRef,
    translate: TranslateService
  ) {
    translate.setDefaultLang('en');
    translate.use('en');
  }

  ngOnInit() {
    this.currentUrl = this.location.path();
    const userRoleCookie = this.securityService.getCookie('userRole');
    if (!userRoleCookie) return;
    const userRole = userRoleCookie;
    this.navigateWithRole(userRole);
  }

  ngAfterViewInit(): void {
    this.securitySubscriberService.isFormActive.subscribe((formActive) => {
      this.isFormActive = formActive;
      this.cdr.detectChanges();
    });
    this.securitySubscriberService.getCurrentUser.subscribe((currentUser) => {
      this.currentUser = currentUser;
      this.cdr.detectChanges();
    });
  }

  isUserInRole(roleOrRoles: string | string[]): boolean {
    if (this.currentUser) {
      if (Array.isArray(roleOrRoles)) {
        return roleOrRoles.includes(this.currentUser.role);
      } else {
        return this.currentUser.role === roleOrRoles;
      }
    } else return false;
  }

  navigateWithRole(role: string | null) {
    switch (role) {
      case 'admin':
        this.navigateWithParams('admin', 'admin/users/user-list');
        break;
      case 'vendor':
        this.navigateWithParams('vendor', 'vendor/call-list');
        break;
      case 'reviewer':
        this.navigateWithParams('reviewer', 'reviewer/call-list');
        break;
      case 'whopanel':
        this.navigateWithParams('who-panel', 'who-panel/call-list');
        break;
      default:
        this.router.navigate(['/welcome']);
    }
  }

  navigateWithParams(role: string, path: string) {
    this.securitySubscriberService.isLoggedIn.next(true);
    if (this.currentUrl.includes(`/${role}/`) || this.currentUrl.includes('/user/')) {
      this.router.navigate([this.currentUrl]);
    } else {
      this.router.navigate([path]);
    }
  }
}
