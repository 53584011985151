<nav class="navbar navbar-expand-lg homepage-header" [class.navbar-shadow]="hasBoxShadow">
  <div class="container-fluid">
    <a class="navbar-brand cursor-pointer" routerLink="/welcome"><img src="assets/images/home_logo.svg" alt="home_logo" class="img-static" /></a>
    <button
      class="navbar-toggler"
      type="button"
      data-bs-toggle="collapse"
      data-bs-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav mb-2 mb-lg-0">
        <li class="nav-item cursor-pointer">
          <a class="nav-link" data-bs-offset="100" href="/welcome#about-us" [class.nav-link-active]="activeRouteFragement === 'about-us'" aria-current="page">{{
            'welcome.about_us.heading' | translate
          }}</a>
        </li>
        <li class="nav-item cursor-pointer">
          <a class="nav-link" href="/welcome#assessment-framework" [class.nav-link-active]="activeRouteFragement === 'assessment-framework'">
            {{ 'welcome.assessment_framework.heading' | translate }}</a
          >
        </li>
        <li class="nav-item cursor-pointer">
          <a class="nav-link" href="/welcome#benefits" [class.nav-link-active]="activeRouteFragement === 'benefits'">{{ 'welcome.benefits.heading' | translate }}</a>
        </li>
        <li class="nav-item cursor-pointer">
          <a class="nav-link" href="/welcome#process" [class.nav-link-active]="activeRouteFragement === 'process'">{{ 'welcome.process.heading' | translate }}</a>
        </li>
        <li class="nav-item">
          <a class="nav-link cursor-pointer" href="/welcome#curated-solutions" [class.nav-link-active]="activeRouteFragement === 'curated-solutions'">{{
            'welcome.curated_solutions' | translate
          }}</a>
        </li>
        <li class="nav-item">
          <a class="nav-link cursor-pointer" href="/welcome#get-in-touch" [class.nav-link-active]="activeRouteFragement === 'get-in-touch'">{{
            'welcome.get_in_touch.heading' | translate
          }}</a>
        </li>
      </ul>
      <form class="d-flex">
        <button routerLink="/login" class="btn link_btn cancel_hover" type="submit">{{ 'welcome.sign_in' | translate }}</button>
        <button routerLink="/signup" class="btn cst_btn" type="submit">{{ 'welcome.sign_up' | translate }}</button>
      </form>
    </div>
  </div>
</nav>
