<div class="main-content">
  <app-breadcrumb
    *ngIf="currentUser"
    [title]="'breadcrumb.profile' | translate | titlecase"
    [items]="[
      { title: 'home', link: securityService.getLink(currentUser.role) },
      { title: 'profile', link: '/profile' }
    ]"
  ></app-breadcrumb>

  <div class="whitebox_container">
    <div *ngIf="isError" class="alert alert-danger cst-alert d-flex" role="alert"><em class="pe-2 bi bi-exclamation-triangle"></em> {{ isError | translate }}</div>
    <form [formGroup]="userDetailsForm">
      <div class="row">
        <div class="col-lg-6 col-12">
          <div class="mb-4 cst_form me-lg-5">
            <label for="firstName" class="form-label">{{ 'shared.first_name' | translate }}</label>
            <input (input)="isError = null" type="text" id="firstName" class="form-control" formControlName="firstName" />
          </div>
        </div>
        <div class="col-lg-6 col-12">
          <div class="mb-4 cst_form ms-lg-5">
            <label for="lastName" class="form-label">{{ 'shared.last_name' | translate }}</label>
            <input (input)="isError = null" type="text" id="lastName" class="form-control" formControlName="lastName" />
          </div>
        </div>
        <div class="col-lg-6 col-12">
          <div class="mb-4 cst_form me-lg-5">
            <label for="email" class="form-label">{{ 'shared.email' | translate }}<span>*</span></label>
            <input (input)="isError = null" type="email" id="email" class="form-control" formControlName="email" />
          </div>
        </div>
        <div class="col-lg-6 col-12">
          <div class="mb-4 cst_form ms-lg-5">
            <label for="role" class="form-label">{{ 'shared.role' | translate }}<span>*</span></label>
            <input (input)="isError = null" type="text" id="role" class="form-control" formControlName="role" />
          </div>
        </div>
        <div class="col-lg-6 col-12">
          <div class="mb-4 cst_form me-lg-5">
            <label for="country" class="form-label">{{ 'shared.country' | translate }}</label>
            <app-ng-select-custom
              [isMultiple]="false"
              [isClearable]="false"
              [isSearchable]="true"
              [isPlaceholder]="true"
              [items]="countries"
              [selectedItems]="this.userDetailsForm.value.country || null"
              (selectedItemChange)="setCountry($event)"
            ></app-ng-select-custom>
          </div>
        </div>
        <div class="col-lg-6 col-12">
          <div class="mb-4 cst_form ms-lg-5">
            <label for="contactNumber" class="form-label">{{ 'shared.contact_no' | translate }}</label>
            <input
              [class.invalid]="userDetailsForm.get('contactNumber')?.invalid && (userDetailsForm.get('contactNumber')?.dirty || userDetailsForm.get('contactNumber')?.touched)"
              type="text"
              id="contactNumber"
              class="form-control"
              formControlName="contactNumber"
            />
            <div *ngIf="userDetailsForm.get('contactNumber')?.touched && userDetailsForm.get('contactNumber')?.invalid">
              <div *ngIf="userDetailsForm.get('contactNumber')?.errors?.pattern">
                <span class="invalid_feedback">{{ 'alert.contact_no_invalid' | translate }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-12">
          <div class="mb-4 cst_form me-lg-5">
            <label for="companyName" class="form-label">{{ 'shared.company_name' | translate }}<span>*</span></label>
            <input
              [class.invalid]="userDetailsForm.get('companyName')?.invalid && (userDetailsForm.get('companyName')?.dirty || userDetailsForm.get('companyName')?.touched)"
              (input)="isError = null"
              formControlName="companyName"
              type="text"
              id="companyName"
              class="form-control"
            />
            <div *ngIf="userDetailsForm.get('companyName')?.touched && userDetailsForm.get('companyName')?.invalid">
              <div *ngIf="userDetailsForm.get('companyName')?.hasError('required')">
                <span class="invalid_feedback">{{ 'alert.company_required' | translate }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-12">
          <div class="mb-4 cst_form ms-lg-5">
            <label for="jobTitle" class="form-label">{{ 'shared.job_title' | translate | titlecase }}</label>
            <input (input)="isError = null" type="text" id="jobTitle" class="form-control" formControlName="jobTitle" />
          </div>
        </div>
        <div class="col-12 text-end">
          <button type="button" class="btn link_btn me-4 cancel_hover" (click)="navigatToDashboard()">{{ 'shared.cancel' | translate | titlecase }}</button>
          <button
            [ngClass]="{
              disabled: !userDetailsForm.valid || isError
            }"
            [disabled]="!userDetailsForm.valid || isError"
            type="button"
            class="btn cst_btn"
            (click)="onUpdate()"
          >
            {{ 'shared.save' | translate | titlecase }}
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
