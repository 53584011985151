export const ALLOWED_FILE_TYPES = {
  'Compressed Files': {
    mimeTypes: ['application/x-rar', 'application/x-rar-compressed', 'application/x-zip-compressed', 'application/octet-stream', 'application/zip', 'application/x-7z-compressed'],
    extensions: ['.rar', '.zip', '.7z']
  },
  'Excel Sheet': {
    mimeTypes: [
      'application/vnd.ms-excel',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.template',
      'application/vnd.ms-excel.sheet.macroEnabled.12',
      'application/vnd.ms-excel.template.macroEnabled.12',
      'application/vnd.ms-excel.addin.macroEnabled.12',
      'application/vnd.ms-excel.sheet.binary.macroEnabled.12'
    ],
    extensions: ['.xla', '.xlsx', '.xltx', '.xlsm', '.xltm', '.xlam', '.xlsb', '.xls']
  },
  Video: {
    mimeTypes: ['video/webm', 'video/mp4', 'video/wmv', 'video/mov', 'video/x-msvideo', 'video/mpeg', 'video/ogg'],
    extensions: ['.webm', '.mp4', '.wmv', '.mov', '.avi', '.mpeg', '.ogv']
  },
  Image: {
    mimeTypes: [
      'image/bmp',
      'image/cis-cod',
      'image/gif',
      'image/ief',
      'image/jpeg',
      'image/pipeg',
      'image/svg+xml',
      'image/tiff',
      'image/x-cmu-raster',
      'image/x-cmx',
      'image/x-icon',
      'image/x-portable-anymap',
      'image/x-portable-bitmap',
      'image/x-portable-graymap',
      'image/x-portable-pixmap',
      'image/x-rgb',
      'image/x-xbitmap',
      'image/x-xpixmap',
      'image/x-xwindowdump',
      'image/png',
      'image/webp'
    ],
    extensions: [
      '.bmp',
      '.cod',
      '.gif',
      '.ief',
      '.jpeg',
      '.jpg',
      '.jfif',
      '.svg',
      '.tiff',
      '.tif',
      '.ras',
      '.cmx',
      '.ico',
      '.pnm',
      '.pbm',
      '.pgm',
      '.ppm',
      '.rgb',
      '.xbm',
      '.xpm',
      '.xwd',
      '.png',
      '.webp'
    ]
  },
  'Outlook mail message': {
    mimeTypes: ['application/vnd.ms-outlook'],
    extensions: ['.msg']
  },
  'Power Point': {
    mimeTypes: [
      'application/vnd.ms-powerpoint',
      'application/vnd.openxmlformats-officedocument.presentationml.presentation',
      'application/vnd.openxmlformats-officedocument.presentationml.template',
      'application/vnd.openxmlformats-officedocument.presentationml.slideshow',
      'application/vnd.ms-powerpoint.addin.macroEnabled.12',
      'application/vnd.ms-powerpoint.presentation.macroEnabled.12',
      'application/vnd.ms-powerpoint.template.macroEnabled.12',
      'application/vnd.ms-powerpoint.slideshow.macroEnabled.12'
    ],
    extensions: ['.pps', '.pptx', '.potx', '.ppsx', '.ppam', '.pptm', '.potm', '.ppsm']
  },
  'Text Files': {
    mimeTypes: [
      'text/plain',
      'text/h323',
      'text/iuls',
      'text/richtext',
      'text/tab-separated-values',
      'text/webviewhtml',
      'text/x-component',
      'text/x-setext',
      'text/x-vcard',
      'text/csv',
      'application/vnd.ms-excel'
    ],
    extensions: ['.txt', '.323', '.uls', '.rtf', '.tsv', '.htt', '.htc', '.etx', '.vcf', '.vcard', '.csv']
  },
  'Word Document': {
    mimeTypes: [
      'application/pdf',
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.template',
      'application/vnd.ms-word.document.macroEnabled.12',
      'application/x-pdf',
      'application/vnd.ms-word.template.macroEnabled.12'
    ],
    extensions: ['.pdf', '.doc', '.docx', '.dotx', '.docm', '.dotm']
  }
};
