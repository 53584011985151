<div class="prelogin_rightside">
  <div class="prelogin_form_container" *ngIf="!errorMessage; else error">
    <div class="success-msg" *ngIf="!isLoading; else loading">
      <img src="assets/images/correct.png" alt="correct" class="img-static" />
      <h1>{{ 'verify_account.success_title' | translate }}</h1>

      <p>
        {{ 'verify_account.success_message' | translate }} <br />
        {{ 'verify_account.wait_for_admin' | translate }}
      </p>
      <button type="button" (click)="returnToLogin()" class="btn prelogin_btn">{{ 'shared.return_to_login' | translate }}</button>
    </div>
    <ng-template #loading>
      <h3>{{ 'shared.loading' | translate }}...</h3>
    </ng-template>
  </div>
  <ng-template #error>
    <div class="prelogin_form_container">
      <div class="success-msg">
        <div *ngIf="errorMessage === 'user.token_already_verified'; else defaultError">
          <img src="assets/images/alert.png" alt="alert" class="img-static" />
          <h1>{{ 'verify_account.already_verified' | translate }}</h1>
          <p>{{ 'verify_account.already_verified_message' | translate }}<br />{{ 'verify_account.wait_for_admin' | translate }}</p>
        </div>
        <ng-template #defaultError>
          <div>
            <img src="assets/images/reject.svg" alt="reject" class="img-static" />
            <h1>{{ 'verify_account.failure_title' | translate }}</h1>
            <p>{{ 'verify_account.failure_message' | translate }}</p>
          </div>
        </ng-template>
        <button type="button" (click)="returnToLogin()" class="btn prelogin_btn">{{ 'shared.return_to_login' | translate }}</button>
      </div>
    </div>
  </ng-template>
</div>
