<div class="prelogin_rightside">
  <img routerLink="/welcome" class="logo cursor-pointer img-static" src="assets/images/logo_black.svg" alt="logo_black" />
  <div *ngIf="!isForgotPassword" class="prelogin_form_container">
    <h1 class="center-header">{{ 'forgot_password.title' | translate }}</h1>
    <p class="center-header">{{ 'forgot_password.sub_text' | translate }}</p>
    <div *ngIf="showForgotPasswordError" class="alert alert-danger cst-alert d-flex" role="alert">
      <em class="pe-2 bi bi-exclamation-triangle"></em> {{ showForgotPasswordError | translate }}
    </div>
    <div class="form-group position-relative"></div>
    <form class="cst_form-container mt-4" [formGroup]="forgotPasswordForm" (ngSubmit)="onSubmit()">
      <div class="mb-4 cst_form">
        <label for="email" class="form-label">{{ 'shared.email' | translate }}<span>*</span></label>
        <input
          type="email"
          class="form-control"
          id="email"
          formControlName="email"
          (input)="showForgotPasswordError = ''"
          [class.invalid]="
            (forgotPasswordForm.get('email')?.invalid && (forgotPasswordForm.get('email')?.dirty || forgotPasswordForm.get('email')?.touched)) || showForgotPasswordError
          "
        />
        <div *ngIf="forgotPasswordForm.controls['email']?.invalid && forgotPasswordForm.controls['email']?.touched">
          <div *ngIf="forgotPasswordForm.controls['email'].errors?.['required']">
            <span class="invalid_feedback"> {{ 'alert.email_required' | translate }}</span>
          </div>
        </div>
      </div>

      <div class="mb-4 cst_form">
        <label for="role" class="form-label">{{ 'shared.role' | translate }}</label>
        <app-ng-select-custom
          [isMultiple]="false"
          [isClearable]="false"
          [isSearchable]="false"
          [items]="roleItems"
          [selectedItems]="forgotPasswordForm.controls['role'].value"
          (selectedItemChange)="roleChange($event)"
        ></app-ng-select-custom>

        <div *ngIf="forgotPasswordForm.get('role')?.touched && forgotPasswordForm.get('role')?.invalid">
          <div *ngIf="forgotPasswordForm.get('role')?.hasError('required')">
            <span class="invalid_feedback">{{ 'alert.role_required' | translate }}</span>
          </div>
        </div>
      </div>

      <!-- Works below for displacement -->

      <div class="text-center mt-5">
        <button
          [disabled]="forgotPasswordForm.invalid || showForgotPasswordError || inProcess"
          class="btn prelogin_btn"
          [ngClass]="{
            disabled: forgotPasswordForm.invalid || showForgotPasswordError || inProcess
          }"
        >
          {{ 'shared.submit' | translate | uppercase }}
        </button>
      </div>

      <div class="mt-4 text-center">
        <span class="back_link forgot-password-cursor" (click)="goToLogin()">{{ 'shared.back' | translate | titlecase }}</span>
      </div>
    </form>
  </div>

  <div *ngIf="isForgotPassword" class="success-msg">
    <img src="assets/images/success-checked.png" alt="success-checked" class="img-static" />
    <h1>{{ 'forgot_password.thank_you_message' | translate }}</h1>

    <p>{{ 'forgot_password.acknowledgement_message' | translate }}</p>
    <button routerLink="/login" type="button" class="btn prelogin_btn">{{ 'shared.return_to_login' | translate }}</button>
  </div>
</div>
