<div class="prelogin_rightside d-flex align-items-center flex-column justify-content-center">
  <img routerLink="/welcome" class="logo cursor-pointer img-static" src="assets/images/logo_black.svg" alt="logo_black" />
  <div *ngIf="!isUserCreated" class="prelogin_form_container">
    <h1 class="center-header">{{ 'signup.create_account' | translate }}</h1>
    <div *ngIf="isError" class="alert alert-danger cst-alert d-flex" role="alert"><em class="pe-2 bi bi-exclamation-triangle"></em> {{ isError | translate }}</div>
    <form [formGroup]="signupForm" class="cst_form mt-4" autocomplete="off" (ngSubmit)="onSubmit()">
      <div class="mb-4">
        <label for="email" class="form-label">{{ 'shared.email' | translate }}<span>*</span></label>
        <input
          type="text"
          class="form-control"
          id="email"
          formControlName="email"
          (input)="isError = ''"
          [class.invalid]="(signupForm.get('email')?.invalid && (signupForm.get('email')?.dirty || signupForm.get('email')?.touched)) || isError === 'user.user_already_exists'"
        />
        <div *ngIf="signupForm.get('email')?.touched && signupForm.get('email')?.invalid">
          <div *ngIf="signupForm.get('email')?.hasError('required')">
            <span class="invalid_feedback">{{ 'alert.email_required' | translate }} </span>
          </div>

          <div *ngIf="signupForm.get('email')?.hasError('pattern')">
            <span class="invalid_feedback">{{ 'alert.email_invalid' | translate }} </span>
          </div>
        </div>
      </div>

      <div class="mb-4">
        <label for="companyName" class="form-label">{{ 'shared.company_name' | translate }}<span>*</span></label>
        <input
          type="text"
          class="form-control"
          id="companyName"
          autocomplete="disabled"
          formControlName="companyName"
          [class.invalid]="signupForm.get('companyName')?.invalid && (signupForm.get('companyName')?.dirty || signupForm.get('companyName')?.touched)"
        />
        <div *ngIf="signupForm.get('companyName')?.touched && signupForm.get('companyName')?.invalid">
          <div *ngIf="signupForm.get('companyName')?.hasError('required')">
            <span class="invalid_feedback">{{ 'alert.company_required' | translate }} </span>
          </div>
        </div>
      </div>

      <div class="mb-4">
        <label for="role" class="form-label">{{ 'shared.role' | translate }}<span>*</span></label>
        <app-ng-select-custom
          [isMultiple]="false"
          [isClearable]="false"
          [isSearchable]="false"
          [items]="roleItems"
          [selectedItems]="signupForm.controls['role'].value"
          (selectedItemChange)="roleChange($event)"
        ></app-ng-select-custom>

        <div *ngIf="signupForm.get('role')?.touched && signupForm.get('role')?.invalid">
          <div *ngIf="signupForm.get('role')?.hasError('required')">
            <span class="invalid_feedback">{{ 'alert.role_required' | translate }}</span>
          </div>
        </div>
      </div>

      <div class="mb-4">
        <label for="password" class="form-label">{{ 'shared.password' | translate }}<span>*</span></label>
        <input
          type="password"
          class="form-control"
          id="password"
          formControlName="password"
          [class.invalid]="signupForm.get('password')?.invalid && (signupForm.get('password')?.dirty || signupForm.get('password')?.touched)"
        />
        <div *ngIf="signupForm.get('password')?.touched && signupForm.get('password')?.invalid">
          <div *ngIf="signupForm.get('password')?.hasError('required')">
            <span class="invalid_feedback">{{ 'alert.password_required' | translate }} </span>
          </div>
          <div *ngIf="signupForm.get('password')?.hasError('minlength') || signupForm.get('password')?.hasError('pattern')">
            <span class="invalid_feedback">{{ 'alert.password_invalid' | translate }}</span>
          </div>
        </div>
      </div>

      <div class="mb-4">
        <label for="confirmPassword" class="form-label">{{ 'shared.confirm_password' | translate }}<span>*</span></label>
        <input
          type="password"
          class="form-control"
          id="confirmPassword "
          formControlName="confirmPassword"
          [class.invalid]="
            (signupForm.get('confirmPassword')?.invalid && signupForm.get('confirmPassword')?.touched && !signupForm.get('confirmPassword')?.value) ||
            (signupForm.get('confirmPassword')?.touched && signupForm.hasError('notSame'))
          "
        />
        <div *ngIf="signupForm.get('confirmPassword')?.touched && signupForm.get('confirmPassword')?.invalid">
          <div *ngIf="signupForm.get('confirmPassword')?.hasError('required')">
            <span class="invalid_feedback">{{ 'alert.confirm_password_required' | translate }}</span>
          </div>
        </div>
        <div *ngIf="signupForm.get('confirmPassword')?.touched && signupForm.hasError('notSame') && !signupForm.get('confirmPassword')?.hasError('required')">
          <span class="invalid_feedback">{{ 'alert.password_mismatch' | translate }}</span>
        </div>
      </div>

      <div class="d-flex justify-content-between">
        <div class="mb-4 cst_form_group">
          <input
            type="checkbox"
            class="form-check-input"
            id="exampleCheck1"
            formControlName="termsAndConditions"
            [class.invalid]="signupForm.get('termsAndConditions')?.invalid && signupForm.get('termsAndConditions')?.touched && !signupForm.get('termsAndConditions')?.value"
          />
          <label class="form-check-label" for="exampleCheck1">{{ 'signup.agree_terms' | translate }}</label>
        </div>
      </div>

      <div class="text-center mt-4">
        <button
          [disabled]="signupForm.invalid || isError === 'user.user_already_exists'"
          class="btn prelogin_btn"
          [ngClass]="{
            disabled: signupForm.invalid || isError === 'user.user_already_exists'
          }"
        >
          {{ 'signup.title' | translate | uppercase }}
        </button>
      </div>

      <div class="mt-4 text-center">
        <span class="signup_link account_cursor">
          {{ 'signup.already_an_account' | translate }}<span routerLink="/login"> {{ 'signup.signin_link' | translate | uppercase }} </span>
        </span>
      </div>
    </form>
  </div>

  <div *ngIf="isUserCreated" class="success-msg">
    <img src="assets/images/success-checked.png" alt="success-checked" class="img-static" />
    <h1>{{ 'signup.thank_you_message' | translate }}</h1>

    <p>{{ 'signup.acknowledgement_message' | translate }}</p>
    <button routerLink="/login" type="button" class="btn prelogin_btn">{{ 'shared.return_to_login' | translate }}</button>
  </div>
</div>
