import { Component, OnDestroy, OnInit } from '@angular/core';
import { Debounce } from 'lodash-decorators';
import { SimpleModalService } from 'ngx-simple-modal';
import { lastValueFrom } from 'rxjs';
import { ConfirmComponent } from 'src/app/shared/components/confirm/confirm.component';
import { SharedService } from 'src/app/shared/services/shared.service';
import { AdminService } from '../admin.service';

@Component({
  selector: 'app-user-registration-request',
  templateUrl: './user-registration-request.component.html',
  styleUrls: ['./user-registration-request.component.scss']
})
export class UserRegistrationRequestComponent implements OnInit, OnDestroy {
  constructor(private readonly adminService: AdminService, private readonly sharedService: SharedService, private readonly simpleModalService: SimpleModalService) {}
  sortByColumn: any = {};
  paginationOptions = {
    searchTerm: '',
    page: 1,
    pageSize: 10,
    verificationStatus: 'Pending',
    orderColumn: '',
    orderByAsc: true
  };

  totalPages: any;
  totalUsers: any;
  users: any;
  sortDirection: any;
  noDataFound = false;
  async ngOnInit() {
    await this.getAllUsers();
  }

  pageChange(page: any) {
    this.paginationOptions.page = page;
    this.getAllUsers();
  }

  async getAllUsers() {
    this.noDataFound = false;
    try {
      const userList = await lastValueFrom(this.adminService.getAllUsers(this.paginationOptions));
      this.users = userList.users.rows;
      if (this.users.length === 0) {
        this.noDataFound = true;
      }
      this.totalPages = Math.ceil(userList.users.count / this.paginationOptions.pageSize) || 0;
      this.totalUsers = userList.users?.count || 0;
    } catch (err) {
      this.sharedService.showDynamicAlert();
    }
  }

  async onSortChanged(event: { columnName: string; sortDirection: any }) {
    const { columnName, sortDirection } = event;
    this.sortDirection = sortDirection;
    this.paginationOptions.orderByAsc = sortDirection;
    this.paginationOptions.orderColumn = columnName;
    this.sortByColumn = {};
    if (sortDirection !== 'default') {
      this.sortByColumn[columnName] = sortDirection;
    }
    await this.getAllUsers();
  }

  @Debounce(500)
  async onSearchChange(searchFilter: any) {
    this.paginationOptions.searchTerm = searchFilter;
    this.paginationOptions.page = 1;
    await this.getAllUsers();
  }

  onChangeStatus(user: any, action: string) {
    const title = action === 'Rejected' ? 'Reject User' : 'Accept User';
    this.simpleModalService
      .addModal(ConfirmComponent, {
        title: title,
        showImg: false,
        lang_msg: 'admin.' + (action === 'Rejected' ? 'reject_user_confirm' : 'accept_user_confirm'),
        email: user.email
      })
      .subscribe((isConfirmed) => {
        // Get modal result
        if (isConfirmed) {
          this.deleteUser(user, action);
        }
      });
  }

  async deleteUser(user: any, action: any) {
    try {
      const userData = {
        id: user.id,
        status: action
      };
      const status: any = await lastValueFrom(this.adminService.changeVerificationStatus(userData));
      this.sharedService.showDynamicAlert(status['lang_msg'], 'success');
      await this.getAllUsers();
    } catch (error: any) {
      this.sharedService.showDynamicAlert();
    }
  }
  ngOnDestroy() {
    this.simpleModalService.removeAll();
  }
}
