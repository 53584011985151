<div class="prelogin_rightside">
  <img routerLink="/welcome" class="logo cursor-pointer img-static" src="assets/images/logo_black.svg" alt="logo_black" />
  <div class="prelogin_form_container">
    <h1 class="center-header">{{ 'login.welcome_to_dch' | translate }}</h1>
    <p class="center-header">{{ 'login.login_to_continue' | translate }}</p>
    <div *ngIf="errorMessage" class="alert alert-danger cst-alert" role="alert"><em class="pe-2 bi bi-exclamation-triangle"></em> {{ errorMessage | translate }}</div>

    <form class="cst_form-container mt-4" [formGroup]="loginForm" (ngSubmit)="onSubmit()">
      <div class="mb-4 cst_form">
        <label for="email" class="form-label">{{ 'shared.email' | translate }}</label>
        <input
          type="email"
          class="form-control"
          id="email"
          formControlName="email"
          [class.invalid]="loginForm.get('email')?.invalid && (loginForm.get('email')?.dirty || loginForm.get('email')?.touched)"
          (input)="errorMessage = ''"
        />
        <div *ngIf="loginForm.controls['email']?.invalid && loginForm.controls['email']?.touched">
          <div *ngIf="loginForm.controls['email'].errors?.['required']">
            <span class="invalid_feedback"> {{ 'alert.email_required' | translate }}</span>
          </div>
        </div>
      </div>
      <div class="mb-4 cst_form">
        <label for="password" class="form-label">{{ 'shared.password' | translate }}</label>
        <input
          type="password"
          class="form-control"
          id="password"
          formControlName="password"
          [class.invalid]="loginForm.get('password')?.invalid && (loginForm.get('password')?.dirty || loginForm.get('password')?.touched)"
          (input)="errorMessage = ''"
        />
        <div *ngIf="loginForm.controls['password']?.invalid && loginForm.controls['password']?.touched">
          <div *ngIf="loginForm.controls['password'].errors?.['required']">
            <span class="invalid_feedback"> {{ 'alert.password_required' | translate }}</span>
          </div>
        </div>
      </div>

      <div class="mb-4 cst_form">
        <label for="role" class="form-label">{{ 'shared.role' | translate }}</label>
        <app-ng-select-custom
          [isMultiple]="false"
          [isClearable]="false"
          [isSearchable]="false"
          [items]="roleItems"
          [selectedItems]="loginForm.controls['role'].value"
          (selectedItemChange)="roleChange($event)"
        ></app-ng-select-custom>

        <div *ngIf="loginForm.get('role')?.touched && loginForm.get('role')?.invalid">
          <div *ngIf="loginForm.get('role')?.hasError('required')">
            <span class="invalid_feedback">{{ 'alert.role_required' | translate }}</span>
          </div>
        </div>
      </div>

      <div class="d-flex justify-content-between">
        <div class="mb-4 cst_form_group">
          <input type="checkbox" class="form-check-input" id="remember-me" formControlName="remember" />
          <label class="form-check-label" for="remember-me">{{ 'login.remember_me' | translate }}</label>
        </div>
        <div class="mb-4">
          <span class="forgot_password forgot-password-cursor" routerLink="/forgot-password"> {{ 'login.forgot_password_text' | translate }}</span>
        </div>
      </div>
      <div class="text-center mt-4">
        <button
          [disabled]="loginForm.invalid || inProcess"
          class="btn prelogin_btn"
          [ngClass]="{
            disabled: loginForm.invalid || inProcess
          }"
        >
          {{ 'login.title' | translate | uppercase }}
        </button>
      </div>
      <div class="mt-4 text-center">
        <span class="signup_link account-cursor">
          {{ 'login.signup_text' | translate }} <span routerLink="/signup">{{ 'signup.title' | translate | uppercase }}</span>
        </span>
      </div>
    </form>
  </div>
</div>
