<ng-select
  class="dropdown"
  (open)="onOpen()"
  [searchable]="isSearchable"
  dropdownPosition="bottom"
  [multiple]="isMultiple"
  [closeOnSelect]="true"
  [disabled]="isDisabled"
  [clearable]="isClearable"
  [(ngModel)]="selectedItems"
  (change)="onChange()"
  [placeholder]="isPlaceholder ? placeholderLabel : ''"
  [class.invalid-selection]="isInvalid"
>
  <ng-option *ngFor="let item of items" [value]="item.id">{{ item.label }}</ng-option>
  <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
    <div class="ng-value" *ngIf="items.length">
      <span class="ng-value-label">{{ items.length }} selected</span>
    </div>
  </ng-template>
</ng-select>
