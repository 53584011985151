import { Component, OnInit } from '@angular/core';
import { SharedService } from '../../services/shared.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {
  isLoading = false;

  constructor(private readonly sharedService: SharedService) {}

  ngOnInit(): void {
    this.sharedService.showLoader.subscribe((val: boolean) => {
      this.isLoading = val;
    });
  }
}
