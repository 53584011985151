import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { SharedService } from '../../services/shared.service';
import { VendorService } from 'src/app/vendor/vendor.service';
import { FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { FormService } from 'src/app/vendor/form/form.service';
import { Question } from '../../models/question.model';
import { ConfirmComponent } from '../confirm/confirm.component';
import { SimpleModalService } from 'ngx-simple-modal';

@Component({
  selector: 'app-note',
  templateUrl: './note.component.html',
  styleUrls: ['./note.component.scss']
})
export class NoteComponent implements OnInit {
  _isFormSubmitted = false;
  @Input() question!: Question;
  @Input() formGroup!: FormGroup;
  @Input() stepId!: string;
  @Input()
  set isFormSubmitted(formSubmitted: boolean) {
    if (formSubmitted) {
      this._isFormSubmitted = formSubmitted;
    }
  }
  @Input() noteGroup!: FormGroup;
  noteControl!: FormControl;
  vendorNoteData: any;
  stageId = '';
  solutionId = '';
  deletedNoteQuesId: any;

  constructor(
    private readonly formService: FormService,
    private readonly sharedService: SharedService,
    private readonly vendorService: VendorService,
    private activeRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private simpleModalService: SimpleModalService,
    private readonly cdr: ChangeDetectorRef
  ) {
    this.stageId = this.activeRoute.snapshot.params['stageid'];
    this.solutionId = this.activeRoute.snapshot.params['solutionid'];
  }

  ngOnInit(): void {
    const controls: any = this.noteGroup['controls'];
    const hideNote = controls[this.question.id]?.['hideNote'];
    if (controls[this.question.id] && hideNote === undefined) {
      controls[this.question.id]['hideNote'] = false;
    }
    if (this._isFormSubmitted) {
      this.noteGroup.disable();
    }
  }

  checkIfControlDisabled(id: string) {
    return this.noteGroup.controls[id]?.disabled;
  }

  addNoteControl(id: string) {
    const noteControl = new FormControl('');
    this.noteGroup.addControl(id, noteControl);
    const hideNote = this.getCommonControl(this.noteGroup, id)['hideNote'];
    this.deletedNoteQuesId = null;
    if (hideNote === undefined) {
      this.getCommonControl(this.noteGroup, id)['hideNote'] = true;
    }
    this.toggleNoteDisplay(id);
  }

  toggleNoteDisplay(id: string) {
    const control = this.getCommonControl(this.noteGroup, id);
    control['hideNote'] = !control['hideNote'];
    setTimeout(() => {
      document.getElementById(`noteTextarea${id.replaceAll('.', '')}`)?.focus();
    }, 0);
  }

  isNoteHidden(id: string) {
    return this.getCommonControl(this.noteGroup, id)?.['hideNote'];
  }

  getCommonControl(formGroup: FormGroup, questionId: string): any {
    return formGroup?.controls[questionId];
  }

  async getNotes() {
    const response = await lastValueFrom(this.vendorService.getSolutionNotes({ solutionId: this.solutionId, stageId: this.stageId }));
    this.vendorNoteData = response.solutionNotes;
  }

  async submitNote(questionId: string) {
    this.deletedNoteQuesId = null;
    this.noteGroup.controls[questionId]?.disable();
    const note = this.noteGroup.controls[questionId].value;
    await lastValueFrom(this.vendorService.saveSolutionNotes({ solutionId: this.solutionId, stepId: this.stepId, questionId, note }));
    this.getNotes();
  }

  onEdit(id: string) {
    this.noteGroup.controls[id].enable();
  }

  async deleteNote(id: string) {
    try {
      this.getCommonControl(this.noteGroup, id)['hideNote'] = true;
      await this.getNotes();
      const NoteToBeDeleted = this.vendorNoteData.find((data: any) => data.questionId === id);
      this.deletedNoteQuesId = NoteToBeDeleted.questionId;
      await lastValueFrom(this.vendorService.deleteSolutionNotes({ noteId: NoteToBeDeleted.id }));
      this.noteGroup.removeControl(id);
      this.cdr.detectChanges();
    } catch (error) {
      this.sharedService.showDynamicAlert('Some error occurred while deleting the note');
    }
  }

  onDelete(id: string) {
    this.simpleModalService
      .addModal(ConfirmComponent, {
        title: 'Confirm Deletion',
        showImg: false,
        lang_msg: 'Are you sure you want to delete comment?',
        email: ''
      })
      .subscribe((isConfirmed) => {
        (async () => {
          if (isConfirmed) {
            await this.deleteNote(id);
          }
        })();
      });
  }
}
