<div class="main-content">
  <app-breadcrumb
    [title]="'breadcrumb.publish_solution' | translate | titlecase"
    [items]="[
      { title: 'solution', link: '/admin/solution/assignment-request' },
      { title: 'publish_solution', link: '/users' }
    ]"
  >
  </app-breadcrumb>
  <div class="table_container">
    <div class="d-md-flex mb-md-0 mb-2 justify-content-between align-items-center">
      <div>
        <form class="table_search mt-2">
          <input
            name="search-registered-users"
            class="form-control search_input me-2"
            [placeholder]="'shared.search' | translate | titlecase"
            id="search-registered-users"
            type="search"
            [(ngModel)]="paginationOptions.searchTerm"
            (ngModelChange)="onSearchChange($event)"
          />
        </form>
      </div>
    </div>
    <div class="table-responsive">
      <table class="table cst_table table-striped" aria-describedby="user list">
        <thead>
          <tr>
            <th scope="col">
              <p>
                {{ 'reviewer.review_list.call_name' | translate }}
                <span
                  ><app-sorting [sortByColumn]="sortByColumn" [sortDirection]="sortDirection" (sortChanged)="onSortChanged($event)" columnName="first_name"></app-sorting>
                </span>
              </p>
            </th>
            <th scope="col">
              <p>
                {{ 'reviewer.review_list.solution_name' | translate }}
                <span><app-sorting [sortByColumn]="sortByColumn" [sortDirection]="sortDirection" (sortChanged)="onSortChanged($event)" columnName="last_name"></app-sorting> </span>
              </p>
            </th>
            <th scope="col">
              <p>
                {{ 'reviewer.review_list.submitted_by' | translate }}
                <span
                  ><app-sorting [sortByColumn]="sortByColumn" [sortDirection]="sortDirection" (sortChanged)="onSortChanged($event)" columnName="submittedBy"></app-sorting>
                </span>
              </p>
            </th>
            <th scope="col">
              <p>
                {{ 'reviewer.review_list.who_panel_reviewed_by' | translate }}
                <span
                  ><app-sorting [sortByColumn]="sortByColumn" [sortDirection]="sortDirection" (sortChanged)="onSortChanged($event)" columnName="whoPanelReviewedBy"></app-sorting>
                </span>
              </p>
            </th>
            <th scope="col">
              {{ 'shared.action' | translate }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            *ngFor="
              let solution of publishSolutionRequsts | paginate : { itemsPerPage: paginationOptions.pageSize, currentPage: paginationOptions.page, totalItems: totalSolutions };
              let i = index
            "
          >
            <td>{{ solution.callName }}</td>
            <td>{{ solution.solutionName }}</td>
            <td>{{ solution.submittedBy || solution.submittedByEmail }}</td>
            <td>{{ solution.whoPanelReviewedBy || solution.whoPanelReviewedByEmail }}</td>
            <td>
              <img (click)="onEditSolution(solution)" src="assets/images/edit.svg" alt="edit" class="img-static" />
            </td>
          </tr>

          <tr class="no-row-available" *ngIf="noDataFound">
            <td colspan="5" rowspan="2">{{ 'alert.no_records_found' | translate }}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <app-pagination
      *ngIf="publishSolutionRequsts?.length"
      [currentPage]="paginationOptions.page"
      [totalPages]="totalPages"
      (pageChange)="pageChange($event)"
      [limitSelected]="paginationOptions.pageSize"
    >
    </app-pagination>
  </div>
</div>
