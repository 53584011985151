<div class="modal-backdrop" (click)="back()"></div>
<div class="modal modal-dialog modal-dialog-centered" fade>
  <div class="confirm-modal modal-content">
    <div class="modal-header">
      <h5 class="modal-title" id="confirm-modal"><em style="font-size: 18px" class="pe-2 bi bi-exclamation-triangle"></em>{{ 'shared.submission_status' | translate }}</h5>
    </div>
    <div class="modal-body" [innerHTML]="lang_msg | translate"></div>
    <div class="modal-footer mt-5">
      <button *ngIf="type === 'BACK'" (click)="back()" type="button" class="btn cst_btn" data-bs-dismiss="modal">{{ 'shared.back' | translate }}</button>
      <button *ngIf="type === 'CONTINUE'" (click)="continue()" type="button" class="btn cst_btn" data-bs-dismiss="modal">{{ 'shared.continue' | translate }}</button>
    </div>
  </div>
</div>
