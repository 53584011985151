import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class VendorService {
  private readonly isFormActiveSubject = new BehaviorSubject<any>(null);
  isFormActive$ = this.isFormActiveSubject.asObservable();

  constructor(private readonly http: HttpClient) {}
  setToggleValue(value: any) {
    this.isFormActiveSubject.next(value);
  }

  getPreSubmissionQuestions(callId: any): Observable<any> {
    return this.http.get(`${environment.baseUrl}/form/get-pre-submission-questions`, { params: callId });
  }

  getPreviousApplications(): Observable<any> {
    return this.http.get(`${environment.baseUrl}/public-call/get-previous-applications`);
  }

  solutionExists(callId: any): Observable<any> {
    return this.http.get(`${environment.baseUrl}/public-call/solution-exists`, { params: callId });
  }

  savePreSubmission(callId: string, data: any): Observable<any> {
    return this.http.post(`${environment.baseUrl}/solution/save-pre-submission`, { callId, data });
  }
  checkSolutionSubmission(solutionId: string): Observable<any> {
    return this.http.get(`${environment.baseUrl}/solution/check-submission`, { params: { solutionId } });
  }
  getFormEditorList(solutionId: any) {
    const payload = { solutionId };
    return this.http.get(`${environment.baseUrl}/solution/editor`, { params: payload });
  }

  deleteFormEditorList(solutionId: string): Observable<any> {
    return this.http.delete(`${environment.baseUrl}/solution/editor`, { body: { solutionId } });
  }

  addFormEditList(solutionId: any) {
    return this.http.post(`${environment.baseUrl}/solution/editor`, { solutionId });
  }

  saveSolutionNotes(solutionDetails: any): Observable<any> {
    return this.http.post(`${environment.baseUrl}/solution/note`, solutionDetails);
  }
  getSolutionNotes(solutionDetails: any): Observable<any> {
    return this.http.get(`${environment.baseUrl}/solution/note`, { params: solutionDetails });
  }
  deleteSolutionNotes(solutionDetails: any): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-': 'application/json'
      }),
      body: {
        noteId: solutionDetails.noteId
      }
    };
    return this.http.delete(`${environment.baseUrl}/solution/note`, options);
  }

  saveSolutionAttachments(attachmentDetails: any): Observable<any> {
    return this.http.post(`${environment.baseUrl}/solution/attachment`, attachmentDetails);
  }

  getSolutionAttachments(attachmentDetails: any): Observable<any> {
    return this.http.get(`${environment.baseUrl}/solution/attachment`, { params: attachmentDetails });
  }

  deleteSolutionAttachment(attachmentDetails: any): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-': 'application/json'
      }),
      body: {
        attachmentId: attachmentDetails.attachmentId
      }
    };
    return this.http.delete(`${environment.baseUrl}/solution/attachment`, options);
  }

  uploadFileAttachment(formData: FormData): Observable<any> {
    return this.http.post(`${environment.baseUrl}/solution/upload`, formData);
  }
}
