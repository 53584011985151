import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { lastValueFrom } from 'rxjs';
import { VendorService } from '../../vendor.service';
import { SharedService } from 'src/app/shared/services/shared.service';
import { Router } from '@angular/router';
import { SubmissionStatusComponent } from 'src/app/shared/components/submission-status/submission-status.component';
import { SimpleModalService } from 'ngx-simple-modal';

@Component({
  selector: 'app-pre-submission',
  templateUrl: './pre-submission.component.html',
  styleUrls: ['./pre-submission.component.scss']
})
export class PreSubmissionComponent implements OnInit {
  questions: any;
  @Input() callId!: string;
  preSubmissionForm: FormGroup;
  previousApplicationOptions: any;
  formInvalid: boolean = true;
  inProcess: boolean = false;

  constructor(
    private readonly router: Router,
    private readonly vendorService: VendorService,
    formBuilder: FormBuilder,
    private readonly sharedService: SharedService,
    private readonly simpleModalService: SimpleModalService
  ) {
    this.preSubmissionForm = formBuilder.group({});
  }

  async ngOnInit() {
    this.questions = (await lastValueFrom(this.vendorService.getPreSubmissionQuestions({ callId: this.callId })))['preSubmissionQuestions'];
    const previousApplications = await lastValueFrom(this.vendorService.getPreviousApplications());
    this.previousApplicationOptions = previousApplications.applications;
    this.preSubmissionForm.valueChanges.subscribe(() => {
      this.isFormInvalid();
    });
  }

  isFormInvalid() {
    const controls: any = this.preSubmissionForm.controls;
    let isInvalid = false;
    for (const key in controls) {
      if (!controls[key]['skipQuestion'] && controls[key].invalid) {
        isInvalid = true;
      }
    }
    this.formInvalid = isInvalid;
  }

  async onSubmit() {
    const psData: any = {};
    this.inProcess = true;
    const preSubmissionValues = this.preSubmissionForm.value;
    Object.keys(preSubmissionValues).forEach((key) => {
      if (key.includes('radio-')) {
        if (!preSubmissionValues[key]) {
          preSubmissionValues[key.split('radio-')[1]] = '';
        }
        delete preSubmissionValues[key];
      } else {
        if (key === 'PS.0') {
          psData['solutionName'] = preSubmissionValues[key];
        } else {
          psData[key.replaceAll('.', '')] = preSubmissionValues[key];
        }
      }
    });

    try {
      const saveResult = await lastValueFrom(this.vendorService.savePreSubmission(this.callId, psData));
      if (saveResult?.result?.isSubmitted) {
        this.simpleModalService
          .addModal(SubmissionStatusComponent, {
            title: 'Submission Status',
            lang_msg: 'Someone from your organization has already submitted the pre-submission form.',
            type: 'CONTINUE'
          })
          .subscribe(() => {
            const { result } = saveResult;
            this.router.navigate([`vendor/solution/${result.solutionId}/${result.stageId}`]);
          });
      } else if (saveResult) {
        const { message, result } = saveResult;
        this.sharedService.showDynamicAlert(message, 'success');
        this.router.navigate([`vendor/solution/${result.solutionId}/${result.stageId}`]);
      }
    } catch (error: any) {
      this.sharedService.showDynamicAlert(error?.error?.message);
      this.inProcess = false;
    }
  }
}
