import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FormService } from 'src/app/vendor/form/form.service';
import { SharedService } from '../../services/shared.service';
import { Question } from '../../models/question.model';

@Component({
  selector: 'app-radio',
  templateUrl: './radio.component.html',
  styleUrls: ['./radio.component.scss']
})
export class RadioComponent implements OnInit {
  @Input() question!: Question;
  @Input() formGroup!: FormGroup;
  @Input() answerData: any;
  @Input() isPreSubmission = false;
  @Input() isFormSubmitted = false;
  radioControl!: FormControl;

  constructor(private readonly formService: FormService, private readonly sharedService: SharedService) {}

  ngOnInit(): void {
    this.question.options = this.question.options.sort((a: any, b: any) => a.label < b.label);
    const validators = this.question.isRequired ? [Validators.required] : [];
    this.radioControl = new FormControl(this.answerData || '', validators);

    this.formGroup.addControl(this.question.id, this.radioControl);
    this.formService.skipLogic(this.question.skipLogic, this.formGroup, this.question.id, this.question.isRequired, this.isPreSubmission);

    if (this.isFormSubmitted) {
      this.radioControl?.disable();
    }
  }

  hasError() {
    return this.sharedService.hasError(this.formGroup, this.question.id);
  }

  skipQuestion(): boolean {
    return this.formService.question(this.formGroup, this.question.id)['skipQuestion'];
  }
}
