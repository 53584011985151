import { ChangeDetectorRef, Component, HostListener, Injector, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SimpleModalService } from 'ngx-simple-modal';
import { lastValueFrom } from 'rxjs';
import { WarningComponent } from 'src/app/shared/components/warning/warning.component';
import { SharedService } from 'src/app/shared/services/shared.service';
import { URLS } from 'src/app/shared/urls';
import { VendorService } from '../vendor.service';
import { FormService } from './form.service';
import { SecuritySubscriberService } from 'src/app/security/security-subscriber.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss']
})
export class FormComponent implements OnInit, OnDestroy {
  @HostListener('window:beforeunload', ['$event'])
  async beforeUnloadHandler(event: BeforeUnloadEvent) {
    await this.deleteUserIfLoggedIn();
    event.preventDefault();
  }

  questionData: any;
  answerData: any;
  prevStageAnswerData: any = {};
  intakeForm: FormGroup;
  stageId: any;
  solutionId!: string;
  existingResponse: boolean = true; // Toggle Presubmission and Stages
  questionsLoading: boolean = true;
  answersLoading: boolean = false;
  preSubmissionQuestions: any;
  isFormSubmitted = false;
  preSubmissionControl!: FormControl;
  editingUserDetails: any;
  isLoggedIn = false;
  noteGroup!: FormGroup;
  previousStageId!: string;
  attachments: any[] = [];
  addEditorInterval: any;

  private sharedService: SharedService;
  private router: Router;
  private activeRoute: ActivatedRoute;
  private formService: FormService;
  private simpleModalService: SimpleModalService;
  private vendorService: VendorService;
  private securitySubscriberService: SecuritySubscriberService;
  private readonly cdr: ChangeDetectorRef;

  constructor(private injector: Injector, formBuilder: FormBuilder) {
    this.sharedService = this.injector.get<SharedService>(SharedService);
    this.router = this.injector.get<Router>(Router);
    this.activeRoute = this.injector.get<ActivatedRoute>(ActivatedRoute);
    this.formService = this.injector.get<FormService>(FormService);
    this.simpleModalService = this.injector.get<SimpleModalService>(SimpleModalService);
    this.vendorService = this.injector.get<VendorService>(VendorService);
    this.securitySubscriberService = this.injector.get<SecuritySubscriberService>(SecuritySubscriberService);
    this.cdr = this.injector.get<ChangeDetectorRef>(ChangeDetectorRef);

    this.intakeForm = formBuilder.group({});

    this.noteGroup = formBuilder.group({});

    this.stageId = this.activeRoute.snapshot.params['stageid'];
    this.solutionId = this.activeRoute.snapshot.params['solutionid'];
    this.previousStageId = this.activeRoute.snapshot.params['previousstageid'];
  }

  async ngOnInit() {
    this.vendorService.setToggleValue(' ');
    await this.setQuestions();
    await this.getNotes();
    await this.getAttachments();
    await this.setAnswers();
    await this.checkUserDetailsForEdit();
    this.securitySubscriberService.isLoggedIn.subscribe((loggedIn) => {
      this.isLoggedIn = loggedIn;
    });
    // Set an interval to add the form to the editor list in the vendor service at regular intervals (every 9 minutes and 50 seconds)
    this.addEditorInterval = setInterval(async () => {
      await lastValueFrom(this.vendorService.addFormEditList(this.solutionId));
    }, environment.refresh_editor_interval);
  }

  async getNotes() {
    const response = await lastValueFrom(this.vendorService.getSolutionNotes({ solutionId: this.solutionId, stageId: this.stageId }));

    if (response.solutionNotes.length) {
      response.solutionNotes.forEach((note: any) => {
        const noteControl = new FormControl({ value: note.note, disabled: true });
        this.noteGroup.addControl(note.questionId, noteControl);
      });
    }
  }

  closeEditorInterval() {
    clearInterval(this.addEditorInterval);
  }

  async getAttachments() {
    const response = await lastValueFrom(this.vendorService.getSolutionAttachments({ solutionId: this.solutionId, stageId: this.stageId }));
    this.attachments = response.solutionAttachments;
    this.cdr.detectChanges();
  }

  async setQuestions() {
    try {
      const data: any = await lastValueFrom(this.formService.getQuestions(this.stageId));
      this.questionData = data.questions;
      this.questionsLoading = false;
    } catch (error) {
      this.router.navigate([URLS.vendor.dashboard]);
      this.sharedService.showDynamicAlert('Something went wrong');
    }
  }

  addPreviousStageToForm(data: any, formName: string) {
    const previousForm = new FormGroup({});
    for (const [key, value] of Object.entries(data || {})) {
      const control = new FormControl(value || '');
      previousForm.addControl(key, control);
    }
    this.intakeForm.addControl(formName, previousForm);
  }

  async setAnswers() {
    try {
      const data: any = await lastValueFrom(this.formService.getResponse(this.solutionId));
      !this.previousStageId && this.vendorService.setToggleValue(`${data.callName}-Intake Form`);
      this.isFormSubmitted = data?.isSubmitted;
      const solutionName = data.solution.PS.solutionName;
      this.addPreSubmissionToForm(data.solution?.PS);
      delete data.solution?.PS;

      Object.entries(data.solution).forEach(([solName, value]) => {
        if (!solName.includes(this.stageId)) {
          this.addPreviousStageToForm(value, solName);
          this.prevStageAnswerData[solName] = data.solution[solName];
          delete data.solution[solName];
        }
        if (!value) {
          data.solution[solName] = {};
        }
      });

      this.answerData = this.formService.flattenJSON(data.solution);

      this.existingResponse = !!Object.keys(this.answerData).length;
      Object.keys(this.answerData).forEach((key) => {
        this.answerData[key]['solutionName'] = solutionName;
      });
      this.answersLoading = false;
    } catch (error) {
      this.router.navigate([URLS.vendor.dashboard]);
      this.sharedService.showDynamicAlert('Something went wrong');
    }
  }

  addPreSubmissionToForm(preSubmissionData: any) {
    const preSubmissionForm = new FormGroup({});
    for (const [key, value] of Object.entries(preSubmissionData || {})) {
      const preSubmissionControl = new FormControl(value || '');
      preSubmissionForm.addControl(key, preSubmissionControl);
    }
    this.intakeForm.addControl('PS', preSubmissionForm);
  }

  async checkUserDetailsForEdit() {
    if (!this.isFormSubmitted) {
      const userDetails: any = await lastValueFrom(this.vendorService.getFormEditorList(this.solutionId));
      if (!userDetails.details) {
        await lastValueFrom(this.vendorService.addFormEditList(this.solutionId));
        return;
      }
      this.editingUserDetails = userDetails;
      this.showWarning(userDetails.details);
    }
  }

  showWarning(details: any) {
    const name = details.map((detail: any) => (detail.firstName && detail.lastName ? detail.firstName + ' ' + detail.lastName : detail.email)).join(', ');

    this.simpleModalService.addModal(WarningComponent, { name }).subscribe(async (isConfirmed) => {
      if (isConfirmed === true) {
        await lastValueFrom(this.vendorService.addFormEditList(this.solutionId));
      } else if (isConfirmed === false) {
        this.isFormSubmitted = true;
      }
    });
  }

  async deleteUserIfLoggedIn() {
    if (this.isLoggedIn) {
      try {
        await lastValueFrom(this.vendorService.deleteFormEditorList(this.solutionId));
      } catch (errr) {}
    }
  }

  async ngOnDestroy() {
    this.simpleModalService.removeAll();
    await this.deleteUserIfLoggedIn();
  }
}
