import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { lastValueFrom } from 'rxjs';
import { SecurityService } from 'src/app/security/security.service';
import { SharedService } from 'src/app/shared/services/shared.service';
import { AdminService } from '../admin.service';

@Component({
  selector: 'app-add-new-user',
  templateUrl: './add-new-user.component.html',
  styleUrls: ['./add-new-user.component.scss']
})
export class AddNewUserComponent implements OnInit {
  addNewUserForm: any;
  isError: any;
  countries: any;
  inProcess: boolean = false;
  roleItems: any;
  constructor(
    private readonly securityService: SecurityService,
    private readonly formBuilder: FormBuilder,
    private readonly adminService: AdminService,
    private readonly sharedService: SharedService,
    private readonly router: Router
  ) {
    this.roleItems = [
      { id: 'vendor', label: 'Vendor' },
      { id: 'reviewer', label: 'Reviewer' },
      { id: 'whopanel', label: 'Who Panel' },
      { id: 'admin', label: 'Admin' }
    ];

    this.addNewUserForm = this.formBuilder.group({
      firstName: [''],
      lastName: [''],
      email: ['', [Validators.required, Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$')]],
      role: ['vendor', Validators.required],
      country: [''],
      contactNo: ['', Validators.pattern('^\\+?[0-9]{10,14}$')],
      companyName: ['', Validators.required],
      jobTitle: ['']
    });
  }

  async ngOnInit() {
    await this.getCountries();
    this.addNewUserForm.valueChanges.subscribe(() => {
      this.isError = null;
    });
  }

  roleChange(role: string) {
    this.addNewUserForm.controls['role'].setValue(role);
  }

  countryChange(role: string) {
    this.addNewUserForm.controls['country'].setValue(role);
  }

  async onSubmit() {
    this.inProcess = true;
    const userDetails = this.addNewUserForm.value;
    try {
      await lastValueFrom(this.adminService.addNewUser(userDetails));
      this.sharedService.showDynamicAlert('admin.user_created', 'success');
      this.addNewUserForm.reset();
      this.addNewUserForm.controls['role'].setValue('vendor');
    } catch (error: any) {
      this.isError = error?.error?.message;
      this.sharedService.showDynamicAlert(error.error.message);
    } finally {
      this.inProcess = false;
    }
  }

  async getCountries() {
    this.countries = (await lastValueFrom(this.securityService.getCountries())).countries;
  }
}
