import { Component, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { SimpleModalComponent } from 'ngx-simple-modal';
import { URLS } from '../../urls';

export interface WarningModel {
  name: string;
}

@Component({
  selector: 'app-warning',
  templateUrl: './warning.component.html',
  styleUrls: ['./warning.component.scss']
})
export class WarningComponent extends SimpleModalComponent<WarningModel, boolean> {
  name: string = '';
  @Output() actionSelected: EventEmitter<string> = new EventEmitter<string>();
  action!: string;

  constructor(private router: Router) {
    super();
  }
  cancel() {
    this.close();
    this.router.navigate([URLS.vendor.dashboard]);
  }
  continueToForm(action: string) {
    if (action === 'edit') {
      this.result = true;
    } else {
      this.result = false;
    }
    this.close();
  }
}
