import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Debounce } from 'lodash-decorators';
import { SimpleModalService } from 'ngx-simple-modal';
import { lastValueFrom } from 'rxjs';
import { SecuritySubscriberService } from 'src/app/security/security-subscriber.service';
import { ConfirmComponent } from 'src/app/shared/components/confirm/confirm.component';
import { SharedService } from 'src/app/shared/services/shared.service';
import { URLS } from 'src/app/shared/urls';
import { AdminService } from '../admin.service';
import { User } from 'src/app/interfaces/user.interface';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss']
})
export class UserListComponent implements OnInit, OnDestroy {
  isSidebarCollapsed: boolean = false;
  paginationOptions = {
    searchTerm: '',
    page: 1,
    pageSize: 10,
    verificationStatus: 'Approved',
    orderColumn: '',
    orderByAsc: true
  };

  totalPages: any;
  totalUsers: any;
  users: any;
  sortDirection: any;
  sortByColumn: any = {};
  userDetails!: User;
  noDataFound: boolean = false;

  constructor(
    private readonly simpleModalService: SimpleModalService,
    private readonly securitySubscriberService: SecuritySubscriberService,
    private readonly adminService: AdminService,
    private readonly router: Router,
    private readonly sharedService: SharedService
  ) {}

  async ngOnInit() {
    this.securitySubscriberService.getCurrentUser.subscribe((user: User) => {
      this.userDetails = user;
    });
    await this.getAllUsers();
  }

  navigateToAddNewUser() {
    this.router.navigate([URLS.admin.addNewUser]);
  }

  @Debounce(500)
  async onSearchChange(searchFilter: any) {
    this.paginationOptions.searchTerm = searchFilter;
    this.paginationOptions.page = 1;
    await this.getAllUsers();
  }

  pageChange(page: any) {
    this.paginationOptions.page = page;

    this.getAllUsers();
  }

  async getAllUsers() {
    this.noDataFound = false;
    try {
      const userList = await lastValueFrom(this.adminService.getAllUsers(this.paginationOptions));
      this.users = userList.users.rows;
      if (this.users.length === 0) {
        this.noDataFound = true;
      }
      this.totalPages = Math.ceil(userList.users.count / this.paginationOptions.pageSize) || 0;
      this.totalUsers = userList.users?.count || 0;
    } catch (err) {
      this.sharedService.showDynamicAlert();
    }
  }

  onUserDelete(user: any) {
    const title = 'Remove User';

    this.simpleModalService
      .addModal(ConfirmComponent, {
        title: title,
        showImg: false,
        lang_msg: 'admin.delete_user_confirm',
        email: user.email
      })
      .subscribe((isConfirmed) => {
        // Get modal result
        if (isConfirmed) {
          this.deleteUser(user);
        }
      });
  }

  async deleteUser(user: any) {
    try {
      await lastValueFrom(this.adminService.deleteUser(user.id));

      this.sharedService.showDynamicAlert('admin.user_deleted', 'success');
      this.getAllUsers();
    } catch (error) {
      this.sharedService.showDynamicAlert('admin.try_again_later', 'danger');
    }
  }

  async onSortChanged(event: { columnName: string; sortDirection: any }) {
    const { columnName, sortDirection } = event;
    this.sortDirection = sortDirection;
    this.sortByColumn = {};
    if (sortDirection !== 'default') {
      this.sortByColumn[columnName] = sortDirection;
    }
    this.paginationOptions.orderByAsc = sortDirection;
    this.paginationOptions.orderColumn = columnName;
    await this.getAllUsers();
  }

  ngOnDestroy() {
    this.simpleModalService.removeAll();
  }
}
