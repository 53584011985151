import { Component } from '@angular/core';
import { SimpleModalComponent } from 'ngx-simple-modal';
export interface ConfirmModel {
  title: string;
  showImg: boolean;
  lang_msg: string;
  email?: string;
}

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss']
})
export class ConfirmComponent extends SimpleModalComponent<ConfirmModel, boolean> implements ConfirmModel {
  title = 'shared.confirm_submission';
  showImg = true;
  lang_msg = '';
  email = '';
  constructor() {
    super();
  }

  /**
   * handles confirm action
   */
  confirm() {
    // on click on confirm button we set dialog result as true,
    // ten we can get dialog result from caller code
    this.result = true;
    this.close();
  }

  /**
   * Handles the cancel action on modal
   */
  cancel() {
    this.result = false;
    this.close();
  }
}
