import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { FormComponent } from './form/form.component';
import { FormModule } from './form/form.module';
import { VendorRoutingModule } from './vendor-routing.module';
import { VendorComponent } from './vendor.component';
import { AddSolutionComponent } from './add-solution/add-solution.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxPaginationModule } from 'ngx-pagination';
@NgModule({
  declarations: [FormComponent, VendorComponent, AddSolutionComponent],
  imports: [CommonModule, VendorRoutingModule, SharedModule, FormModule, MatTooltipModule, ReactiveFormsModule, NgxPaginationModule, FormsModule]
})
export class VendorModule {}
