<div class="main-content">
  <h2 class="mb-4">{{ 'vendor.public_calls.heading' | translate }}</h2>
  <div class="cst-tabs">
    <ul class="nav nav-tabs" id="call-list-tabs" role="tablist">
      <li class="nav-item" role="presentation" (click)="getCallDetails('Active')">
        <button class="nav-link active" id="active-call-tab" data-bs-toggle="tab" data-bs-target="#active-call" type="button" role="tab" aria-controls="home" aria-selected="true">
          {{ 'vendor.public_calls.active_call' | translate }}
        </button>
      </li>
      <li class="nav-item" role="presentation" (click)="getCallDetails('Previous')">
        <button class="nav-link" id="previous-call-tab" data-bs-toggle="tab" data-bs-target="#previous-call" type="button" role="tab" aria-controls="profile" aria-selected="false">
          {{ 'vendor.public_calls.previous_call' | translate }}
        </button>
      </li>
    </ul>
    <div class="tab-content mt-4" id="call-list">
      <div class="tab-pane fade show active" id="active-call" role="tabpanel" aria-labelledby="active-call-tab">
        <div *ngIf="activeCalls?.length > 0" class="d-flex flex-wrap">
          <div class="card call-card" *ngFor="let call of activeCalls">
            <img src="assets/images/callimage_1.png" alt="callimage_1" class="card-img-top img-static" />
            <div class="card-body">
              <h5 class="card-title">{{ call.name }}</h5>
              <div class="d-flex justify-content-between">
                <div class="d-flex">
                  <div class="start_date">
                    {{ 'vendor.public_calls.start_date' | translate }}<span>{{ call.startDate | date : 'dd MMM yyyy' }}</span>
                  </div>
                  <div class="end_date">
                    {{ 'vendor.public_calls.end_date' | translate }}<span>{{ call.endDate | date : 'dd MMM yyyy' }}</span>
                  </div>
                </div>
                <p
                  [ngClass]="{
                    'stagetwo-badge': call.status.includes('Approved'),
                    'rejected-badge': !call.status.includes('Approved')
                  }"
                  *ngIf="currentUser.role === 'vendor' && call.previousStageId"
                >
                  {{ (call.status.includes('Approved') ? 'vendor.public_calls.current_stage' : 'vendor.public_calls.rejected_stage') | translate }}
                  <span *ngIf="call.status.includes('Approved')">{{ call.currentStageOrder }}</span>
                </p>

                <p class="pending-text" *ngIf="currentUser.role !== 'vendor' && call.pendingReviews > 0">
                  {{ 'reviewer.public_calls.pending_request' | translate }}<span>{{ call.pendingReviews }}</span>
                </p>
              </div>
              <div class="d-flex align-items-center">
                <button type="submit" class="btn register_btn cursor-pointer" (click)="navigateTo(call.id, call.solution, call.previousStageId, call.status)">
                  {{ (call.solution?.isSubmitted ? 'shared.view' : currentUser.role === 'vendor' ? 'shared.register' : 'shared.review') | translate }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="tab-pane fade" id="previous-call" role="tabpanel" aria-labelledby="previous-call-tab">
        <div *ngIf="previousCalls?.length > 0" class="d-flex flex-wrap">
          <div class="card cursor-default call-card" *ngFor="let call of previousCalls">
            <img src="assets/images/callimage_1.png" alt="callimage_1" class="card-img-top img-static" />
            <div class="card-body">
              <h5 class="card-title">{{ call.name }}</h5>
              <div class="d-flex">
                <div class="start_date">
                  {{ 'vendor.public_calls.start_date' | translate }}<span>{{ call.startDate | date : 'dd MMM yyyy' }}</span>
                </div>
                <div class="end_date">
                  {{ 'vendor.public_calls.end_date' | translate }}<span>{{ call.endDate | date : 'dd MMM yyyy' }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
