import { Injectable, OnDestroy } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { SecuritySubscriberService } from 'src/app/security/security-subscriber.service';

@Injectable({
  providedIn: 'root'
})
export class VendorAuthGuard implements CanActivate, OnDestroy {
  isLoggedIn: boolean | undefined;
  private readonly subscription: Subscription;

  constructor(private readonly securitySubscriberService: SecuritySubscriberService, private readonly router: Router) {
    this.subscription = this.securitySubscriberService.isLoggedIn.subscribe((loggedIn) => {
      this.isLoggedIn = loggedIn;
    });
  }

  /**
   * Unsubscribe all subscription used in file
   */
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  /**
   * Check authorization and if not authorized navigate to login page
   * @param route object of activated route
   * @param state state of snapshot of route
   * @returns user is logged in or not
   */
  async canActivate(): Promise<any> {
    if (this.isLoggedIn == undefined) {
      const response = await this.securitySubscriberService.getLocalUser();
      if (!response?.role) this.router.navigate(['/welcome']);

      return true;
    }
    if (!this.isLoggedIn) this.router.navigate(['/login']);
    return this.isLoggedIn;
  }
}
