import { Component, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Buffer } from 'buffer';
import { lastValueFrom, Subscription } from 'rxjs';
import { SharedService } from 'src/app/shared/services/shared.service';
import { SecuritySubscriberService } from '../security-subscriber.service';
import { SecurityService } from '../security.service';
import { ReCaptchaV3Service } from 'ng-recaptcha';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnDestroy {
  loginForm: FormGroup;
  errorMessage: string = '';
  cooldownTime: number = 0;
  roleItems!: any;
  inProcess: boolean = false;
  private interval: any;
  private subscription!: Subscription;
  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly securityService: SecurityService,
    private readonly router: Router,
    private readonly securitySubscriberService: SecuritySubscriberService,
    private readonly sharedService: SharedService,
    private recaptchaV3Service: ReCaptchaV3Service
  ) {
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$')]],
      password: ['', [Validators.required, Validators.minLength(3)]],
      role: ['vendor', Validators.required],
      remember: false
    });
    this.roleItems = [
      { id: 'vendor', label: 'Vendor' },
      { id: 'reviewer', label: 'Reviewer' },
      { id: 'whopanel', label: 'Who Panel' },
      { id: 'admin', label: 'Admin' }
    ];
  }

  roleChange(role: string) {
    this.loginForm.controls['role'].setValue(role);
  }

  onSubmit() {
    this.inProcess = true;
    const userDetails = this.loginForm.value;
    const base64_password = Buffer.from(this.loginForm.value.password || '', 'utf-8').toString('base64');
    this.subscription = this.recaptchaV3Service.execute('submit').subscribe((token) => {
      if (!token) {
        this.sharedService.showDynamicAlert('Something went wrong');
        return;
      }

      (async () => {
        await lastValueFrom(this.securityService.verifyReCaptcha(token));
        try {
          const response = await lastValueFrom(this.securityService.login(userDetails.email, base64_password, userDetails.role, userDetails.remember));
          if (response?.details?.skipTwoFactor) {
            //set isLoggedIn value true
            this.securitySubscriberService.isLoggedIn.next(true);
            const userRole = response.details.role;

            //navigate the user to their dashboard as per their role
            this.securityService.navigateUser(userRole);

            //show successful login message
            // this.sharedService.showDynamicAlert('admin.login_success', 'success');
          } else if (response?.details?.email) {
            // Encoding (Encrypting) email to Base64 to avoid the visibility of email in queryparams
            const encodedEmail = btoa(response.details.email); // Encoding using btoa

            this.router.navigate([`/two-factor-auth/${encodedEmail}`]);
          }
        } catch (error: any) {
          if (error?.error?.message) {
            this.errorMessage = error.error.message;
            if (this.errorMessage.includes('Try again later')) {
              clearInterval(this.interval);
              const remainingCooldownMatches = RegExp(/(\d+) seconds/).exec(this.errorMessage) ?? ['0'];
              this.cooldownTime = +remainingCooldownMatches[1];
              const newErrorMessage = this.errorMessage.replace(remainingCooldownMatches[0], this.formatTime(this.cooldownTime));
              this.errorMessage = newErrorMessage;
              this.startCooldownTimer();
            }
            return;
          }
          this.errorMessage = 'Something went wrong. Please contact your administrator';
          this.sharedService.showDynamicAlert(this.errorMessage);
        } finally {
          this.inProcess = false;
        }
      })().catch((error) => {
        this.sharedService.showDynamicAlert(error.error.message);
      });
    });
  }

  formatTime(timeInSeconds: number): string {
    if (timeInSeconds <= 60) {
      return `${timeInSeconds} seconds`;
    } else {
      const minutes = Math.floor(timeInSeconds / 60);
      const seconds = timeInSeconds % 60;
      return `${minutes} minutes and ${seconds} seconds`;
    }
  }

  startCooldownTimer() {
    this.interval = setInterval(() => {
      if (this.cooldownTime <= 0) {
        clearInterval(this.interval);
        this.errorMessage = ''; // Clear the cooldown message
        return;
      }
      this.cooldownTime -= 1;
      const remainingCooldownMatches = RegExp(/\d+ minutes and \d+ seconds/).exec(this.errorMessage) ?? RegExp(/(\d+) seconds/).exec(this.errorMessage) ?? ['0', '0', '0'];
      const newErrorMessage = this.errorMessage.replace(remainingCooldownMatches[0], this.formatTime(this.cooldownTime));
      this.errorMessage = newErrorMessage;
    }, 1000);
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
}
