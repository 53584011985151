<div class="form_wrapper" [class.hideComponent]="!pageLoaded">
  <div class="fixed-top"></div>
  <div class="vendor_summary_main_content" [class.rejected-form-wrapper]="isNextStageDisabled" [class.custom-main-content]="!isNextStageDisabled">
    <div
      class="cst-list-group left-sidebar cursor-default"
      [class.rejected-form-sidebar]="isNextStageDisabled"
      [class.custom-cst-list]="!isNextStageDisabled"
      style="overflow-x: hidden"
    >
      <ul *ngFor="let data of questionData">
        <h3>{{ data.name }}</h3>
        <li [class.bg]="selectedId === getId(configItem.id)" (click)="navigateToId(configItem.id)" class="cursor-pointer" *ngFor="let configItem of data.config">
          {{ configItem.name }}
        </li>
      </ul>
    </div>

    <div class="summary-rightside">
      <div *ngFor="let data of questionData; let i = index">
        <div class="accordion cst_accordian" [id]="'category' + getId(data.config[0].id)" [attr.data-cat]="data.name">
          <div class="accordion-item" *ngFor="let config of data.config; let j = index" [attr.data-que]="getId(config.id)">
            <h2 class="accordion-header" [id]="getId(config.id)">
              <button
                [id]="'button' + getId(config.id)"
                class="accordion-button collapsed"
                type="button"
                [ngClass]="{ collapsed: activeAccordion !== getId(config.id) }"
                data-bs-toggle="collapse"
                [attr.data-bs-target]="'#collapse' + getId(config.id)"
                aria-expanded="true"
                [attr.aria-controls]="'collapse' + getId(config.id)"
                (click)="navigateToId(config.id)"
              >
                <span>{{ config.id }}</span> {{ config.name }}
              </button>
            </h2>
            <div
              [id]="'collapse' + getId(config.id)"
              [class.show]="activeAccordion === getId(config.id)"
              class="accordion-collapse collapse"
              [attr.aria-labelledby]="getId(config.id)"
              [attr.data-bs-parent]="'#category' + getId(data.config[0].id)"
            >
              <div class="accordion-body">
                <div class="mb-66">
                  <div class="mb-36" *ngFor="let sub of config['sub-categories']">
                    <h5 *ngIf="isDataForSubCategory(sub.questions)">{{ sub.name }}</h5>
                    <div *ngFor="let question of sub.questions">
                      <div class="mb-36" *ngIf="question?.answer?.length > 0 && question.id">
                        <p [id]="'que' + getId(question.id)" class="question">
                          <span class="bold">{{ question.id }}</span> {{ question.label }}
                        </p>
                        <div class="">
                          <p class="answer">
                            {{ question.answer }}
                            <span class="ms-2 add-note-box" *ngIf="whoPanelNotes[question.id]" [matTooltip]="whoPanelNotes[question.id]"
                              ><img class="add-note img-static" src="assets/images/edit.png" alt="edit"
                            /></span>
                          </p>

                          <div class="hr-line"></div>
                          <div class="d-flex justify-content-end">
                            <div class="btn-group me-3 cst-btn-group" role="group" aria-label="Basic radio toggle button group">
                              <input
                                type="radio"
                                class="pass btn-check"
                                name="{{ question.id }}"
                                id="{{ question.id + 'pass' }}"
                                [checked]="reviewerData[question.id] === 'pass'"
                                value="pass"
                              />

                              <label class="btn disablebtn" for="{{ question.id + 'pass' }}"><em class="bi bi-check2"></em>{{ 'reviewer.summary.pass' | translate }}</label>

                              <input
                                type="radio"
                                class="fail btn-check"
                                name="{{ question.id }}"
                                id="{{ question.id + 'fail' }}"
                                [checked]="reviewerData[question.id] === 'fail'"
                                value="fail"
                              />
                              <label class="btn disablebtn" for="{{ question.id + 'fail' }}"><em class="bi bi-x"></em>{{ 'reviewer.summary.fail' | translate }}</label>
                            </div>
                          </div>
                        </div>

                        <div class="comment-container" *ngIf="whoPanelCommentData[question.id]">
                          <div>
                            <label>{{ 'reviewer.summary.comments' | translate }}:</label>
                            <p class="comment-value">
                              {{ whoPanelCommentData[question.id] }}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="assessment-response">
        <h1 class="title">
          {{ 'reviewer.summary.assessment_response' | translate }}
        </h1>
        <div class="response">
          <div class="mb-4">
            <label for="select-your-response" class="form-label">{{ 'reviewer.summary.select_your_response' | translate }}</label>
            <app-ng-select-custom
              [isMultiple]="false"
              [isClearable]="false"
              [isSearchable]="false"
              [isPlaceholder]="false"
              [isDisabled]="true"
              [items]="statusItems"
              [selectedItems]="assessmentData['status']"
            ></app-ng-select-custom>
          </div>

          <div class="mb-4">
            <label for="comments" class="form-label">{{ 'reviewer.summary.comments' | translate }}</label>
            <textarea
              class="form-control assesment-text-area"
              id="reviewer-response-comment"
              placeholder=""
              rows="3"
              maxlength="500"
              [value]="assessmentData['comment']"
              disabled
            ></textarea>
          </div>
          <div class="mb-4 e-signature">
            <label for="e-signature" class="form-label">{{ 'reviewer.summary.e_signature' | translate }}</label>
            <div class="signature-container disable-signature">
              <canvas style="width: 100%" #signaturePadCanvas></canvas>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
