import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginGuard, UserGuard } from './security/guard/auth.guard';
import { UserChangePasswordComponent } from './security/user-change-password/user-change-password.component';
import { UserProfileComponent } from './security/user-profile/user-profile.component';
import { WelcomeComponent } from './base/welcome/welcome.component';
import { SearchComponent } from './base/search/search.component';
import { SearchSolutionComponent } from './base/search/search-solution/search-solution.component';

const routes: Routes = [
  {
    path: 'welcome',
    component: WelcomeComponent,
    canActivate: [LoginGuard]
  },
  {
    path: 'search',
    component: SearchComponent
  },
  {
    path: 'search-solution/:solutionId',
    component: SearchSolutionComponent
  },
  {
    path: 'user',
    loadChildren: () => import('./base/base.module').then((m) => m.BaseModule)
  },
  { path: 'user/profile', component: UserProfileComponent, canActivate: [UserGuard] },
  { path: 'user/change-password', component: UserChangePasswordComponent, canActivate: [UserGuard] },
  {
    path: 'admin',
    loadChildren: () => import('./admin/admin.module').then((m) => m.AdminModule)
  },
  {
    path: 'vendor',
    loadChildren: () => import('./vendor/vendor.module').then((m) => m.VendorModule)
  },
  {
    path: 'reviewer',
    loadChildren: () => import('./reviewer/reviewer.module').then((m) => m.ReviewerModule)
  },
  {
    path: 'who-panel',
    loadChildren: () => import('./who-panel/who-panel.module').then((m) => m.WhoPanelModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
