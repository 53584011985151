import { Component } from '@angular/core';
import { SimpleModalComponent } from 'ngx-simple-modal';

export interface SummaryViewModal {
  questionData: any;
  reviewData: any;
  questionList: any;
  previousQuestionData: any;
  previousReviewerData: any;
}
@Component({
  selector: 'app-summary-view-modal',
  templateUrl: './summary-view-modal.component.html',
  styleUrls: ['./summary-view-modal.component.scss']
})
export class SummaryViewModalComponent extends SimpleModalComponent<SummaryViewModal, boolean> implements SummaryViewModal {
  solutionId!: string;
  reviewData: any;
  stageId!: string;
  questionData: any;
  previousQuestionData: any;
  previousReviewerData: any;
  questionList: any = [];
  constructor() {
    super();
  }

  onClose() {
    this.close();
  }

  checkReviewStatus(questionId: string) {
    return this.reviewData[questionId] ? this.reviewData[questionId] : this.previousReviewerData[questionId];
  }

  getId(id: string) {
    return id.replaceAll('.', '');
  }

  navigateToId(id: string) {
    const newId = this.getId(id);
    setTimeout(() => {
      document.getElementById('sview' + newId)?.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
    }, 0);
  }
}
