import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { SharedService } from '../../services/shared.service';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnInit {
  @ViewChild('alert', { static: false }) alert!: ElementRef;

  showAlert = false;
  alertOptions: any;
  alertMessage!: string;
  dynamicValues: Record<string, string> = {};
  constructor(private readonly sharedService: SharedService) {}

  /**
   * Initialize alert component with subscription of showAlert and closeAlert
   * close the alert if autoFade is true.
   */
  ngOnInit(): void {
    this.sharedService.showAlert.subscribe((loaderData) => {
      if (loaderData) {
        this.showAlert = true;
        this.alertOptions = loaderData.options;
        this.alertOptions.autoFade && this.fadeAlertMsg();
        this.dynamicValues = loaderData.options.dynamicValues;
        this.alertMessage = this.alertOptions.lang_msg;
      }
    });
    this.sharedService.closeAlert.subscribe((closeAlert) => {
      if (closeAlert) {
        this.checkAndCloseAlert();
      }
    });
  }

  /**
   * check for the alert and if exist close the alert.
   */
  checkAndCloseAlert() {
    if (this.alert?.nativeElement) {
      this.alert.nativeElement.classList.add('fade');
      this.showAlert = false;
    }
  }

  /**
   * check for the alert and close alert after 2 seconds.
   */
  fadeAlertMsg() {
    setTimeout(() => {
      if (!this.alert?.nativeElement) {
        this.showAlert = false;
        return;
      }
      this.alert.nativeElement.classList.add('fade');
      this.showAlert = false;
    }, 2000);
  }
}
