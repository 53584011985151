import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FormService {
  intakeForm!: FormGroup;

  constructor(private http: HttpClient) {}

  flattenJSON(data: object) {
    return Object.entries(data).reduce((accObj: { [key: string]: object }, [key, value]) => {
      const flattenedValue = Object.assign({}, ...Object.values(value));
      accObj[key] = flattenedValue;
      return accObj;
    }, {});
  }

  /**
   * @param response response received from ngForm
   * @returns post request with acknowledgement
   */
  saveResponse(solutionId: string, response: object, isSaveDraft: boolean, isSubmitted: boolean) {
    return this.http.post(
      `${environment.baseUrl}/solution`,
      { solutionId, data: response, isSaveDraft, isSubmitted },
      {
        headers: new HttpHeaders({ 'Content-type': 'application/json' })
      }
    );
  }

  /**
   * @param response response received from ngForm
   * @returns post request with acknowledgement
   */
  submitResponse(solutionId: string, response: object) {
    return this.http.post(
      `${environment.baseUrl}/solution`,
      { solutionId, data: response },
      {
        headers: new HttpHeaders({ 'Content-type': 'application/json' })
      }
    );
  }

  /**
   * get answer data from backend
   * @returns http response with data
   */
  getResponse(solutionId: string) {
    return this.http.get(`${environment.baseUrl}/solution`, { params: { solutionId } });
  }

  /**
   * get question data from backend
   * @returns http response with data
   */
  getQuestions(stageId: any) {
    return this.http.get(`${environment.baseUrl}/form/get-questions`, { params: { stageId } });
  }

  /**
   * @description Takes in an ngForm and iterates recursively through all its form groups and form controls
   * to check if they are valid.
   *
   * If the form element is invalid, marks the form element as touched to trigger its respective css syles
   * Adds all the invalid question ids to the invalidQuestionIds to display on the frontend.
   *
   * @param formElement Form Group / Form Control to be checked for validation.
   *
   */
  checkFormGroup(formElement: FormGroup | FormControl): string[] {
    const invalidQuestionIds: string[] = [];

    if (formElement instanceof FormGroup) {
      const ctrls: any = formElement.controls;
      Object.keys(ctrls)
        .filter((ctrlId: string) => !ctrls[ctrlId]['skipQuestion'])
        .forEach((field) => {
          const formGroup = formElement.controls[field];

          // If the field is invalid
          if (formGroup?.invalid) {
            formGroup.markAsTouched({ onlySelf: true });

            // If Form Group
            if (formGroup instanceof FormGroup) {
              invalidQuestionIds.push(...this.checkFormGroup(formGroup));
            } else {
              // Form Control
              invalidQuestionIds.push(field);
            }
          }
        });
    }

    return invalidQuestionIds;
  }

  /**
   * Function to find a formControl in any nested form by its name.
   * @param name Name of the form control
   * @param formElement FormGroup / FormControl to be checked
   * @returns FormControl with the provided name if exists else null
   */
  findControl(name: string, formElement: FormControl | FormGroup): AbstractControl | null {
    if (formElement instanceof FormGroup) {
      // Loop over all controls
      for (const key of Object.keys(formElement.controls)) {
        const nestedControl = formElement.controls[key];
        if (nestedControl instanceof FormGroup) {
          // If control not found
          const control = this.findControl(name, nestedControl);
          if (control !== null) {
            return control;
          }
        } else if (nestedControl instanceof FormControl && key === name) {
          // Control found
          return nestedControl;
        }
      }
    }
    // Control not found
    return null;
  }

  /**
   * Implements the logic to skip questions based on some conditions.
   * @param skipLogic Development skip logic
   * @param formGroup FormGroup in which the question is present
   * @param questionId Id of the question to skip
   */
  skipLogic(skipLogic: any, formGroup: FormGroup, questionId: string, isRequired: boolean, isPreSubmission: boolean = false) {
    if (!skipLogic) return;
    // Bind skip logic to the form controls

    const control: AbstractControl | null = isPreSubmission ? this.findControl(skipLogic.control, formGroup) : this.findControl(skipLogic.control, this.intakeForm);
    if (control) {
      this.checkSkipLogic(skipLogic, control.value, formGroup, questionId, isRequired, isPreSubmission);
      control.valueChanges.subscribe((value) => {
        this.checkSkipLogic(skipLogic, value, formGroup, questionId, isRequired, isPreSubmission);
      });
    }
  }

  /**
   * Helper function for skipLogic
   */
  checkSkipLogic(skipLogic: any, valueFromInput: any, formGroup: FormGroup, questionId: string, isRequired: boolean, isPreSubmission = false) {
    const multiSelect = Array.isArray(valueFromInput);
    valueFromInput = multiSelect ? valueFromInput : valueFromInput.toString();

    // For Presubmission
    if (isPreSubmission && formGroup.controls['radio-' + questionId]) {
      formGroup.controls['radio-' + questionId].setValue('');
      formGroup.controls['radio-' + questionId].markAsUntouched();
    }
    const skipLogicValue = skipLogic.value;
    // Logic as per literals

    this.skipInEq(skipLogic, multiSelect, skipLogicValue, valueFromInput, formGroup, questionId, { isPreSubmission, isRequired });
    this.skipNotInEq(skipLogic, multiSelect, skipLogicValue, valueFromInput, formGroup, questionId, { isPreSubmission, isRequired });
  }

  skipInEq(skipLogic: any, multiSelect: any, skipLogicValue: any, valueFromInput: any, formGroup: any, questionId: string, { isPreSubmission, isRequired }: any) {
    if (skipLogic.operator === 'IN') {
      const disable = multiSelect ? skipLogicValue.some((val: string) => valueFromInput.includes(val)) : skipLogicValue.includes(valueFromInput); // Condition for disabling formControl
      this.toggleFormControl(disable, formGroup, questionId, isPreSubmission, isRequired);
    }
    if (skipLogic.operator === 'EQ') {
      const disable = multiSelect ? skipLogicValue.some((val: string) => valueFromInput.includes(val)) : skipLogicValue === valueFromInput; // Condition for disabling formControl
      this.toggleFormControl(disable, formGroup, questionId, isPreSubmission, isRequired);
    }
  }

  skipNotInEq(skipLogic: any, multiSelect: any, skipLogicValue: any, valueFromInput: any, formGroup: any, questionId: string, { isPreSubmission, isRequired }: any) {
    if (skipLogic.operator === 'NOT EQ') {
      const disable = multiSelect ? !skipLogicValue.some((val: string) => valueFromInput.includes(val)) : skipLogicValue !== valueFromInput; // Condition for disabling formControl
      this.toggleFormControl(disable, formGroup, questionId, isPreSubmission, isRequired);
    }
    if (skipLogic.operator === 'NOT IN') {
      const disable = multiSelect ? !skipLogicValue.some((val: string) => valueFromInput.includes(val)) : !skipLogicValue.includes(valueFromInput); // Condition for disabling formControl
      this.toggleFormControl(disable, formGroup, questionId, isPreSubmission, isRequired);
    }
  }

  // Getter for question
  question(formGroup: FormGroup, questionId: string): any {
    return formGroup.controls[questionId];
  }

  /**
   * Toggles formControl from a form group as per inputs
   * @param disable Boolean to enable or disable a formcontrol
   * @param formGroup FormGroup in which the formcontrol is present
   * @param questionId
   * @param isPreSubmission
   */
  toggleFormControl(disable: boolean, formGroup: FormGroup, questionId: string, isPreSubmission: boolean, isRequired: boolean) {
    const questionControl = this.question(formGroup, questionId);
    if (disable) {
      formGroup.controls[questionId].setValue('');
      formGroup.controls[questionId].markAsUntouched();
      formGroup.controls[questionId].clearValidators();
      formGroup.controls[questionId].updateValueAndValidity();
    } else {
      const validators = isRequired ? [Validators.required] : [];
      formGroup.controls[questionId].addValidators(validators);
      formGroup.controls[questionId].updateValueAndValidity();
    }
    questionControl['skipQuestion'] = disable;
    // For Presubmission
    if (isPreSubmission && formGroup.controls['radio-' + questionId]) {
      const preSubQuestionControl = this.question(formGroup, 'radio-' + questionId);
      preSubQuestionControl['skipQuestion'] = disable;
    }
  }
}
