import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AddNewUserComponent } from './add-new-user/add-new-user.component';
import { AdminRoleGuard } from './security/admin-role.guard';
import { UserListComponent } from './user-list/user-list.component';
import { UserRegistrationRequestComponent } from './user-registration-request/user-registration-request.component';
import { AdminComponent } from './admin.component';
import { AssignmentRequestComponent } from './assignment-request/assignment-request.component';
import { PublishSolutionRequestComponent } from './publish-solution-request/publish-solution-request.component';
import { SearchSolutionComponent } from '../base/search/search-solution/search-solution.component';
import { SearchComponent } from '../base/search/search.component';

const routes: Routes = [
  {
    path: '',
    component: AdminComponent,
    children: [
      {
        path: 'users/user-list',
        component: UserListComponent,
        canActivate: [AdminRoleGuard]
      },
      {
        path: 'users/registration-request',
        component: UserRegistrationRequestComponent,
        canActivate: [AdminRoleGuard]
      },
      {
        path: 'solution/assignment-request',
        component: AssignmentRequestComponent,
        canActivate: [AdminRoleGuard]
      },
      {
        path: 'solution/publish-solution-request',
        component: PublishSolutionRequestComponent,
        canActivate: [AdminRoleGuard]
      },
      {
        path: 'users/add-user',
        component: AddNewUserComponent,
        canActivate: [AdminRoleGuard]
      },
      {
        path: 'publish-solution/:solutionId',
        component: SearchSolutionComponent,
        canActivate: [AdminRoleGuard]
      },
      {
        path: 'solution/curated-solution',
        component: SearchComponent,
        canActivate: [AdminRoleGuard]
      },
      {
        path: 'curated-solution/:solutionId',
        component: SearchSolutionComponent,
        canActivate: [AdminRoleGuard]
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdminRoutingModule {}
