import { Component, OnDestroy } from '@angular/core';
import { AbstractControl, FormBuilder, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { SecurityService } from '../security.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SecuritySubscriberService } from '../security-subscriber.service';
import { Subscription, lastValueFrom } from 'rxjs';
import { SharedService } from 'src/app/shared/services/shared.service';
import { Buffer } from 'buffer';
import { User } from 'src/app/interfaces/user.interface';
import { ReCaptchaV3Service } from 'ng-recaptcha';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnDestroy {
  resetPasswordForm;
  isError = false;
  userDetails!: User;
  displayMessage = '';
  passwordUpdatedSuccessfully = false;
  inProcess: boolean = false;
  isResetPassword = false;
  private subscription!: Subscription | undefined;
  constructor(
    private readonly sharedService: SharedService,
    private readonly formBuilder: FormBuilder,
    readonly securityService: SecurityService,
    private readonly activeRoute: ActivatedRoute,
    private router: Router,
    private readonly securitySubscriberService: SecuritySubscriberService,
    private recaptchaV3Service: ReCaptchaV3Service
  ) {
    this.resetPasswordForm = this.formBuilder.group(
      {
        password: ['', [Validators.required, Validators.minLength(8), Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).+$/)]],
        confirmPassword: ['', Validators.required]
      },
      { validators: this.checkPasswords }
    );
    this.resetPasswordForm.valueChanges.subscribe(() => {
      this.isError = false;
      this.passwordUpdatedSuccessfully = false;
    });
  }

  checkPasswords: ValidatorFn = (group: AbstractControl): ValidationErrors | null => {
    const pass = group?.get('password')?.value;
    const confirmPass = group?.get('confirmPassword')?.value;
    return pass === confirmPass ? null : { notSame: true };
  };

  onUpdate() {
    this.inProcess = true;
    this.displayMessage = '';
    this.isError = false;
    this.isResetPassword = false;

    const newPassword = Buffer.from(this.resetPasswordForm.value.password ?? '', 'utf-8').toString('base64');
    const routeToken = this.activeRoute.snapshot.params['token'];
    this.subscription = this.recaptchaV3Service.execute('submit').subscribe((token) => {
      if (token) {
        (async () => {
          await lastValueFrom(this.securityService.verifyReCaptcha(token));

          try {
            const response = await lastValueFrom(this.securityService.resetPassword(routeToken, newPassword));
            this.passwordUpdatedSuccessfully = true;
            this.resetPasswordForm.reset();
            this.securitySubscriberService.isLoggedIn.next(false);
            this.displayMessage = response.lang_msg || response.message;
            this.sharedService.showDynamicAlert(this.displayMessage, 'success');
            this.isResetPassword = true;
          } catch (error: any) {
            throw error;
          } finally {
            this.inProcess = false;
          }
        })().catch((error) => {
          this.sharedService.showDynamicAlert(error.error.message);
        });
      } else {
        this.sharedService.showDynamicAlert('Something went wrong');
      }
    });
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
}
