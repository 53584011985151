<div class="form_wrapper">
  <div class="evidence_main_content">
    <div class="accordion cst_accordian evidence-tab">
      <div class="accordion-item">
        <h2 class="accordion-header" id="headingOne">
          <button class="accordion-button" type="button" data-bs-toggle="collapse" aria-expanded="true" aria-controls="collapseOne">
            <span>Evidence Tracker</span>
          </button>
        </h2>
        <div id="evidence" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
          <div class="accordion-body">
            <div *ngFor="let step of questionList; let i = index">
              <h4>{{ step.stepName }} Evidence</h4>
              <span class="text">({{ step.evidenceCount }} out of {{ step.questions.length }} evidence uploaded )</span>
              <div class="summary-view-table">
                <div class="table-responsive evidence-table" #evidenceTable>
                  <table class="table cst_table" aria-describedby="evidence">
                    <thead>
                      <tr>
                        <th scope="col">ID</th>
                        <th scope="col">Category</th>
                        <th scope="col">Sub-category</th>
                        <th scope="col">Question</th>
                        <th scope="col">Evidence type</th>
                        <th scope="col">Support Submitter</th>
                        <th scope="col">Upload Evidence</th>
                        <th scope="col">Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let que of step.questions">
                        <td>{{ que.id.replaceAll('_', '') }}</td>
                        <td>{{ que.category }}</td>
                        <td>{{ que.subCategory }}</td>
                        <td>{{ que.label }}</td>
                        <td>Documentation</td>
                        <td><em class="info-icon bi bi-info-circle-fill"></em></td>
                        <td>
                          <div class="dropdown">
                            <a type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false" [class.dropdown-disabled]="isFormSubmitted"
                              ><img src="assets/images/file-upload.svg" alt="file-upload"
                            /></a>

                            <ul class="dropdown-menu attachment-dropdown" aria-labelledby="dropdownMenuButton1">
                              <li>
                                <a class="dropdown-item" type="button" data-bs-toggle="modal" [attr.data-bs-target]="'#' + que.id.replaceAll('.', '') + 'staticBackdrop'"
                                  ><em class="bi bi-link"></em>URL</a
                                >
                              </li>
                              <li>
                                <a class="dropdown-item" type="button" data-bs-toggle="modal" [attr.data-bs-target]="'#' + que.id.replaceAll('.', '') + 'staticBackdrop1'"
                                  ><em class="bi bi-file-earmark-diff"></em>New File</a
                                >
                              </li>
                              <li>
                                <a
                                  class="dropdown-item"
                                  type="button"
                                  data-bs-toggle="offcanvas"
                                  [attr.data-bs-target]="'#' + que.id.replaceAll('.', '') + 'offcanvasRight'"
                                  aria-controls="offcanvasRight"
                                  ><em class="bi bi-file-earmark-text"></em>Existing file</a
                                >
                              </li>
                            </ul>
                          </div>

                          <app-file-upload
                            [question]="que"
                            [attachments]="_attachmentData"
                            [stepId]="step.stepId"
                            [isFormSubmitted]="isFormSubmitted"
                            (getAttachments)="getAttachmentsHere(i)"
                          ></app-file-upload>
                        </td>
                        <td>
                          <span *ngIf="que.attachments.length > 0; else redBox" class="green-tick-box"><em class="bi bi-check2"></em></span>
                          <ng-template #redBox>
                            <span class="red-box"><em class="bi bi-x"></em></span>
                          </ng-template>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
