import { Component, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { SimpleModalComponent } from 'ngx-simple-modal';

export interface HomeWarningModel {
  name: string;
}

@Component({
  selector: 'app-home-warning',
  templateUrl: './home-warning.component.html',
  styleUrls: ['./home-warning.component.scss']
})
export class HomeWarningComponent extends SimpleModalComponent<HomeWarningModel, boolean> {
  name: string = '';
  @Output() actionSelected: EventEmitter<string> = new EventEmitter<string>();
  action!: string;

  constructor(private router: Router) {
    super();
  }

  cancel() {
    this.close();
  }

  goBack() {
    this.result = false;
    this.close();
  }

  saveDraft() {
    this.result = true;
    this.close();
  }
}
